import {
  Configuration,
  DefaultApi,
  PracticeMetricsResponse,
  ProviderDetails,
  ProviderPayoutResponse,
  User,
} from "./generated";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

export const URL = process.env.REACT_APP_PROVIDER_API_BASE_PATH;

const configuration = new Configuration({
  basePath: URL,
});
const api = new DefaultApi(configuration);

export class NotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "NotFoundError";
  }
}

const headers = async (): Promise<{ [key: string]: any }> => {
  const session = await fetchAuthSession();
  const { idToken } = session.tokens ?? {};
  return {
    Authorization: `Bearer ${idToken}`,
  };
};

const defaultAxiosRequestConfig = async (): Promise<AxiosRequestConfig> => {
  return {
    headers: await headers(),
    timeout: 10000, // Use a conservative default, in case of cold starts
  };
};

export const fetchPayouts = async (): Promise<ProviderPayoutResponse[]> => {
  try {
    const response = await api.fetchPayoutsForProviderProviderPayoutsGet(
      await defaultAxiosRequestConfig(),
    );
    return response.data;
  } catch (error) {
    // Handle error
    throw error;
  }
};

export const fetchCurrentUser = async (): Promise<User> => {
  const response = await api.fetchCurrentUserUsersMeGet(
    await defaultAxiosRequestConfig(),
  );
  return response.data;
};

export const fetchUninvitedProviders = async (): Promise<ProviderDetails[]> => {
  try {
    const response = await api.fetchProvidersAdminProvidersGet(
      await defaultAxiosRequestConfig(),
    );
    return response.data.filter((p) => !p.invited);
  } catch (error) {
    // Handle error
    throw error;
  }
};

export const fetchAllProviders = async (): Promise<ProviderDetails[]> => {
  try {
    const response = await api.fetchProvidersAdminProvidersGet(
      await defaultAxiosRequestConfig(),
    );
    return response.data;
  } catch (error) {
    // Handle error
    throw error;
  }
};

export const createProviderUser = async (providerId: string): Promise<any> => {
  try {
    await api.createProviderUserAdminUsersProviderPost(
      {
        provider_id: providerId,
      },
      await defaultAxiosRequestConfig(),
    );
  } catch (error) {
    throw error;
  }
};

export const createAdminUser = async (
  email: string,
  name: string,
): Promise<any> => {
  try {
    await api.createAdminUserAdminUsersAdminPost(
      { email, name },
      await defaultAxiosRequestConfig(),
    );
  } catch (error) {
    throw error;
  }
};

export const getUserAuthenticationDetails = async (email: string) => {
  try {
    const response =
      await api.getUserAuthenticationInfoUsersAuthenticationInfoGet(email, {
        ...(await defaultAxiosRequestConfig()),
      });

    return response.data;
  } catch (error: unknown) {
    if (error && (error as AxiosError).response?.status === 404) {
      throw new Error("User not found");
    }
    throw error;
  }
};

export const fetchPracticeMetrics =
  async (): Promise<PracticeMetricsResponse> => {
    try {
      return (
        await api.getProviderPracticeMetricsProviderPracticeMetricsGet(
          await defaultAxiosRequestConfig(),
        )
      ).data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          throw new NotFoundError(error.response?.data);
        }
      }
      throw error;
    }
  };

export const resendUserInvite = async (email: string) => {
  await api.resendUserInviteAdminUsersResendInvitePost(
    { email },
    await defaultAxiosRequestConfig(),
  );
};
