/* tslint:disable */
/* eslint-disable */
/**
 * Fay API
 * Fay API
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const APIAppointmentStatus = {
    Scheduled: 'Scheduled',
    Occurred: 'Occurred',
    Canceled: 'Canceled',
    NoShow: 'No-show',
    ReScheduled: 'Re-scheduled'
} as const;

export type APIAppointmentStatus = typeof APIAppointmentStatus[keyof typeof APIAppointmentStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const APIAppointmentType = {
    InitialConsultation: 'Initial Consultation',
    FollowUp: 'Follow-up'
} as const;

export type APIAppointmentType = typeof APIAppointmentType[keyof typeof APIAppointmentType];


/**
 * 
 * @export
 * @enum {string}
 */

export const APIRecurrenceType = {
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    BiWeekly: 'Bi-weekly'
} as const;

export type APIRecurrenceType = typeof APIRecurrenceType[keyof typeof APIRecurrenceType];


/**
 * 
 * @export
 * @interface AddPatientLabFeedbackPayload
 */
export interface AddPatientLabFeedbackPayload {
    /**
     * 
     * @type {boolean}
     * @memberof AddPatientLabFeedbackPayload
     */
    'is_feedback_positive': boolean;
}
/**
 * 
 * @export
 * @interface AddressDetails
 */
export interface AddressDetails {
    /**
     * 
     * @type {string}
     * @memberof AddressDetails
     */
    'address_line1': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetails
     */
    'address_line2': string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetails
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetails
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetails
     */
    'zip_code': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AllowedSortParam = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type AllowedSortParam = typeof AllowedSortParam[keyof typeof AllowedSortParam];


/**
 * 
 * @export
 * @interface Amount
 */
export interface Amount {
}
/**
 * 
 * @export
 * @interface AppointmentAddToCalendarDetailsResponse
 */
export interface AppointmentAddToCalendarDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'join_url': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAddToCalendarDetailsResponse
     */
    'ics_data': string;
}
/**
 * 
 * @export
 * @interface AppointmentCanceledResponse
 */
export interface AppointmentCanceledResponse {
    /**
     * 
     * @type {string}
     * @memberof AppointmentCanceledResponse
     */
    'appointment_id': string;
}
/**
 * 
 * @export
 * @interface AppointmentDetailsResponse
 */
export interface AppointmentDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof AppointmentDetailsResponse
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDetailsResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDetailsResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {APIAppointmentStatus}
     * @memberof AppointmentDetailsResponse
     */
    'status': APIAppointmentStatus;
    /**
     * 
     * @type {APIAppointmentType}
     * @memberof AppointmentDetailsResponse
     */
    'appointment_type': APIAppointmentType;
    /**
     * 
     * @type {number}
     * @memberof AppointmentDetailsResponse
     */
    'duration_in_minutes': number;
}


/**
 * 
 * @export
 * @interface AppointmentInfo
 */
export interface AppointmentInfo {
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    'selected_time': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    'end_time': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    'reason': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentInfo
     */
    'is_video_call': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentInfo
     */
    'is_in_person': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    'appointment_description': string;
}
/**
 * Response model for the Appointments API.
 * @export
 * @interface AppointmentResponse
 */
export interface AppointmentResponse {
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'calendar_event_id': string;
    /**
     * 
     * @type {APIAppointmentStatus}
     * @memberof AppointmentResponse
     */
    'status': APIAppointmentStatus;
    /**
     * 
     * @type {APIAppointmentType}
     * @memberof AppointmentResponse
     */
    'appointment_type': APIAppointmentType;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentResponse
     */
    'duration_in_minutes': number;
    /**
     * 
     * @type {RecurrenceType}
     * @memberof AppointmentResponse
     */
    'recurrence_type': RecurrenceType | null;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'recurrence_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    'chart_notes': string | null;
}


/**
 * 
 * @export
 * @interface AppointmentVideoCallResponse
 */
export interface AppointmentVideoCallResponse {
    /**
     * 
     * @type {string}
     * @memberof AppointmentVideoCallResponse
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentVideoCallResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentVideoCallResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentVideoCallResponse
     */
    'join_url': string;
}
/**
 * 
 * @export
 * @interface AvailabilitySlotsResponse
 */
export interface AvailabilitySlotsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof AvailabilitySlotsResponse
     */
    'slots': Array<string>;
}
/**
 * 
 * @export
 * @interface BMI
 */
export interface BMI {
    /**
     * 
     * @type {string}
     * @memberof BMI
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface BackgroundJobPayload
 */
export interface BackgroundJobPayload {
    /**
     * 
     * @type {string}
     * @memberof BackgroundJobPayload
     */
    'job_name': string;
}
/**
 * 
 * @export
 * @interface BookAppointmentPayload
 */
export interface BookAppointmentPayload {
    /**
     * 
     * @type {PatientId1}
     * @memberof BookAppointmentPayload
     */
    'patient_id'?: PatientId1 | null;
    /**
     * 
     * @type {ProviderId1}
     * @memberof BookAppointmentPayload
     */
    'provider_id'?: ProviderId1 | null;
    /**
     * 
     * @type {string}
     * @memberof BookAppointmentPayload
     */
    'start': string;
    /**
     * 
     * @type {number}
     * @memberof BookAppointmentPayload
     */
    'duration_in_minutes': number;
    /**
     * 
     * @type {APIAppointmentType}
     * @memberof BookAppointmentPayload
     */
    'appointment_type': APIAppointmentType;
    /**
     * 
     * @type {APIRecurrenceType}
     * @memberof BookAppointmentPayload
     */
    'recurrence_type': APIRecurrenceType | null;
}


/**
 * 
 * @export
 * @interface BookAppointmentResponse
 */
export interface BookAppointmentResponse {
    /**
     * 
     * @type {string}
     * @memberof BookAppointmentResponse
     */
    'appointment_id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BookingInfoAppointmentConfirmationStatus = {
    Cancelled: 'CANCELLED',
    Confirmed: 'CONFIRMED',
    Unconfirmed: 'UNCONFIRMED'
} as const;

export type BookingInfoAppointmentConfirmationStatus = typeof BookingInfoAppointmentConfirmationStatus[keyof typeof BookingInfoAppointmentConfirmationStatus];


/**
 * 
 * @export
 * @interface BookingInfoResponse
 */
export interface BookingInfoResponse {
    /**
     * 
     * @type {BookingInfoAppointmentConfirmationStatus}
     * @memberof BookingInfoResponse
     */
    'appointment_confirmation_status': BookingInfoAppointmentConfirmationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof BookingInfoResponse
     */
    'is_patient_insurance_needed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BookingInfoResponse
     */
    'is_patient_address_needed': boolean;
    /**
     * 
     * @type {AppointmentInfo}
     * @memberof BookingInfoResponse
     */
    'appointment_info': AppointmentInfo;
    /**
     * 
     * @type {PatientInfo}
     * @memberof BookingInfoResponse
     */
    'patient_info': PatientInfo;
    /**
     * 
     * @type {ProviderDetails}
     * @memberof BookingInfoResponse
     */
    'provider_info': ProviderDetails;
}


/**
 * 
 * @export
 * @interface BusResubmissionPayload
 */
export interface BusResubmissionPayload {
    /**
     * 
     * @type {string}
     * @memberof BusResubmissionPayload
     */
    'command_or_event_name': string;
    /**
     * 
     * @type {object}
     * @memberof BusResubmissionPayload
     */
    'payload': object;
}
/**
 * 
 * @export
 * @interface BusResubmissionResponse
 */
export interface BusResubmissionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BusResubmissionResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof BusResubmissionResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CalendarEventResponse
 */
export interface CalendarEventResponse {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'calendar_event_id': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'end': string;
    /**
     * 
     * @type {APIRecurrenceType}
     * @memberof CalendarEventResponse
     */
    'recurrence_type': APIRecurrenceType | null;
    /**
     * 
     * @type {AppointmentDetailsResponse}
     * @memberof CalendarEventResponse
     */
    'appointment': AppointmentDetailsResponse | null;
    /**
     * 
     * @type {SyncedCalendarEventDetailsResponse}
     * @memberof CalendarEventResponse
     */
    'synced_calendar_event': SyncedCalendarEventDetailsResponse | null;
}


/**
 * 
 * @export
 * @interface CalendarEventsResponse
 */
export interface CalendarEventsResponse {
    /**
     * 
     * @type {Array<CalendarEventResponse>}
     * @memberof CalendarEventsResponse
     */
    'events': Array<CalendarEventResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CardBrand = {
    Visa: 'Visa',
    Mastercard: 'Mastercard',
    AmericanExpress: 'American Express',
    Discover: 'Discover',
    Jcb: 'JCB',
    DinersClub: 'Diners Club',
    Unknown: 'Unknown'
} as const;

export type CardBrand = typeof CardBrand[keyof typeof CardBrand];


/**
 * 
 * @export
 * @enum {string}
 */

export const CardSource = {
    CreditCard: 'Credit Card',
    HsaCard: 'HSA Card',
    FsaCard: 'FSA Card'
} as const;

export type CardSource = typeof CardSource[keyof typeof CardSource];


/**
 * 
 * @export
 * @enum {string}
 */

export const CognitoUserStatus = {
    Confirmed: 'CONFIRMED',
    Unconfirmed: 'UNCONFIRMED',
    Archived: 'ARCHIVED',
    Compromised: 'COMPROMISED',
    Unknown: 'UNKNOWN',
    ResetRequired: 'RESET_REQUIRED',
    ForceChangePassword: 'FORCE_CHANGE_PASSWORD'
} as const;

export type CognitoUserStatus = typeof CognitoUserStatus[keyof typeof CognitoUserStatus];


/**
 * 
 * @export
 * @interface CommandOrEventSchema
 */
export interface CommandOrEventSchema {
    /**
     * 
     * @type {object}
     * @memberof CommandOrEventSchema
     */
    'properties': object;
    /**
     * 
     * @type {string}
     * @memberof CommandOrEventSchema
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface CommandsAndEventsResponse
 */
export interface CommandsAndEventsResponse {
    /**
     * 
     * @type {Array<CommandOrEventSchema>}
     * @memberof CommandsAndEventsResponse
     */
    'commands': Array<CommandOrEventSchema>;
    /**
     * 
     * @type {Array<CommandOrEventSchema>}
     * @memberof CommandsAndEventsResponse
     */
    'events': Array<CommandOrEventSchema>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CommercialPlanType = {
    Employer: 'employer',
    School: 'school'
} as const;

export type CommercialPlanType = typeof CommercialPlanType[keyof typeof CommercialPlanType];


/**
 * 
 * @export
 * @interface CompletePartnerBookingPayload
 */
export interface CompletePartnerBookingPayload {
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingPayload
     */
    'email': string;
    /**
     * 
     * @type {AddressDetails}
     * @memberof CompletePartnerBookingPayload
     */
    'address'?: AddressDetails | null;
    /**
     * 
     * @type {InsuranceDetails}
     * @memberof CompletePartnerBookingPayload
     */
    'insurance'?: InsuranceDetails | null;
    /**
     * 
     * @type {StripePaymentDetails}
     * @memberof CompletePartnerBookingPayload
     */
    'payment'?: StripePaymentDetails | null;
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingPayload
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingPayload
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingPayload
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingPayload
     */
    'reason': string;
    /**
     * 
     * @type {number}
     * @memberof CompletePartnerBookingPayload
     */
    'weight_in_lbs': number;
    /**
     * 
     * @type {number}
     * @memberof CompletePartnerBookingPayload
     */
    'height_in_inches': number;
    /**
     * 
     * @type {Sex}
     * @memberof CompletePartnerBookingPayload
     */
    'gender': Sex;
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingPayload
     */
    'mobile_phone_no': string;
    /**
     * 
     * @type {Array<EnrollmentTags>}
     * @memberof CompletePartnerBookingPayload
     */
    'tags'?: Array<EnrollmentTags>;
}


/**
 * 
 * @export
 * @interface CompletePartnerBookingResponse
 */
export interface CompletePartnerBookingResponse {
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingResponse
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof CompletePartnerBookingResponse
     */
    'recurring_donation_access_token': string | null;
}
/**
 * 
 * @export
 * @interface CreateCalendarBlockPayload
 */
export interface CreateCalendarBlockPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarBlockPayload
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarBlockPayload
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarBlockPayload
     */
    'end': string;
}
/**
 * 
 * @export
 * @interface CreateClaimPayload
 */
export interface CreateClaimPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateClaimPayload
     */
    'appointment_id': string;
}
/**
 * 
 * @export
 * @interface CreateClaimResponse
 */
export interface CreateClaimResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateClaimResponse
     */
    'claim_id': string | null;
}
/**
 * 
 * @export
 * @interface CreateExternalProvider
 */
export interface CreateExternalProvider {
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProvider
     */
    'provider_type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProvider
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProvider
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProvider
     */
    'taxonomy': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProvider
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProvider
     */
    'npi_number': string;
    /**
     * 
     * @type {Array<CreateExternalProviderAddress>}
     * @memberof CreateExternalProvider
     */
    'addresses': Array<CreateExternalProviderAddress>;
}
/**
 * 
 * @export
 * @interface CreateExternalProviderAddress
 */
export interface CreateExternalProviderAddress {
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'country_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'country_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'address_purpose': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'address_type': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'line1': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'line2': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'state': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'postal_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'phone_number': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalProviderAddress
     */
    'fax_number': string | null;
}
/**
 * 
 * @export
 * @interface CreateFormAnswer
 */
export interface CreateFormAnswer {
    /**
     * 
     * @type {string}
     * @memberof CreateFormAnswer
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFormAnswer
     */
    'answer': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateFormAnswer
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof CreateFormAnswer
     */
    'stable_id': string;
}
/**
 * 
 * @export
 * @interface CreateJournalEntryFileDetails
 */
export interface CreateJournalEntryFileDetails {
    /**
     * 
     * @type {string}
     * @memberof CreateJournalEntryFileDetails
     */
    'file_key': string;
}
/**
 * 
 * @export
 * @interface CreateJournalEntryFilePayload
 */
export interface CreateJournalEntryFilePayload {
    /**
     * 
     * @type {string}
     * @memberof CreateJournalEntryFilePayload
     */
    'file_key': string;
}
/**
 * 
 * @export
 * @interface CreateJournalEntryPayload
 */
export interface CreateJournalEntryPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateJournalEntryPayload
     */
    'content': string;
    /**
     * 
     * @type {Array<CreateJournalEntryFilePayload>}
     * @memberof CreateJournalEntryPayload
     */
    'files': Array<CreateJournalEntryFilePayload>;
    /**
     * 
     * @type {string}
     * @memberof CreateJournalEntryPayload
     */
    'occurred_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateJournalEntryPayload
     */
    'add_to_current_provider_chat'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateNutritionResearchThreadResponse
 */
export interface CreateNutritionResearchThreadResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateNutritionResearchThreadResponse
     */
    'thread_id': string;
}
/**
 * 
 * @export
 * @interface CreatePaymentInstrumentPayload
 */
export interface CreatePaymentInstrumentPayload {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentInstrumentPayload
     */
    'card_token': string;
    /**
     * 
     * @type {CardSource}
     * @memberof CreatePaymentInstrumentPayload
     */
    'card_source': CardSource;
}


/**
 * 
 * @export
 * @interface CursorPaginatedJournalEntriesResponse
 */
export interface CursorPaginatedJournalEntriesResponse {
    /**
     * 
     * @type {Array<JournalEntryResponse>}
     * @memberof CursorPaginatedJournalEntriesResponse
     */
    'entries': Array<JournalEntryResponse>;
    /**
     * 
     * @type {string}
     * @memberof CursorPaginatedJournalEntriesResponse
     */
    'next_cursor': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DayOfWeek = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type DayOfWeek = typeof DayOfWeek[keyof typeof DayOfWeek];


/**
 * 
 * @export
 * @interface EndDate
 */
export interface EndDate {
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EnrollmentTags = {
    Autoimmune: 'Autoimmune',
    Bariatric: 'Bariatric',
    Celiac: 'Celiac',
    Colitis: 'Colitis',
    CrohnsDisease: 'Crohn\'s Disease',
    Diabetes: 'Diabetes',
    EatingDisordersDisorderedEating: 'Eating Disorders & Disordered Eating',
    FoodAllergiesSensitivities: 'Food Allergies & Sensitivities',
    Gerd: 'GERD',
    GeneralNutrition: 'General Nutrition',
    GutHealth: 'Gut Health',
    HighBloodPressure: 'High Blood Pressure',
    HighCholesterol: 'High Cholesterol',
    Ibd: 'IBD',
    Ibs: 'IBS',
    KidneyDisease: 'Kidney Disease',
    Menopause: 'Menopause',
    OncologyCancer: 'Oncology / Cancer',
    Pcos: 'PCOS',
    Pots: 'POTS',
    Pediatrics: 'Pediatrics',
    PregnancyPostpartum: 'Pregnancy & Postpartum',
    Sibo: 'SIBO',
    SportsNutrition: 'Sports Nutrition',
    ThyroidHealth: 'Thyroid Health',
    Transplant: 'Transplant',
    VeganVegetarian: 'Vegan & Vegetarian',
    WeightLoss: 'Weight Loss',
    Pregnancy: 'Pregnancy',
    Oncology: 'Oncology'
} as const;

export type EnrollmentTags = typeof EnrollmentTags[keyof typeof EnrollmentTags];


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'detail': string;
}
/**
 * 
 * @export
 * @interface ExternalProviderList
 */
export interface ExternalProviderList {
    /**
     * 
     * @type {Array<CreateExternalProvider>}
     * @memberof ExternalProviderList
     */
    'providers': Array<CreateExternalProvider>;
}
/**
 * 
 * @export
 * @interface ExternalProviderResponse
 */
export interface ExternalProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'external_provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'provider_type': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'taxonomy': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'npi_number': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProviderResponse
     */
    'primary_email'?: string | null;
    /**
     * 
     * @type {Array<PatientExternalProviderAddress>}
     * @memberof ExternalProviderResponse
     */
    'addresses': Array<PatientExternalProviderAddress>;
}
/**
 * 
 * @export
 * @interface ExternalProviderResponseList
 */
export interface ExternalProviderResponseList {
    /**
     * 
     * @type {Array<ExternalProviderResponse>}
     * @memberof ExternalProviderResponseList
     */
    'providers': Array<ExternalProviderResponse>;
}
/**
 * 
 * @export
 * @interface FormAnswerResponse
 */
export interface FormAnswerResponse {
    /**
     * 
     * @type {string}
     * @memberof FormAnswerResponse
     */
    'form_answer_id': string;
    /**
     * 
     * @type {string}
     * @memberof FormAnswerResponse
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof FormAnswerResponse
     */
    'answer': string | null;
    /**
     * 
     * @type {number}
     * @memberof FormAnswerResponse
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof FormAnswerResponse
     */
    'stable_id': string;
}
/**
 * 
 * @export
 * @interface FormAnswerResponseList
 */
export interface FormAnswerResponseList {
    /**
     * 
     * @type {Array<FormAnswerResponse>}
     * @memberof FormAnswerResponseList
     */
    'answers': Array<FormAnswerResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Gender = {
    Male: 'Male',
    Female: 'Female',
    NonBinary: 'Non-binary',
    PreferNotToSay: 'Prefer not to say'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];


/**
 * 
 * @export
 * @interface GenerateMealPlanPayload
 */
export interface GenerateMealPlanPayload {
    /**
     * The ID of the patient to generate the meal plan for
     * @type {string}
     * @memberof GenerateMealPlanPayload
     */
    'patient_id': string;
    /**
     * A description of the meal plan
     * @type {string}
     * @memberof GenerateMealPlanPayload
     */
    'description': string;
    /**
     * 
     * @type {Array<MealPlanCondition>}
     * @memberof GenerateMealPlanPayload
     */
    'conditions': Array<MealPlanCondition>;
    /**
     * 
     * @type {number}
     * @memberof GenerateMealPlanPayload
     */
    'calorie_target'?: number | null;
}
/**
 * 
 * @export
 * @interface GetNutritionResearchThreadMessagesResponse
 */
export interface GetNutritionResearchThreadMessagesResponse {
    /**
     * 
     * @type {string}
     * @memberof GetNutritionResearchThreadMessagesResponse
     */
    'thread_id': string;
    /**
     * 
     * @type {Array<NutritionResearchMessageDetails>}
     * @memberof GetNutritionResearchThreadMessagesResponse
     */
    'messages': Array<NutritionResearchMessageDetails>;
}
/**
 * 
 * @export
 * @interface GetNutritionResearchThreadResponse
 */
export interface GetNutritionResearchThreadResponse {
    /**
     * 
     * @type {string}
     * @memberof GetNutritionResearchThreadResponse
     */
    'thread_id': string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HealthieImportPayload
 */
export interface HealthieImportPayload {
    /**
     * 
     * @type {string}
     * @memberof HealthieImportPayload
     */
    'healthie_patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof HealthieImportPayload
     */
    'provider_id': string;
}
/**
 * 
 * @export
 * @interface HealthieProviderResponse
 */
export interface HealthieProviderResponse {
    /**
     * Provider ID in Healthie
     * @type {string}
     * @memberof HealthieProviderResponse
     */
    'healthie_provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof HealthieProviderResponse
     */
    'provider_id': string | null;
    /**
     * Provider name from healthie
     * @type {string}
     * @memberof HealthieProviderResponse
     */
    'name': string;
    /**
     * Provider email from healthie
     * @type {string}
     * @memberof HealthieProviderResponse
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof HealthieProviderResponse
     */
    'patients_count': number | null;
    /**
     * 
     * @type {HealthieProviderValidationResponse}
     * @memberof HealthieProviderResponse
     */
    'validation': HealthieProviderValidationResponse | null;
    /**
     * Is fully migrated, with all dependent data
     * @type {boolean}
     * @memberof HealthieProviderResponse
     */
    'is_migrated': boolean;
    /**
     * Is the provider active in Healthie
     * @type {boolean}
     * @memberof HealthieProviderResponse
     */
    'is_active': boolean;
}
/**
 * 
 * @export
 * @interface HealthieProviderValidationResponse
 */
export interface HealthieProviderValidationResponse {
    /**
     * 
     * @type {string}
     * @memberof HealthieProviderValidationResponse
     */
    'started_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthieProviderValidationResponse
     */
    'completed_at': string | null;
    /**
     * 
     * @type {Array<HealthieValidationErrorResponse>}
     * @memberof HealthieProviderValidationResponse
     */
    'healthie_validation_errors'?: Array<HealthieValidationErrorResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof HealthieProviderValidationResponse
     */
    'passes_validation'?: boolean;
}
/**
 * 
 * @export
 * @interface HealthieValidationErrorResponse
 */
export interface HealthieValidationErrorResponse {
    /**
     * 
     * @type {HealthieValidationNamespace}
     * @memberof HealthieValidationErrorResponse
     */
    'namespace': HealthieValidationNamespace;
    /**
     * 
     * @type {string}
     * @memberof HealthieValidationErrorResponse
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof HealthieValidationErrorResponse
     */
    'actual_value': string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthieValidationErrorResponse
     */
    'expected_value': string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthieValidationErrorResponse
     */
    'extra'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const HealthieValidationNamespace = {
    Provider: 'provider',
    ProviderAvailability: 'provider_availability',
    ProviderFeedback: 'provider_feedback',
    AppointmentBlocks: 'appointment_blocks',
    Patient: 'patient',
    Appointment: 'appointment',
    Claim: 'claim',
    Chat: 'chat',
    General: 'general'
} as const;

export type HealthieValidationNamespace = typeof HealthieValidationNamespace[keyof typeof HealthieValidationNamespace];


/**
 * 
 * @export
 * @interface HeightFieldInput
 */
export interface HeightFieldInput {
    /**
     * 
     * @type {HeightUnits}
     * @memberof HeightFieldInput
     */
    'unit': HeightUnits;
    /**
     * 
     * @type {Value}
     * @memberof HeightFieldInput
     */
    'value': Value;
}


/**
 * 
 * @export
 * @interface HeightFieldOutput
 */
export interface HeightFieldOutput {
    /**
     * 
     * @type {HeightUnits}
     * @memberof HeightFieldOutput
     */
    'unit': HeightUnits;
    /**
     * 
     * @type {string}
     * @memberof HeightFieldOutput
     */
    'value': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const HeightUnits = {
    Inches: 'inches',
    Centimeters: 'centimeters'
} as const;

export type HeightUnits = typeof HeightUnits[keyof typeof HeightUnits];


/**
 * 
 * @export
 * @interface ImportPatientResponse
 */
export interface ImportPatientResponse {
    /**
     * 
     * @type {string}
     * @memberof ImportPatientResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportPatientResponse
     */
    'patient_email': string;
}
/**
 * 
 * @export
 * @interface ImportProvidersPayload
 */
export interface ImportProvidersPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportProvidersPayload
     */
    'healthie_provider_ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ImportProvidersPayload
     */
    'target_migration_date': string;
}
/**
 * 
 * @export
 * @interface ImportProvidersResponse
 */
export interface ImportProvidersResponse {
    /**
     * 
     * @type {Array<ImportResult>}
     * @memberof ImportProvidersResponse
     */
    'results': Array<ImportResult>;
    /**
     * 
     * @type {boolean}
     * @memberof ImportProvidersResponse
     */
    'all_success': boolean;
}
/**
 * 
 * @export
 * @interface ImportResult
 */
export interface ImportResult {
    /**
     * 
     * @type {string}
     * @memberof ImportResult
     */
    'provider_id': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ImportResult
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InsuranceCompany = {
    Aetna: 'Aetna',
    Cigna: 'Cigna',
    Humana: 'Humana',
    Anthem: 'Anthem',
    BlueCrossBlueShield: 'Blue Cross Blue Shield',
    BlueCareNetwork: 'Blue Care Network',
    BlueCross: 'Blue Cross',
    BlueShield: 'Blue Shield',
    Carefirst: 'Carefirst',
    Empire: 'Empire',
    FloridaBlue: 'Florida Blue',
    Highmark: 'Highmark',
    Horizon: 'Horizon',
    IndependenceBlueCross: 'Independence Blue Cross',
    UnitedHealthcare: 'United Healthcare',
    GoldenRule: 'Golden Rule',
    Optum: 'Optum',
    Oxford: 'Oxford',
    Umr: 'UMR',
    UnitedMedicalResources: 'United Medical Resources'
} as const;

export type InsuranceCompany = typeof InsuranceCompany[keyof typeof InsuranceCompany];


/**
 * 
 * @export
 * @interface InsuranceDetails
 */
export interface InsuranceDetails {
    /**
     * 
     * @type {string}
     * @memberof InsuranceDetails
     */
    'insurance_company': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceDetails
     */
    'member_id': string;
}
/**
 * 
 * @export
 * @interface IntakeFormAnswerList
 */
export interface IntakeFormAnswerList {
    /**
     * 
     * @type {Array<CreateFormAnswer>}
     * @memberof IntakeFormAnswerList
     */
    'answers': Array<CreateFormAnswer>;
}
/**
 * 
 * @export
 * @interface IntakeFormResponse
 */
export interface IntakeFormResponse {
    /**
     * 
     * @type {FormAnswerResponseList}
     * @memberof IntakeFormResponse
     */
    'form_answers'?: FormAnswerResponseList | null;
    /**
     * 
     * @type {ExternalProviderResponseList}
     * @memberof IntakeFormResponse
     */
    'external_providers'?: ExternalProviderResponseList | null;
    /**
     * 
     * @type {Gender}
     * @memberof IntakeFormResponse
     */
    'gender'?: Gender | null;
    /**
     * 
     * @type {string}
     * @memberof IntakeFormResponse
     */
    'pronouns'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntakeFormResponse
     */
    'profession'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntakeFormResponse
     */
    'work_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntakeFormResponse
     */
    'student_email'?: string | null;
}


/**
 * 
 * @export
 * @interface InternalAppointmentResponse
 */
export interface InternalAppointmentResponse {
    /**
     * 
     * @type {string}
     * @memberof InternalAppointmentResponse
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof InternalAppointmentResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof InternalAppointmentResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof InternalAppointmentResponse
     */
    'calendar_event_id': string;
    /**
     * 
     * @type {APIAppointmentStatus}
     * @memberof InternalAppointmentResponse
     */
    'status': APIAppointmentStatus;
    /**
     * 
     * @type {APIAppointmentType}
     * @memberof InternalAppointmentResponse
     */
    'appointment_type': APIAppointmentType;
    /**
     * 
     * @type {string}
     * @memberof InternalAppointmentResponse
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof InternalAppointmentResponse
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof InternalAppointmentResponse
     */
    'duration_in_minutes': number;
    /**
     * 
     * @type {RecurrenceType}
     * @memberof InternalAppointmentResponse
     */
    'recurrence_type': RecurrenceType | null;
    /**
     * 
     * @type {string}
     * @memberof InternalAppointmentResponse
     */
    'recurrence_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalAppointmentResponse
     */
    'chart_notes': string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalAppointmentResponse
     */
    'join_url': string;
    /**
     * 
     * @type {string}
     * @memberof InternalAppointmentResponse
     */
    'recurring_donation_access_token': string | null;
}


/**
 * 
 * @export
 * @interface InternalBookAppointmentPayload
 */
export interface InternalBookAppointmentPayload {
    /**
     * 
     * @type {string}
     * @memberof InternalBookAppointmentPayload
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof InternalBookAppointmentPayload
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof InternalBookAppointmentPayload
     */
    'start': string;
    /**
     * 
     * @type {APIRecurrenceType}
     * @memberof InternalBookAppointmentPayload
     */
    'recurrence_type': APIRecurrenceType | null;
}


/**
 * 
 * @export
 * @interface InternalRegisterPatientPayload
 */
export interface InternalRegisterPatientPayload {
    /**
     * 
     * @type {string}
     * @memberof InternalRegisterPatientPayload
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof InternalRegisterPatientPayload
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof InternalRegisterPatientPayload
     */
    'email': string;
    /**
     * 
     * @type {Sex}
     * @memberof InternalRegisterPatientPayload
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {string}
     * @memberof InternalRegisterPatientPayload
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalRegisterPatientPayload
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalRegisterPatientPayload
     */
    'timezone': string;
    /**
     * 
     * @type {HeightFieldInput}
     * @memberof InternalRegisterPatientPayload
     */
    'height'?: HeightFieldInput | null;
    /**
     * 
     * @type {WeightFieldInput}
     * @memberof InternalRegisterPatientPayload
     */
    'weight'?: WeightFieldInput | null;
    /**
     * 
     * @type {string}
     * @memberof InternalRegisterPatientPayload
     */
    'enrollment_reason'?: string | null;
    /**
     * 
     * @type {}
     * @memberof InternalRegisterPatientPayload
     */
    'enrollment_tags'?:  | null;
    /**
     * 
     * @type {PatientDiscoverySource}
     * @memberof InternalRegisterPatientPayload
     */
    'discovery_source'?: PatientDiscoverySource | null;
    /**
     * 
     * @type {string}
     * @memberof InternalRegisterPatientPayload
     */
    'stripe_customer_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalRegisterPatientPayload
     */
    'stripe_payment_method_id': string | null;
}


/**
 * 
 * @export
 * @interface JournalEntryFileResponse
 */
export interface JournalEntryFileResponse {
    /**
     * 
     * @type {string}
     * @memberof JournalEntryFileResponse
     */
    'journal_entry_file_id': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryFileResponse
     */
    'file_key': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryFileResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryFileResponse
     */
    'thumbnail_url': string | null;
}
/**
 * 
 * @export
 * @interface JournalEntryResponse
 */
export interface JournalEntryResponse {
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'journal_entry_id': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'content': string;
    /**
     * 
     * @type {Array<JournalEntryFileResponse>}
     * @memberof JournalEntryResponse
     */
    'files': Array<JournalEntryFileResponse>;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'occurred_at': string;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'updated_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalEntryResponse
     */
    'deleted_at': string | null;
}
/**
 * 
 * @export
 * @interface JournalStreakResponse
 */
export interface JournalStreakResponse {
    /**
     * Total number of journal entries in the consecutive day streak.
     * @type {number}
     * @memberof JournalStreakResponse
     */
    'journal_count': number;
    /**
     * Consecutive days of journaling. Resets to 0if the patient has not journaled today or yesterday.
     * @type {number}
     * @memberof JournalStreakResponse
     */
    'consecutive_days': number;
    /**
     * 
     * @type {string}
     * @memberof JournalStreakResponse
     */
    'start_date': string | null;
}
/**
 * 
 * @export
 * @interface LabResult
 */
export interface LabResult {
    /**
     * 
     * @type {string}
     * @memberof LabResult
     */
    'test': string;
    /**
     * 
     * @type {string}
     * @memberof LabResult
     */
    'result': string;
    /**
     * 
     * @type {string}
     * @memberof LabResult
     */
    'units': string | null;
    /**
     * 
     * @type {string}
     * @memberof LabResult
     */
    'reference_interval': string | null;
    /**
     * 
     * @type {LabResultStatus}
     * @memberof LabResult
     */
    'status': LabResultStatus | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const LabResultStatus = {
    Low: 'Low',
    Normal: 'Normal',
    High: 'High',
    NotSpecified: 'Not Specified',
    CheckLabReport: 'Check Lab Report'
} as const;

export type LabResultStatus = typeof LabResultStatus[keyof typeof LabResultStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const MealPlanCondition = {
    Autoimmune: 'autoimmune',
    PlannedBariatricSurgery: 'planned_bariatric_surgery',
    CeliacDisease: 'celiac_disease',
    Colitis: 'colitis',
    CrohnsDisease: 'crohns_disease',
    Diabetes: 'diabetes',
    EatingDisorder: 'eating_disorder',
    HealthyEating: 'healthy_eating',
    GutHealth: 'gut_health',
    HighCholesterol: 'high_cholesterol',
    HighBloodPressure: 'high_blood_pressure',
    IrritableBowelSyndrome: 'irritable_bowel_syndrome',
    InflammatoryBowelDisease: 'inflammatory_bowel_disease',
    KidneyDisease: 'kidney_disease',
    Pcos: 'pcos',
    Pots: 'pots',
    Pregnancy: 'pregnancy',
    Sibo: 'sibo',
    Gerd: 'gerd',
    WeightLoss: 'weight_loss',
    WeightGain: 'weight_gain'
} as const;

export type MealPlanCondition = typeof MealPlanCondition[keyof typeof MealPlanCondition];


/**
 * 
 * @export
 * @interface MealPlanResponse
 */
export interface MealPlanResponse {
    /**
     * The ID of the meal plan
     * @type {string}
     * @memberof MealPlanResponse
     */
    'meal_plan_id': string;
    /**
     * The ID of the patient for whom the meal plan is generated
     * @type {string}
     * @memberof MealPlanResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof MealPlanResponse
     */
    'chat_completion_id': string | null;
    /**
     * 
     * @type {Array<MealResponse>}
     * @memberof MealPlanResponse
     */
    'meals': Array<MealResponse>;
    /**
     * Indicates if the meal plan has been generated
     * @type {boolean}
     * @memberof MealPlanResponse
     */
    'is_generated': boolean;
}
/**
 * 
 * @export
 * @interface MealResponse
 */
export interface MealResponse {
    /**
     * The ID of the meal
     * @type {string}
     * @memberof MealResponse
     */
    'meal_id': string;
    /**
     * 
     * @type {MealType}
     * @memberof MealResponse
     */
    'meal_type': MealType;
    /**
     * 
     * @type {RecipeResponse}
     * @memberof MealResponse
     */
    'recipe': RecipeResponse;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MealType = {
    Breakfast: 'Breakfast',
    Lunch: 'Lunch',
    Dinner: 'Dinner',
    Snack: 'Snack'
} as const;

export type MealType = typeof MealType[keyof typeof MealType];


/**
 * 
 * @export
 * @interface NPIProviderAddress
 */
export interface NPIProviderAddress {
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'country_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'country_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'address_purpose'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'address_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'address_1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'address_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'postal_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'telephone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderAddress
     */
    'fax_number'?: string | null;
}
/**
 * 
 * @export
 * @interface NPIProviderResponse
 */
export interface NPIProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof NPIProviderResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderResponse
     */
    'middle_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderResponse
     */
    'npi_number': string;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderResponse
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof NPIProviderResponse
     */
    'taxonomy': string;
    /**
     * 
     * @type {Array<NPIProviderAddress>}
     * @memberof NPIProviderResponse
     */
    'addresses': Array<NPIProviderAddress>;
}
/**
 * 
 * @export
 * @interface NutritionResearchCitation
 */
export interface NutritionResearchCitation {
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitation
     */
    'nutrition_research_citation_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitation
     */
    'nutrition_research_message_id': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitation
     */
    'source_name': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitation
     */
    'citation_text': string;
    /**
     * 
     * @type {number}
     * @memberof NutritionResearchCitation
     */
    'citation_text_start_index': number;
}
/**
 * 
 * @export
 * @interface NutritionResearchCitationDetails
 */
export interface NutritionResearchCitationDetails {
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitationDetails
     */
    'citation_id': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitationDetails
     */
    'message_id': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitationDetails
     */
    'source_name': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchCitationDetails
     */
    'citation_text': string;
    /**
     * 
     * @type {number}
     * @memberof NutritionResearchCitationDetails
     */
    'citation_text_start_index': number;
}
/**
 * 
 * @export
 * @interface NutritionResearchFeedbackPayload
 */
export interface NutritionResearchFeedbackPayload {
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchFeedbackPayload
     */
    'message_id': string;
    /**
     * 
     * @type {NutritionResearchFeedbackRating}
     * @memberof NutritionResearchFeedbackPayload
     */
    'feedback_rating': NutritionResearchFeedbackRating;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NutritionResearchFeedbackRating = {
    Positive: 'positive',
    Negative: 'negative'
} as const;

export type NutritionResearchFeedbackRating = typeof NutritionResearchFeedbackRating[keyof typeof NutritionResearchFeedbackRating];


/**
 * 
 * @export
 * @interface NutritionResearchMessageDetails
 */
export interface NutritionResearchMessageDetails {
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchMessageDetails
     */
    'message_id': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchMessageDetails
     */
    'thread_id': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchMessageDetails
     */
    'content': string;
    /**
     * 
     * @type {Role}
     * @memberof NutritionResearchMessageDetails
     */
    'role': Role;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchMessageDetails
     */
    'created_at': string;
    /**
     * 
     * @type {NutritionResearchFeedbackRating}
     * @memberof NutritionResearchMessageDetails
     */
    'feedback_rating': NutritionResearchFeedbackRating | null;
    /**
     * 
     * @type {Array<NutritionResearchCitationDetails>}
     * @memberof NutritionResearchMessageDetails
     */
    'citations': Array<NutritionResearchCitationDetails>;
}


/**
 * 
 * @export
 * @interface NutritionResearchRunThreadResponse
 */
export interface NutritionResearchRunThreadResponse {
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchRunThreadResponse
     */
    'response': string;
    /**
     * 
     * @type {Array<NutritionResearchCitation>}
     * @memberof NutritionResearchRunThreadResponse
     */
    'citations': Array<NutritionResearchCitation>;
}
/**
 * 
 * @export
 * @interface NutritionResearchSendMessagePayload
 */
export interface NutritionResearchSendMessagePayload {
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchSendMessagePayload
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionResearchSendMessagePayload
     */
    'thread_id': string;
}
/**
 * 
 * @export
 * @interface OAuthResponse
 */
export interface OAuthResponse {
    /**
     * 
     * @type {boolean}
     * @memberof OAuthResponse
     */
    'is_connected'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OAuthResponse
     */
    'is_revoked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OAuthResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OAuthResponse
     */
    'connect_url'?: string | null;
}
/**
 * 
 * @export
 * @interface OffboardStatusResponse
 */
export interface OffboardStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof OffboardStatusResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof OffboardStatusResponse
     */
    'provider_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof OffboardStatusResponse
     */
    'cognito_offboarded': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OffboardStatusResponse
     */
    'circle_offboarded': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OffboardStatusResponse
     */
    'appointments_cancelled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OffboardStatusResponse
     */
    'database_offboarded': boolean;
}
/**
 * 
 * @export
 * @interface OnboardableProviderResponse
 */
export interface OnboardableProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof OnboardableProviderResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardableProviderResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardableProviderResponse
     */
    'npi': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardableProviderResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OnboardableProviderResponse
     */
    'timezone': string | null;
}
/**
 * 
 * @export
 * @interface PartnerBookingVerifyPaymentMethodPayload
 */
export interface PartnerBookingVerifyPaymentMethodPayload {
    /**
     * 
     * @type {string}
     * @memberof PartnerBookingVerifyPaymentMethodPayload
     */
    'stripe_token': string;
    /**
     * 
     * @type {CardSource}
     * @memberof PartnerBookingVerifyPaymentMethodPayload
     */
    'card_source': CardSource;
    /**
     * 
     * @type {string}
     * @memberof PartnerBookingVerifyPaymentMethodPayload
     */
    'partner_booking_code': string;
}


/**
 * 
 * @export
 * @interface PatientAddress
 */
export interface PatientAddress {
    /**
     * 
     * @type {string}
     * @memberof PatientAddress
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof PatientAddress
     */
    'line2': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientAddress
     */
    'city': string;
    /**
     * 
     * @type {USState}
     * @memberof PatientAddress
     */
    'state': USState;
    /**
     * 
     * @type {string}
     * @memberof PatientAddress
     */
    'postal_code': string;
    /**
     * 
     * @type {string}
     * @memberof PatientAddress
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof PatientAddress
     */
    'address_id': string;
}


/**
 * 
 * @export
 * @interface PatientBookingSubmitEmailPayload
 */
export interface PatientBookingSubmitEmailPayload {
    /**
     * 
     * @type {string}
     * @memberof PatientBookingSubmitEmailPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PatientBookingSubmitEmailPayload
     */
    'provider_id': string;
}
/**
 * 
 * @export
 * @interface PatientBookingSubmitEmailResponse
 */
export interface PatientBookingSubmitEmailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PatientBookingSubmitEmailResponse
     */
    'found': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientBookingSubmitEmailResponse
     */
    'is_intake_required': boolean;
}
/**
 * 
 * @export
 * @interface PatientCommercialPlanResponse
 */
export interface PatientCommercialPlanResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientCommercialPlanResponse
     */
    'commercial_plan_id': string;
    /**
     * 
     * @type {CommercialPlanType}
     * @memberof PatientCommercialPlanResponse
     */
    'commercial_plan_type': CommercialPlanType;
}


/**
 * 
 * @export
 * @interface PatientConfirmationByCodePayload
 */
export interface PatientConfirmationByCodePayload {
    /**
     * 
     * @type {string}
     * @memberof PatientConfirmationByCodePayload
     */
    'new_password': string;
    /**
     * 
     * @type {string}
     * @memberof PatientConfirmationByCodePayload
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface PatientConfirmationPayload
 */
export interface PatientConfirmationPayload {
    /**
     * 
     * @type {string}
     * @memberof PatientConfirmationPayload
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface PatientConfirmationPreflightResponse
 */
export interface PatientConfirmationPreflightResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientConfirmationPreflightResponse
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientConfirmationPreflightResponse
     */
    'can_confirm': boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientConfirmationPreflightResponse
     */
    'cognito_id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PatientDetailsInclusions = {
    NextAppointment: 'next_appointment',
    OldPatients: 'old_patients'
} as const;

export type PatientDetailsInclusions = typeof PatientDetailsInclusions[keyof typeof PatientDetailsInclusions];


/**
 * 
 * @export
 * @interface PatientDetailsListResponse
 */
export interface PatientDetailsListResponse {
    /**
     * 
     * @type {Array<PatientDetailsResponse>}
     * @memberof PatientDetailsListResponse
     */
    'patients': Array<PatientDetailsResponse>;
}
/**
 * 
 * @export
 * @interface PatientDetailsResponse
 */
export interface PatientDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'provider_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'cognito_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'preferred_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'full_legal_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'joined_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'avatar_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'enrollment_reason'?: string | null;
    /**
     * 
     * @type {}
     * @memberof PatientDetailsResponse
     */
    'enrollment_tags'?:  | null;
    /**
     * 
     * @type {Sex}
     * @memberof PatientDetailsResponse
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {WeightFieldOutput}
     * @memberof PatientDetailsResponse
     */
    'weight'?: WeightFieldOutput | null;
    /**
     * 
     * @type {HeightFieldOutput}
     * @memberof PatientDetailsResponse
     */
    'height'?: HeightFieldOutput | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'timezone': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'pronouns'?: string | null;
    /**
     * 
     * @type {PatientInsurancePolicyResponse}
     * @memberof PatientDetailsResponse
     */
    'insurance_policy'?: PatientInsurancePolicyResponse | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDetailsResponse
     */
    'insurance_policy_hidden'?: boolean;
    /**
     * 
     * @type {PatientAddress}
     * @memberof PatientDetailsResponse
     */
    'mailing_address'?: PatientAddress | null;
    /**
     * 
     * @type {UpcomingAppointmentSummary}
     * @memberof PatientDetailsResponse
     */
    'next_appointment'?: UpcomingAppointmentSummary | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponse
     */
    'current_provider_id'?: string | null;
    /**
     * 
     * @type {ExternalProviderResponseList}
     * @memberof PatientDetailsResponse
     */
    'external_providers'?: ExternalProviderResponseList | null;
    /**
     * 
     * @type {PatientInformationReleaseAuthorization}
     * @memberof PatientDetailsResponse
     */
    'information_release_authorization'?: PatientInformationReleaseAuthorization | null;
    /**
     * 
     * @type {PatientCommercialPlanResponse}
     * @memberof PatientDetailsResponse
     */
    'commercial_plan'?: PatientCommercialPlanResponse | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PatientDiscoverySource = {
    DoctorRecommendedISeeADietitian: 'Doctor recommended I see a dietitian',
    DoctorRecommendedFay: 'Doctor recommended Fay',
    DoctorRecommendedThisDietitian: 'Doctor recommended this dietitian',
    TherapistRecommendedISeeADietitian: 'Therapist recommended I see a dietitian',
    TherapistRecommendedFay: 'Therapist recommended Fay',
    TherapistRecommendedThisDietitian: 'Therapist recommended this dietitian',
    FriendRecommendedISeeADietitian: 'Friend recommended I see a dietitian',
    FriendRecommendedThisDietitian: 'Friend recommended this dietitian',
    FriendRecommendedFay: 'Friend recommended Fay',
    FamilyRecommendedISeeADietitian: 'Family recommended I see a dietitian ',
    FamilyMemberRecommendedThisDietitian: 'Family member recommended this dietitian',
    FamilyMemberRecommendedFay: 'Family member recommended Fay',
    CareCoordinatorRecommendedISeeADietitian: 'Care coordinator recommended I see a dietitian',
    CareCoordinatorRecommendedFay: 'Care coordinator recommended Fay',
    CareCoordinatorRecommendedThisDietitian: 'Care coordinator recommended this dietitian',
    InstagramPostFromADietitian: 'Instagram post from a dietitian',
    Instagram: 'Instagram',
    InstagramAd: 'Instagram Ad',
    FacebookPostFromADietitian: 'Facebook post from a dietitian',
    Facebook: 'Facebook',
    FacebookAd: 'Facebook Ad',
    TikTokPostFromADietitian: 'TikTok post from a dietitian',
    TikTok: 'TikTok',
    TikTokAd: 'TikTok Ad',
    DietitiansWebsite: 'Dietitians website',
    Google: 'Google',
    GoogleSearch: 'Google Search',
    GoogleMaps: 'Google Maps',
    GoogleAd: 'Google Ad',
    Bing: 'Bing',
    BingSearch: 'Bing Search',
    BingAd: 'Bing Ad',
    EatingDisorderHope: 'Eating Disorder Hope',
    Neda: 'NEDA',
    ExternalBlog: 'External Blog',
    FayBlog: 'Fay Blog',
    Podcast: 'Podcast',
    InsuranceRepresentative: 'Insurance representative',
    InsuranceDirectory: 'Insurance directory',
    InsuranceAdvertisement: 'Insurance advertisement',
    Healthgrades: 'Healthgrades',
    Zocdoc: 'Zocdoc',
    EmployerBenefit: 'Employer benefit',
    SchoolBenefit: 'School benefit',
    WebMd: 'WebMD',
    Healthline: 'Healthline',
    Other: 'Other'
} as const;

export type PatientDiscoverySource = typeof PatientDiscoverySource[keyof typeof PatientDiscoverySource];


/**
 * 
 * @export
 * @interface PatientExternalFacingDetailsResponse
 */
export interface PatientExternalFacingDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'patient_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'patient_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'patient_date_of_birth'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'appointment_count': number;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'latest_chart_notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'latest_chart_notes_timestamp'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'height_in_inches': number | null;
    /**
     * 
     * @type {number}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'weight_in_lbs': number | null;
    /**
     * 
     * @type {number}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'bmi': number | null;
    /**
     * 
     * @type {Array<EnrollmentTags>}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'enrollment_tags': Array<EnrollmentTags>;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'provider_full_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'provider_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'provider_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'provider_avatar_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'provider_slug': string;
    /**
     * 
     * @type {Array<InsuranceCompany>}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'supported_billing_insurance_companies': Array<InsuranceCompany>;
    /**
     * 
     * @type {Array<InsuranceCompany>}
     * @memberof PatientExternalFacingDetailsResponse
     */
    'supported_booking_insurance_companies': Array<InsuranceCompany>;
}
/**
 * 
 * @export
 * @interface PatientExternalProviderAddress
 */
export interface PatientExternalProviderAddress {
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'external_provider_address_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'external_provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'country_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'country_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'address_purpose': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'address_type': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'line1': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'line2': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'state': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'postal_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'phone_number': string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientExternalProviderAddress
     */
    'fax_number': string | null;
}
/**
 * 
 * @export
 * @interface PatientId
 */
export interface PatientId {
}
/**
 * 
 * @export
 * @interface PatientId1
 */
export interface PatientId1 {
}
/**
 * 
 * @export
 * @interface PatientInfo
 */
export interface PatientInfo {
    /**
     * 
     * @type {string}
     * @memberof PatientInfo
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInfo
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInfo
     */
    'date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInfo
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInfo
     */
    'email_address': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInfo
     */
    'mobile_phone_no': string;
}
/**
 * 
 * @export
 * @interface PatientInformationReleaseAuthorization
 */
export interface PatientInformationReleaseAuthorization {
    /**
     * 
     * @type {string}
     * @memberof PatientInformationReleaseAuthorization
     */
    'information_release_authorization_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInformationReleaseAuthorization
     */
    'authorized_at': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInformationReleaseAuthorization
     */
    'release_form_template_link': string;
}
/**
 * 
 * @export
 * @interface PatientInsurancePolicyResponse
 */
export interface PatientInsurancePolicyResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicyResponse
     */
    'policy_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientInsurancePolicyResponse
     */
    'policy_number': string;
    /**
     * 
     * @type {InsuranceCompany}
     * @memberof PatientInsurancePolicyResponse
     */
    'payer': InsuranceCompany;
}


/**
 * 
 * @export
 * @interface PatientLabResponse
 */
export interface PatientLabResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientLabResponse
     */
    'patient_lab_id': string;
    /**
     * 
     * @type {string}
     * @memberof PatientLabResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PatientLabResponse
     */
    'uploaded_at': string;
    /**
     * 
     * @type {string}
     * @memberof PatientLabResponse
     */
    'document_link': string;
    /**
     * 
     * @type {Array<LabResult>}
     * @memberof PatientLabResponse
     */
    'lab_results': Array<LabResult>;
    /**
     * 
     * @type {PatientLabStatus}
     * @memberof PatientLabResponse
     */
    'status': PatientLabStatus;
    /**
     * 
     * @type {boolean}
     * @memberof PatientLabResponse
     */
    'can_accept_feedback': boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PatientLabStatus = {
    Processing: 'Processing',
    Completed: 'Completed',
    Failed: 'Failed'
} as const;

export type PatientLabStatus = typeof PatientLabStatus[keyof typeof PatientLabStatus];


/**
 * 
 * @export
 * @interface PatientLabsResponse
 */
export interface PatientLabsResponse {
    /**
     * 
     * @type {Array<PatientLabResponse>}
     * @memberof PatientLabsResponse
     */
    'patient_labs': Array<PatientLabResponse>;
}
/**
 * 
 * @export
 * @interface PatientRecapResponse
 */
export interface PatientRecapResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {PatientRecapStatus}
     * @memberof PatientRecapResponse
     */
    'status': PatientRecapStatus;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'personal_life_details'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'personal_life_details_chat_completion_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'work_life_details'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'work_life_details_chat_completion_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'foods_to_avoid_details'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'foods_to_avoid_details_chat_completion_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'income_levels_details'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'income_levels_details_chat_completion_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'last_appointment_summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientRecapResponse
     */
    'last_appointment_summary_chat_completion_id'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PatientRecapStatus = {
    Initialized: 'initialized',
    NeedsOccurredAppointment: 'needs_occurred_appointment',
    NeedsUpdatedRecapFields: 'needs_updated_recap_fields',
    Done: 'done'
} as const;

export type PatientRecapStatus = typeof PatientRecapStatus[keyof typeof PatientRecapStatus];


/**
 * 
 * @export
 * @interface PatientReleaseFormTemplateUrlResponse
 */
export interface PatientReleaseFormTemplateUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof PatientReleaseFormTemplateUrlResponse
     */
    'release_form_template_url': string;
}
/**
 * 
 * @export
 * @interface PatientUTMParametersPayload
 */
export interface PatientUTMParametersPayload {
    /**
     * 
     * @type {Array<UTMParameter>}
     * @memberof PatientUTMParametersPayload
     */
    'utm_parameters': Array<UTMParameter>;
}
/**
 * 
 * @export
 * @interface PaymentInstrumentResponse
 */
export interface PaymentInstrumentResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentResponse
     */
    'payment_instrument_id': string;
    /**
     * 
     * @type {CardBrand}
     * @memberof PaymentInstrumentResponse
     */
    'brand': CardBrand;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentResponse
     */
    'last_four': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentInstrumentResponse
     */
    'expiration_month': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentInstrumentResponse
     */
    'expiration_year': number;
    /**
     * 
     * @type {CardSource}
     * @memberof PaymentInstrumentResponse
     */
    'card_source': CardSource;
}


/**
 * 
 * @export
 * @interface PayoutDetailsResponse
 */
export interface PayoutDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof PayoutDetailsResponse
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutDetailsResponse
     */
    'period_start': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutDetailsResponse
     */
    'period_end': string;
    /**
     * 
     * @type {Array<PayoutLineItemResponse>}
     * @memberof PayoutDetailsResponse
     */
    'line_items': Array<PayoutLineItemResponse>;
}
/**
 * 
 * @export
 * @interface PayoutLineItemResponse
 */
export interface PayoutLineItemResponse {
    /**
     * 
     * @type {string}
     * @memberof PayoutLineItemResponse
     */
    'appointment_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof PayoutLineItemResponse
     */
    'appointment_time': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutLineItemResponse
     */
    'rate': string | null;
    /**
     * 
     * @type {string}
     * @memberof PayoutLineItemResponse
     */
    'payment_source': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutLineItemResponse
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface PayoutsListResponse
 */
export interface PayoutsListResponse {
    /**
     * 
     * @type {string}
     * @memberof PayoutsListResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {Array<PayoutDetailsResponse>}
     * @memberof PayoutsListResponse
     */
    'payouts': Array<PayoutDetailsResponse>;
}
/**
 * 
 * @export
 * @interface PracticeMetricsResponse
 */
export interface PracticeMetricsResponse {
    /**
     * 
     * @type {string}
     * @memberof PracticeMetricsResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof PracticeMetricsResponse
     */
    'as_of_date': string;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'weekly_availability': number;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'weekly_retention': number | null;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'average_appointment_length': number | null;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'average_rating': number | null;
    /**
     * 
     * @type {number}
     * @memberof PracticeMetricsResponse
     */
    'score': number | null;
}
/**
 * 
 * @export
 * @interface ProviderAvailabilityScheduleResponse
 */
export interface ProviderAvailabilityScheduleResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderAvailabilityScheduleResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {Array<ProviderAvailabilityWindowResponse>}
     * @memberof ProviderAvailabilityScheduleResponse
     */
    'windows': Array<ProviderAvailabilityWindowResponse>;
}
/**
 * 
 * @export
 * @interface ProviderAvailabilityWindowResponse
 */
export interface ProviderAvailabilityWindowResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderAvailabilityWindowResponse
     */
    'provider_availability_window_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderAvailabilityWindowResponse
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderAvailabilityWindowResponse
     */
    'end_time': string;
    /**
     * 
     * @type {DayOfWeek}
     * @memberof ProviderAvailabilityWindowResponse
     */
    'day_of_week': DayOfWeek;
    /**
     * 
     * @type {string}
     * @memberof ProviderAvailabilityWindowResponse
     */
    'timezone': string;
}


/**
 * 
 * @export
 * @interface ProviderBatchActionPayload
 */
export interface ProviderBatchActionPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProviderBatchActionPayload
     */
    'provider_ids': Array<string>;
}
/**
 * 
 * @export
 * @interface ProviderBatchActionResponse
 */
export interface ProviderBatchActionResponse {
    /**
     * 
     * @type {Array<ProviderBatchActionResult>}
     * @memberof ProviderBatchActionResponse
     */
    'results': Array<ProviderBatchActionResult>;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderBatchActionResponse
     */
    'all_success': boolean;
}
/**
 * 
 * @export
 * @interface ProviderBatchActionResult
 */
export interface ProviderBatchActionResult {
    /**
     * 
     * @type {string}
     * @memberof ProviderBatchActionResult
     */
    'provider_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderBatchActionResult
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface ProviderConfigDetails
 */
export interface ProviderConfigDetails {
    /**
     * 
     * @type {string}
     * @memberof ProviderConfigDetails
     */
    'provider_config_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderConfigDetails
     */
    'offers_free_consultations': boolean;
}
/**
 * 
 * @export
 * @interface ProviderConfirmationPayload
 */
export interface ProviderConfirmationPayload {
    /**
     * 
     * @type {string}
     * @memberof ProviderConfirmationPayload
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface ProviderConfirmationPreflightResponse
 */
export interface ProviderConfirmationPreflightResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderConfirmationPreflightResponse
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderConfirmationPreflightResponse
     */
    'can_confirm': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProviderConfirmationPreflightResponse
     */
    'cognito_id': string;
}
/**
 * 
 * @export
 * @interface ProviderDetails
 */
export interface ProviderDetails {
    /**
     * 
     * @type {string}
     * @memberof ProviderDetails
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetails
     */
    'provider_name': string;
}
/**
 * 
 * @export
 * @interface ProviderDetailsResponse
 */
export interface ProviderDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'preferred_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'full_legal_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'npi': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'timezone': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDetailsResponse
     */
    'is_imported': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDetailsResponse
     */
    'is_migrated': boolean;
    /**
     * 
     * @type {Sex}
     * @memberof ProviderDetailsResponse
     */
    'sex': Sex | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'pronouns': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'phone_number': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'avatar_url': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDetailsResponse
     */
    'is_admin': boolean;
    /**
     * 
     * @type {Array<InsuranceCompany>}
     * @memberof ProviderDetailsResponse
     */
    'supported_booking_insurance_companies': Array<InsuranceCompany>;
    /**
     * 
     * @type {Array<InsuranceCompany>}
     * @memberof ProviderDetailsResponse
     */
    'supported_billing_insurance_companies': Array<InsuranceCompany>;
    /**
     * 
     * @type {Array<USState>}
     * @memberof ProviderDetailsResponse
     */
    'supported_us_states': Array<USState>;
    /**
     * 
     * @type {ProviderSettings}
     * @memberof ProviderDetailsResponse
     */
    'settings': ProviderSettings;
    /**
     * 
     * @type {ProviderConfigDetails}
     * @memberof ProviderDetailsResponse
     */
    'provider_config': ProviderConfigDetails;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'healthie_provider_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderDetailsResponse
     */
    'target_migration_date'?: string | null;
}


/**
 * 
 * @export
 * @interface ProviderForPatientDetailsResponse
 */
export interface ProviderForPatientDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'cognito_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'preferred_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'avatar_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'timezone': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderForPatientDetailsResponse
     */
    'is_migrated': boolean;
    /**
     * 
     * @type {Sex}
     * @memberof ProviderForPatientDetailsResponse
     */
    'sex': Sex | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'pronouns': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderForPatientDetailsResponse
     */
    'current': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProviderForPatientDetailsResponse
     */
    'started_seeing_provider_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderForPatientDetailsResponse
     */
    'is_admin': boolean;
}


/**
 * 
 * @export
 * @interface ProviderId
 */
export interface ProviderId {
}
/**
 * 
 * @export
 * @interface ProviderId1
 */
export interface ProviderId1 {
}
/**
 * 
 * @export
 * @interface ProviderIdentityDetailsResponse
 */
export interface ProviderIdentityDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ProviderIdentityDetailsResponse
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderIdentityDetailsResponse
     */
    'cognito_id': string;
    /**
     * 
     * @type {CognitoUserStatus}
     * @memberof ProviderIdentityDetailsResponse
     */
    'status': CognitoUserStatus;
    /**
     * 
     * @type {string}
     * @memberof ProviderIdentityDetailsResponse
     */
    'created_at': string;
}


/**
 * 
 * @export
 * @interface ProviderSettings
 */
export interface ProviderSettings {
    /**
     * 
     * @type {number}
     * @memberof ProviderSettings
     */
    'minimum_advance_schedule_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProviderSettings
     */
    'max_days_in_future'?: number;
}
/**
 * 
 * @export
 * @interface RecipeResponse
 */
export interface RecipeResponse {
    /**
     * The name of the recipe
     * @type {string}
     * @memberof RecipeResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecipeResponse
     */
    'ingredients': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecipeResponse
     */
    'steps': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RecipeResponse
     */
    'image_url': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RecurrenceType = {
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    BiWeekly: 'Bi-weekly'
} as const;

export type RecurrenceType = typeof RecurrenceType[keyof typeof RecurrenceType];


/**
 * 
 * @export
 * @interface RecurringDonationResponse
 */
export interface RecurringDonationResponse {
    /**
     * 
     * @type {string}
     * @memberof RecurringDonationResponse
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface RegisterPatientPayload
 */
export interface RegisterPatientPayload {
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'email': string;
    /**
     * 
     * @type {Sex}
     * @memberof RegisterPatientPayload
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'timezone': string;
    /**
     * 
     * @type {HeightFieldInput}
     * @memberof RegisterPatientPayload
     */
    'height'?: HeightFieldInput | null;
    /**
     * 
     * @type {WeightFieldInput}
     * @memberof RegisterPatientPayload
     */
    'weight'?: WeightFieldInput | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPatientPayload
     */
    'enrollment_reason'?: string | null;
    /**
     * 
     * @type {}
     * @memberof RegisterPatientPayload
     */
    'enrollment_tags'?:  | null;
    /**
     * 
     * @type {PatientDiscoverySource}
     * @memberof RegisterPatientPayload
     */
    'discovery_source'?: PatientDiscoverySource | null;
}


/**
 * 
 * @export
 * @interface RegisterProviderPayload
 */
export interface RegisterProviderPayload {
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'provider_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'cognito_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'npi': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProviderPayload
     */
    'timezone': string;
    /**
     * Is the provider a Fay Staff admin?
     * @type {boolean}
     * @memberof RegisterProviderPayload
     */
    'is_admin'?: boolean;
    /**
     * Is the provider a test provider?
     * @type {boolean}
     * @memberof RegisterProviderPayload
     */
    'is_test'?: boolean;
}
/**
 * 
 * @export
 * @interface ReleaseOfInformationPayload
 */
export interface ReleaseOfInformationPayload {
    /**
     * 
     * @type {boolean}
     * @memberof ReleaseOfInformationPayload
     */
    'authorized': boolean;
    /**
     * 
     * @type {string}
     * @memberof ReleaseOfInformationPayload
     */
    'release_form_template_link'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Role = {
    User: 'user',
    Assistant: 'assistant'
} as const;

export type Role = typeof Role[keyof typeof Role];


/**
 * 
 * @export
 * @interface SetTargetMigrationDatePayload
 */
export interface SetTargetMigrationDatePayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof SetTargetMigrationDatePayload
     */
    'provider_ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SetTargetMigrationDatePayload
     */
    'target_migration_date': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Sex = {
    Male: 'Male',
    Female: 'Female',
    Other: 'Other',
    PreferNotToSay: 'Prefer not to say'
} as const;

export type Sex = typeof Sex[keyof typeof Sex];


/**
 * 
 * @export
 * @interface StartDate
 */
export interface StartDate {
}
/**
 * 
 * @export
 * @interface StripePaymentDetails
 */
export interface StripePaymentDetails {
    /**
     * 
     * @type {CardSource}
     * @memberof StripePaymentDetails
     */
    'payment_type': CardSource;
    /**
     * 
     * @type {string}
     * @memberof StripePaymentDetails
     */
    'stripe_code': string;
}


/**
 * 
 * @export
 * @interface SuggestChatResponsePayload
 */
export interface SuggestChatResponsePayload {
    /**
     * 
     * @type {string}
     * @memberof SuggestChatResponsePayload
     */
    'patient_id': string;
}
/**
 * 
 * @export
 * @interface SuggestChatResponseResponse
 */
export interface SuggestChatResponseResponse {
    /**
     * 
     * @type {string}
     * @memberof SuggestChatResponseResponse
     */
    'response': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestChatResponseResponse
     */
    'chat_completion_id': string;
}
/**
 * 
 * @export
 * @interface SyncedCalendarEventDetailsResponse
 */
export interface SyncedCalendarEventDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'synced_calendar_event_id': string;
    /**
     * 
     * @type {string}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'synced_calendar_id': string;
    /**
     * 
     * @type {string}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'ical_uid': string;
    /**
     * 
     * @type {string}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'source_calendar_event_id': string;
    /**
     * 
     * @type {SyncedCalendarSource}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'source': SyncedCalendarSource;
    /**
     * 
     * @type {string}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'synced_event_url': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'is_busy': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyncedCalendarEventDetailsResponse
     */
    'is_cancelled': boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SyncedCalendarSource = {
    Google: 'google'
} as const;

export type SyncedCalendarSource = typeof SyncedCalendarSource[keyof typeof SyncedCalendarSource];


/**
 * 
 * @export
 * @enum {string}
 */

export const USState = {
    Al: 'AL',
    Ak: 'AK',
    Az: 'AZ',
    Ar: 'AR',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ia: 'IA',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Me: 'ME',
    Md: 'MD',
    Ma: 'MA',
    Mi: 'MI',
    Mn: 'MN',
    Ms: 'MS',
    Mo: 'MO',
    Mt: 'MT',
    Ne: 'NE',
    Nv: 'NV',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Ny: 'NY',
    Nc: 'NC',
    Nd: 'ND',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Vt: 'VT',
    Va: 'VA',
    Wa: 'WA',
    Wv: 'WV',
    Wi: 'WI',
    Wy: 'WY',
    As: 'AS',
    Dc: 'DC',
    Gu: 'GU',
    Mp: 'MP',
    Pr: 'PR',
    Vi: 'VI',
    Aa: 'AA',
    Ae: 'AE',
    Ap: 'AP'
} as const;

export type USState = typeof USState[keyof typeof USState];


/**
 * 
 * @export
 * @interface UTMParameter
 */
export interface UTMParameter {
    /**
     * 
     * @type {string}
     * @memberof UTMParameter
     */
    'parameter_name': string;
    /**
     * 
     * @type {string}
     * @memberof UTMParameter
     */
    'parameter_value': string;
}
/**
 * 
 * @export
 * @interface UpcomingAppointmentSummary
 */
export interface UpcomingAppointmentSummary {
    /**
     * 
     * @type {string}
     * @memberof UpcomingAppointmentSummary
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingAppointmentSummary
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingAppointmentSummary
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingAppointmentSummary
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingAppointmentSummary
     */
    'end_time': string;
    /**
     * 
     * @type {RecurrenceType}
     * @memberof UpcomingAppointmentSummary
     */
    'recurrence_type'?: RecurrenceType | null;
    /**
     * 
     * @type {number}
     * @memberof UpcomingAppointmentSummary
     */
    'duration': number;
}


/**
 * 
 * @export
 * @interface UpdateAddressPayload
 */
export interface UpdateAddressPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'postal_code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface UpdateAppointmentPayload
 */
export interface UpdateAppointmentPayload {
    /**
     * 
     * @type {APIAppointmentStatus}
     * @memberof UpdateAppointmentPayload
     */
    'status'?: APIAppointmentStatus | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentPayload
     */
    'start'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentPayload
     */
    'duration_in_minutes'?: number | null;
    /**
     * 
     * @type {APIAppointmentType}
     * @memberof UpdateAppointmentPayload
     */
    'appointment_type'?: APIAppointmentType | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentPayload
     */
    'chart_notes'?: string | null;
}


/**
 * 
 * @export
 * @interface UpdateCalendarEventPayload
 */
export interface UpdateCalendarEventPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateCalendarEventPayload
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalendarEventPayload
     */
    'start': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCalendarEventPayload
     */
    'end': string | null;
}
/**
 * 
 * @export
 * @interface UpdateChatCompletionFeedbackPayload
 */
export interface UpdateChatCompletionFeedbackPayload {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateChatCompletionFeedbackPayload
     */
    'binary_feedback'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateChatCompletionFeedbackPayload
     */
    'feedback_text'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateInsurancePolicyPayload
 */
export interface UpdateInsurancePolicyPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateInsurancePolicyPayload
     */
    'policy_number': string;
    /**
     * 
     * @type {InsuranceCompany}
     * @memberof UpdateInsurancePolicyPayload
     */
    'payer': InsuranceCompany;
}


/**
 * 
 * @export
 * @interface UpdateIntakePayload
 */
export interface UpdateIntakePayload {
    /**
     * 
     * @type {IntakeFormAnswerList}
     * @memberof UpdateIntakePayload
     */
    'intake_form_answers'?: IntakeFormAnswerList | null;
    /**
     * 
     * @type {ExternalProviderList}
     * @memberof UpdateIntakePayload
     */
    'external_providers'?: ExternalProviderList | null;
    /**
     * 
     * @type {Gender}
     * @memberof UpdateIntakePayload
     */
    'gender'?: Gender | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntakePayload
     */
    'pronouns'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntakePayload
     */
    'profession'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntakePayload
     */
    'work_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntakePayload
     */
    'student_email'?: string | null;
}


/**
 * 
 * @export
 * @interface UpdateJournalEntryPayload
 */
export interface UpdateJournalEntryPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateJournalEntryPayload
     */
    'content'?: string | null;
    /**
     * 
     * @type {}
     * @memberof UpdateJournalEntryPayload
     */
    'files'?:  | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateJournalEntryPayload
     */
    'occurred_at'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateMealPayload
 */
export interface UpdateMealPayload {
    /**
     * 
     * @type {UpdateRecipePayload}
     * @memberof UpdateMealPayload
     */
    'recipe': UpdateRecipePayload;
}
/**
 * 
 * @export
 * @interface UpdatePatientPayload
 */
export interface UpdatePatientPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'preferred_first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {Sex}
     * @memberof UpdatePatientPayload
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'pronouns'?: string | null;
    /**
     * 
     * @type {HeightFieldInput}
     * @memberof UpdatePatientPayload
     */
    'height'?: HeightFieldInput | null;
    /**
     * 
     * @type {WeightFieldInput}
     * @memberof UpdatePatientPayload
     */
    'weight'?: WeightFieldInput | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'avatar_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'timezone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'enrollment_reason'?: string | null;
    /**
     * 
     * @type {UpdateInsurancePolicyPayload}
     * @memberof UpdatePatientPayload
     */
    'insurance_policy'?: UpdateInsurancePolicyPayload | null;
    /**
     * 
     * @type {UpdateAddressPayload}
     * @memberof UpdatePatientPayload
     */
    'mailing_address'?: UpdateAddressPayload | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'stripe_customer_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientPayload
     */
    'stripe_payment_method_id'?: string | null;
}


/**
 * 
 * @export
 * @interface UpdatePatientProviderPayload
 */
export interface UpdatePatientProviderPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientProviderPayload
     */
    'provider_id': string;
}
/**
 * 
 * @export
 * @interface UpdateProviderAvailabilitySchedulePayload
 */
export interface UpdateProviderAvailabilitySchedulePayload {
    /**
     * 
     * @type {Array<UpdateProviderAvailabilityScheduleWindowPayload>}
     * @memberof UpdateProviderAvailabilitySchedulePayload
     */
    'windows': Array<UpdateProviderAvailabilityScheduleWindowPayload>;
}
/**
 * 
 * @export
 * @interface UpdateProviderAvailabilityScheduleWindowPayload
 */
export interface UpdateProviderAvailabilityScheduleWindowPayload {
    /**
     * 
     * @type {DayOfWeek}
     * @memberof UpdateProviderAvailabilityScheduleWindowPayload
     */
    'day_of_week': DayOfWeek;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderAvailabilityScheduleWindowPayload
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderAvailabilityScheduleWindowPayload
     */
    'end_time': string;
}


/**
 * 
 * @export
 * @interface UpdateProviderConfigPayload
 */
export interface UpdateProviderConfigPayload {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProviderConfigPayload
     */
    'offers_free_consultations'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateProviderPayload
 */
export interface UpdateProviderPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'preferred_first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'email'?: string | null;
    /**
     * 
     * @type {Sex}
     * @memberof UpdateProviderPayload
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'pronouns'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderPayload
     */
    'timezone'?: string | null;
    /**
     * 
     * @type {UpdateProviderConfigPayload}
     * @memberof UpdateProviderPayload
     */
    'provider_config'?: UpdateProviderConfigPayload | null;
}


/**
 * 
 * @export
 * @interface UpdateRecipePayload
 */
export interface UpdateRecipePayload {
    /**
     * The name of the recipe
     * @type {string}
     * @memberof UpdateRecipePayload
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRecipePayload
     */
    'ingredients': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRecipePayload
     */
    'steps': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateRecurringDonationPayload
 */
export interface UpdateRecurringDonationPayload {
    /**
     * 
     * @type {Amount}
     * @memberof UpdateRecurringDonationPayload
     */
    'amount': Amount;
}
/**
 * 
 * @export
 * @interface UploadPatientLabPayload
 */
export interface UploadPatientLabPayload {
    /**
     * 
     * @type {string}
     * @memberof UploadPatientLabPayload
     */
    'document_s3_key': string;
    /**
     * 
     * @type {string}
     * @memberof UploadPatientLabPayload
     */
    'intended_provider_id': string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}
/**
 * 
 * @export
 * @interface Value
 */
export interface Value {
}
/**
 * 
 * @export
 * @interface VerifyPaymentMethodPayload
 */
export interface VerifyPaymentMethodPayload {
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodPayload
     */
    'stripe_token': string;
    /**
     * 
     * @type {CardSource}
     * @memberof VerifyPaymentMethodPayload
     */
    'card_source': CardSource;
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodPayload
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodPayload
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodPayload
     */
    'stripe_customer_id'?: string | null;
}


/**
 * 
 * @export
 * @interface VerifyPaymentMethodResponse
 */
export interface VerifyPaymentMethodResponse {
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodResponse
     */
    'stripe_customer_id': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodResponse
     */
    'payment_method_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyPaymentMethodResponse
     */
    'error'?: string | null;
}
/**
 * 
 * @export
 * @interface WeightFieldInput
 */
export interface WeightFieldInput {
    /**
     * 
     * @type {WeightUnits}
     * @memberof WeightFieldInput
     */
    'unit': WeightUnits;
    /**
     * 
     * @type {Value}
     * @memberof WeightFieldInput
     */
    'value': Value;
}


/**
 * 
 * @export
 * @interface WeightFieldOutput
 */
export interface WeightFieldOutput {
    /**
     * 
     * @type {WeightUnits}
     * @memberof WeightFieldOutput
     */
    'unit': WeightUnits;
    /**
     * 
     * @type {string}
     * @memberof WeightFieldOutput
     */
    'value': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const WeightUnits = {
    Pounds: 'pounds',
    Kilograms: 'kilograms'
} as const;

export type WeightUnits = typeof WeightUnits[keyof typeof WeightUnits];


/**
 * 
 * @export
 * @interface ZocdocAppointment
 */
export interface ZocdocAppointment {
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointment
     */
    'appointment_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointment
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointment
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointment
     */
    'start_time': string;
    /**
     * 
     * @type {number}
     * @memberof ZocdocAppointment
     */
    'duration': number;
    /**
     * 
     * @type {ZocdocAppointmentType}
     * @memberof ZocdocAppointment
     */
    'visit_reason_id': ZocdocAppointmentType;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointment
     */
    'location_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointment
     */
    'appointment_status_id': string;
}


/**
 * 
 * @export
 * @interface ZocdocAppointmentCancelResponse
 */
export interface ZocdocAppointmentCancelResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ZocdocAppointmentCancelResponse
     */
    'successful': boolean;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointmentCancelResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ZocdocAppointmentIdPayload
 */
export interface ZocdocAppointmentIdPayload {
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointmentIdPayload
     */
    'appointment_id': string;
}
/**
 * 
 * @export
 * @interface ZocdocAppointmentStatus
 */
export interface ZocdocAppointmentStatus {
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointmentStatus
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAppointmentStatus
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ZocdocAppointmentType = {
    InitialConsultation90min: 'initial_consultation_90min'
} as const;

export type ZocdocAppointmentType = typeof ZocdocAppointmentType[keyof typeof ZocdocAppointmentType];


/**
 * 
 * @export
 * @interface ZocdocAvailabilitySlot
 */
export interface ZocdocAvailabilitySlot {
    /**
     * 
     * @type {string}
     * @memberof ZocdocAvailabilitySlot
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAvailabilitySlot
     */
    'end_time': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAvailabilitySlot
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocAvailabilitySlot
     */
    'location_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ZocdocAvailabilitySlot
     */
    'available_slot_types'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ZocdocCreateAppointmentPayload
 */
export interface ZocdocCreateAppointmentPayload {
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'patient_address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'patient_address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'patient_city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'patient_state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'patient_zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'insurance_carrier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'insurance_plan'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentPayload
     */
    'insurance_member_id'?: string | null;
}
/**
 * 
 * @export
 * @interface ZocdocCreateAppointmentResponse
 */
export interface ZocdocCreateAppointmentResponse {
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreateAppointmentResponse
     */
    'appointment_id': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ZocdocCreateAppointmentResponse
     */
    'successful': boolean;
}
/**
 * 
 * @export
 * @interface ZocdocCreatePatientPayload
 */
export interface ZocdocCreatePatientPayload {
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'email_address': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'provider_id'?: string | null;
    /**
     * 
     * @type {ZocdocGender}
     * @memberof ZocdocCreatePatientPayload
     */
    'gender': ZocdocGender;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'insurance_carrier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'insurance_plan'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientPayload
     */
    'insurance_member_id'?: string | null;
}


/**
 * 
 * @export
 * @interface ZocdocCreatePatientResponse
 */
export interface ZocdocCreatePatientResponse {
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientResponse
     */
    'patient_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocCreatePatientResponse
     */
    'provider_id': string | null;
}
/**
 * This is a reductive and incorrect representation of gender but it\'s what the spec requires.
 * @export
 * @enum {string}
 */

export const ZocdocGender = {
    M: 'M',
    F: 'F'
} as const;

export type ZocdocGender = typeof ZocdocGender[keyof typeof ZocdocGender];


/**
 * 
 * @export
 * @interface ZocdocPatient
 */
export interface ZocdocPatient {
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'provider_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'email_address': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatient
     */
    'phone_number': string | null;
    /**
     * 
     * @type {ZocdocGender}
     * @memberof ZocdocPatient
     */
    'gender'?: ZocdocGender | null;
}


/**
 * 
 * @export
 * @interface ZocdocPatientSearchPayload
 */
export interface ZocdocPatientSearchPayload {
    /**
     * 
     * @type {string}
     * @memberof ZocdocPatientSearchPayload
     */
    'email_address': string;
}
/**
 * 
 * @export
 * @interface ZocdocProvider
 */
export interface ZocdocProvider {
    /**
     * 
     * @type {string}
     * @memberof ZocdocProvider
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ZocdocProvider
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ZocdocVisitReason
 */
export interface ZocdocVisitReason {
    /**
     * 
     * @type {ZocdocAppointmentType}
     * @memberof ZocdocVisitReason
     */
    'id': ZocdocAppointmentType;
    /**
     * 
     * @type {string}
     * @memberof ZocdocVisitReason
     */
    'name': string;
}



/**
 * AIFeedbackApi - axios parameter creator
 * @export
 */
export const AIFeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Chat Completion Feedback
         * @param {string} chatCompletionId 
         * @param {UpdateChatCompletionFeedbackPayload} updateChatCompletionFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut: async (chatCompletionId: string, updateChatCompletionFeedbackPayload: UpdateChatCompletionFeedbackPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatCompletionId' is not null or undefined
            assertParamExists('updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut', 'chatCompletionId', chatCompletionId)
            // verify required parameter 'updateChatCompletionFeedbackPayload' is not null or undefined
            assertParamExists('updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut', 'updateChatCompletionFeedbackPayload', updateChatCompletionFeedbackPayload)
            const localVarPath = `/ai-feedback/chat-completion/{chat_completion_id}`
                .replace(`{${"chat_completion_id"}}`, encodeURIComponent(String(chatCompletionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChatCompletionFeedbackPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AIFeedbackApi - functional programming interface
 * @export
 */
export const AIFeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AIFeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update Chat Completion Feedback
         * @param {string} chatCompletionId 
         * @param {UpdateChatCompletionFeedbackPayload} updateChatCompletionFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(chatCompletionId: string, updateChatCompletionFeedbackPayload: UpdateChatCompletionFeedbackPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(chatCompletionId, updateChatCompletionFeedbackPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AIFeedbackApi.updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AIFeedbackApi - factory interface
 * @export
 */
export const AIFeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AIFeedbackApiFp(configuration)
    return {
        /**
         * 
         * @summary Update Chat Completion Feedback
         * @param {string} chatCompletionId 
         * @param {UpdateChatCompletionFeedbackPayload} updateChatCompletionFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(chatCompletionId: string, updateChatCompletionFeedbackPayload: UpdateChatCompletionFeedbackPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(chatCompletionId, updateChatCompletionFeedbackPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AIFeedbackApi - object-oriented interface
 * @export
 * @class AIFeedbackApi
 * @extends {BaseAPI}
 */
export class AIFeedbackApi extends BaseAPI {
    /**
     * 
     * @summary Update Chat Completion Feedback
     * @param {string} chatCompletionId 
     * @param {UpdateChatCompletionFeedbackPayload} updateChatCompletionFeedbackPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIFeedbackApi
     */
    public updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(chatCompletionId: string, updateChatCompletionFeedbackPayload: UpdateChatCompletionFeedbackPayload, options?: AxiosRequestConfig) {
        return AIFeedbackApiFp(this.configuration).updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(chatCompletionId, updateChatCompletionFeedbackPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppointmentApi - axios parameter creator
 * @export
 */
export const AppointmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Book Appointment
         * @param {BookAppointmentPayload} bookAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookAppointmentAppointmentsPost: async (bookAppointmentPayload: BookAppointmentPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookAppointmentPayload' is not null or undefined
            assertParamExists('bookAppointmentAppointmentsPost', 'bookAppointmentPayload', bookAppointmentPayload)
            const localVarPath = `/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookAppointmentPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointmentAppointmentAppointmentIdCancelPost: async (appointmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('cancelAppointmentAppointmentAppointmentIdCancelPost', 'appointmentId', appointmentId)
            const localVarPath = `/appointment/{appointment_id}/cancel`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Calendar Block
         * @param {CreateCalendarBlockPayload} createCalendarBlockPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendarBlockCalendarEventsBlocksPost: async (createCalendarBlockPayload: CreateCalendarBlockPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCalendarBlockPayload' is not null or undefined
            assertParamExists('createCalendarBlockCalendarEventsBlocksPost', 'createCalendarBlockPayload', createCalendarBlockPayload)
            const localVarPath = `/calendar-events/blocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCalendarBlockPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Calendar Event
         * @param {string} calendarEventId 
         * @param {boolean} withSubsequentEventsInRecurrenceSeries If true, all events after this event in the recurrence series will also be deleted. When false, only this event is deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEventCalendarEventsCalendarEventIdDelete: async (calendarEventId: string, withSubsequentEventsInRecurrenceSeries: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('deleteCalendarEventCalendarEventsCalendarEventIdDelete', 'calendarEventId', calendarEventId)
            // verify required parameter 'withSubsequentEventsInRecurrenceSeries' is not null or undefined
            assertParamExists('deleteCalendarEventCalendarEventsCalendarEventIdDelete', 'withSubsequentEventsInRecurrenceSeries', withSubsequentEventsInRecurrenceSeries)
            const localVarPath = `/calendar-events/{calendar_event_id}`
                .replace(`{${"calendar_event_id"}}`, encodeURIComponent(String(calendarEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (withSubsequentEventsInRecurrenceSeries !== undefined) {
                localVarQueryParameter['with_subsequent_events_in_recurrence_series'] = withSubsequentEventsInRecurrenceSeries;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointment Add To Calendar Details
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet: async (appointmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet', 'appointmentId', appointmentId)
            const localVarPath = `/appointments/{appointment_id}/calendar-details`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentAppointmentsAppointmentIdGet: async (appointmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('getAppointmentAppointmentsAppointmentIdGet', 'appointmentId', appointmentId)
            const localVarPath = `/appointments/{appointment_id}`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointments
         * @param {string | null} [providerId] 
         * @param {string | null} [patientId] 
         * @param {string | null} [before] 
         * @param {string | null} [after] 
         * @param {string | null} [startBefore] 
         * @param {string | null} [startAfter] 
         * @param {string | null} [endBefore] 
         * @param {string | null} [endAfter] 
         * @param {AllowedSortParam | null} [sort] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsAppointmentsGet: async (providerId?: string | null, patientId?: string | null, before?: string | null, after?: string | null, startBefore?: string | null, startAfter?: string | null, endBefore?: string | null, endAfter?: string | null, sort?: AllowedSortParam | null, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (patientId !== undefined) {
                localVarQueryParameter['patient_id'] = patientId;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = (before as any instanceof Date) ?
                    (before as any).toISOString() :
                    before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = (after as any instanceof Date) ?
                    (after as any).toISOString() :
                    after;
            }

            if (startBefore !== undefined) {
                localVarQueryParameter['start_before'] = (startBefore as any instanceof Date) ?
                    (startBefore as any).toISOString() :
                    startBefore;
            }

            if (startAfter !== undefined) {
                localVarQueryParameter['start_after'] = (startAfter as any instanceof Date) ?
                    (startAfter as any).toISOString() :
                    startAfter;
            }

            if (endBefore !== undefined) {
                localVarQueryParameter['end_before'] = (endBefore as any instanceof Date) ?
                    (endBefore as any).toISOString() :
                    endBefore;
            }

            if (endAfter !== undefined) {
                localVarQueryParameter['end_after'] = (endAfter as any instanceof Date) ?
                    (endAfter as any).toISOString() :
                    endAfter;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Calendar Event
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventCalendarEventsCalendarEventIdGet: async (calendarEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('getCalendarEventCalendarEventsCalendarEventIdGet', 'calendarEventId', calendarEventId)
            const localVarPath = `/calendar-events/{calendar_event_id}`
                .replace(`{${"calendar_event_id"}}`, encodeURIComponent(String(calendarEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Calendar Events
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventsCalendarEventsGet: async (startTime: string, endTime: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getCalendarEventsCalendarEventsGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getCalendarEventsCalendarEventsGet', 'endTime', endTime)
            const localVarPath = `/calendar-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Availability Schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAvailabilityScheduleAvailabilityScheduleGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/availability-schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
         * @summary Get Provider Available Slots
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {number} appointmentDurationInMinutes 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAvailableSlotsAvailabilitySlotsGet: async (startTime: string, endTime: string, appointmentDurationInMinutes: number, providerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getProviderAvailableSlotsAvailabilitySlotsGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getProviderAvailableSlotsAvailabilitySlotsGet', 'endTime', endTime)
            // verify required parameter 'appointmentDurationInMinutes' is not null or undefined
            assertParamExists('getProviderAvailableSlotsAvailabilitySlotsGet', 'appointmentDurationInMinutes', appointmentDurationInMinutes)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderAvailableSlotsAvailabilitySlotsGet', 'providerId', providerId)
            const localVarPath = `/availability-slots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (appointmentDurationInMinutes !== undefined) {
                localVarQueryParameter['appointment_duration_in_minutes'] = appointmentDurationInMinutes;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Book Appointment
         * @param {InternalBookAppointmentPayload} internalBookAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalBookAppointmentInternalAppointmentsPost: async (internalBookAppointmentPayload: InternalBookAppointmentPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalBookAppointmentPayload' is not null or undefined
            assertParamExists('internalBookAppointmentInternalAppointmentsPost', 'internalBookAppointmentPayload', internalBookAppointmentPayload)
            const localVarPath = `/internal/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalBookAppointmentPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Get Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetAppointmentInternalAppointmentsAppointmentIdGet: async (appointmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('internalGetAppointmentInternalAppointmentsAppointmentIdGet', 'appointmentId', appointmentId)
            const localVarPath = `/internal/appointments/{appointment_id}`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
         * @summary Internal Get Provider Available Slots
         * @param {string} providerId 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {number} appointmentDurationInMinutes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetProviderAvailableSlotsInternalAvailabilitySlotsGet: async (providerId: string, startTime: string, endTime: string, appointmentDurationInMinutes: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('internalGetProviderAvailableSlotsInternalAvailabilitySlotsGet', 'providerId', providerId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('internalGetProviderAvailableSlotsInternalAvailabilitySlotsGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('internalGetProviderAvailableSlotsInternalAvailabilitySlotsGet', 'endTime', endTime)
            // verify required parameter 'appointmentDurationInMinutes' is not null or undefined
            assertParamExists('internalGetProviderAvailableSlotsInternalAvailabilitySlotsGet', 'appointmentDurationInMinutes', appointmentDurationInMinutes)
            const localVarPath = `/internal/availability-slots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (appointmentDurationInMinutes !== undefined) {
                localVarQueryParameter['appointment_duration_in_minutes'] = appointmentDurationInMinutes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Provider Availability Schedule
         * @param {UpdateProviderAvailabilitySchedulePayload} updateProviderAvailabilitySchedulePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProviderAvailabilityScheduleAvailabilitySchedulePost: async (updateProviderAvailabilitySchedulePayload: UpdateProviderAvailabilitySchedulePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProviderAvailabilitySchedulePayload' is not null or undefined
            assertParamExists('setProviderAvailabilityScheduleAvailabilitySchedulePost', 'updateProviderAvailabilitySchedulePayload', updateProviderAvailabilitySchedulePayload)
            const localVarPath = `/availability-schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderAvailabilitySchedulePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Appointment
         * @param {string} appointmentId 
         * @param {UpdateAppointmentPayload} updateAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointmentAppointmentsAppointmentIdPatch: async (appointmentId: string, updateAppointmentPayload: UpdateAppointmentPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('updateAppointmentAppointmentsAppointmentIdPatch', 'appointmentId', appointmentId)
            // verify required parameter 'updateAppointmentPayload' is not null or undefined
            assertParamExists('updateAppointmentAppointmentsAppointmentIdPatch', 'updateAppointmentPayload', updateAppointmentPayload)
            const localVarPath = `/appointments/{appointment_id}`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppointmentPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Calendar Event
         * @param {string} calendarEventId 
         * @param {UpdateCalendarEventPayload} updateCalendarEventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEventCalendarEventsCalendarEventIdPut: async (calendarEventId: string, updateCalendarEventPayload: UpdateCalendarEventPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('updateCalendarEventCalendarEventsCalendarEventIdPut', 'calendarEventId', calendarEventId)
            // verify required parameter 'updateCalendarEventPayload' is not null or undefined
            assertParamExists('updateCalendarEventCalendarEventsCalendarEventIdPut', 'updateCalendarEventPayload', updateCalendarEventPayload)
            const localVarPath = `/calendar-events/{calendar_event_id}`
                .replace(`{${"calendar_event_id"}}`, encodeURIComponent(String(calendarEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCalendarEventPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppointmentApi - functional programming interface
 * @export
 */
export const AppointmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppointmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Book Appointment
         * @param {BookAppointmentPayload} bookAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookAppointmentAppointmentsPost(bookAppointmentPayload: BookAppointmentPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookAppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookAppointmentAppointmentsPost(bookAppointmentPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.bookAppointmentAppointmentsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Cancel Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAppointmentAppointmentAppointmentIdCancelPost(appointmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentCanceledResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAppointmentAppointmentAppointmentIdCancelPost(appointmentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.cancelAppointmentAppointmentAppointmentIdCancelPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create Calendar Block
         * @param {CreateCalendarBlockPayload} createCalendarBlockPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCalendarBlockCalendarEventsBlocksPost(createCalendarBlockPayload: CreateCalendarBlockPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCalendarBlockCalendarEventsBlocksPost(createCalendarBlockPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.createCalendarBlockCalendarEventsBlocksPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Calendar Event
         * @param {string} calendarEventId 
         * @param {boolean} withSubsequentEventsInRecurrenceSeries If true, all events after this event in the recurrence series will also be deleted. When false, only this event is deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalendarEventCalendarEventsCalendarEventIdDelete(calendarEventId: string, withSubsequentEventsInRecurrenceSeries: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalendarEventCalendarEventsCalendarEventIdDelete(calendarEventId, withSubsequentEventsInRecurrenceSeries, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.deleteCalendarEventCalendarEventsCalendarEventIdDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Appointment Add To Calendar Details
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(appointmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentAddToCalendarDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(appointmentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentAppointmentsAppointmentIdGet(appointmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentAppointmentsAppointmentIdGet(appointmentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.getAppointmentAppointmentsAppointmentIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Appointments
         * @param {string | null} [providerId] 
         * @param {string | null} [patientId] 
         * @param {string | null} [before] 
         * @param {string | null} [after] 
         * @param {string | null} [startBefore] 
         * @param {string | null} [startAfter] 
         * @param {string | null} [endBefore] 
         * @param {string | null} [endAfter] 
         * @param {AllowedSortParam | null} [sort] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentsAppointmentsGet(providerId?: string | null, patientId?: string | null, before?: string | null, after?: string | null, startBefore?: string | null, startAfter?: string | null, endBefore?: string | null, endAfter?: string | null, sort?: AllowedSortParam | null, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppointmentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentsAppointmentsGet(providerId, patientId, before, after, startBefore, startAfter, endBefore, endAfter, sort, limit, offset, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.getAppointmentsAppointmentsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Calendar Event
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarEventCalendarEventsCalendarEventIdGet(calendarEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarEventCalendarEventsCalendarEventIdGet(calendarEventId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.getCalendarEventCalendarEventsCalendarEventIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Calendar Events
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarEventsCalendarEventsGet(startTime: string, endTime: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarEventsCalendarEventsGet(startTime, endTime, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.getCalendarEventsCalendarEventsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Availability Schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderAvailabilityScheduleAvailabilityScheduleGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAvailabilityScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderAvailabilityScheduleAvailabilityScheduleGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.getProviderAvailabilityScheduleAvailabilityScheduleGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
         * @summary Get Provider Available Slots
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {number} appointmentDurationInMinutes 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderAvailableSlotsAvailabilitySlotsGet(startTime: string, endTime: string, appointmentDurationInMinutes: number, providerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailabilitySlotsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderAvailableSlotsAvailabilitySlotsGet(startTime, endTime, appointmentDurationInMinutes, providerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.getProviderAvailableSlotsAvailabilitySlotsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Internal Book Appointment
         * @param {InternalBookAppointmentPayload} internalBookAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalBookAppointmentInternalAppointmentsPost(internalBookAppointmentPayload: InternalBookAppointmentPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookAppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalBookAppointmentInternalAppointmentsPost(internalBookAppointmentPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.internalBookAppointmentInternalAppointmentsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Internal Get Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetAppointmentInternalAppointmentsAppointmentIdGet(appointmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalAppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetAppointmentInternalAppointmentsAppointmentIdGet(appointmentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.internalGetAppointmentInternalAppointmentsAppointmentIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
         * @summary Internal Get Provider Available Slots
         * @param {string} providerId 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {number} appointmentDurationInMinutes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetProviderAvailableSlotsInternalAvailabilitySlotsGet(providerId: string, startTime: string, endTime: string, appointmentDurationInMinutes: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailabilitySlotsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetProviderAvailableSlotsInternalAvailabilitySlotsGet(providerId, startTime, endTime, appointmentDurationInMinutes, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.internalGetProviderAvailableSlotsInternalAvailabilitySlotsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Set Provider Availability Schedule
         * @param {UpdateProviderAvailabilitySchedulePayload} updateProviderAvailabilitySchedulePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProviderAvailabilityScheduleAvailabilitySchedulePost(updateProviderAvailabilitySchedulePayload: UpdateProviderAvailabilitySchedulePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderAvailabilityScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProviderAvailabilityScheduleAvailabilitySchedulePost(updateProviderAvailabilitySchedulePayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.setProviderAvailabilityScheduleAvailabilitySchedulePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update Appointment
         * @param {string} appointmentId 
         * @param {UpdateAppointmentPayload} updateAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppointmentAppointmentsAppointmentIdPatch(appointmentId: string, updateAppointmentPayload: UpdateAppointmentPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppointmentAppointmentsAppointmentIdPatch(appointmentId, updateAppointmentPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.updateAppointmentAppointmentsAppointmentIdPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update Calendar Event
         * @param {string} calendarEventId 
         * @param {UpdateCalendarEventPayload} updateCalendarEventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalendarEventCalendarEventsCalendarEventIdPut(calendarEventId: string, updateCalendarEventPayload: UpdateCalendarEventPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalendarEventCalendarEventsCalendarEventIdPut(calendarEventId, updateCalendarEventPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.updateCalendarEventCalendarEventsCalendarEventIdPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AppointmentApi - factory interface
 * @export
 */
export const AppointmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppointmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Book Appointment
         * @param {BookAppointmentPayload} bookAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookAppointmentAppointmentsPost(bookAppointmentPayload: BookAppointmentPayload, options?: any): AxiosPromise<BookAppointmentResponse> {
            return localVarFp.bookAppointmentAppointmentsPost(bookAppointmentPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointmentAppointmentAppointmentIdCancelPost(appointmentId: string, options?: any): AxiosPromise<AppointmentCanceledResponse> {
            return localVarFp.cancelAppointmentAppointmentAppointmentIdCancelPost(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Calendar Block
         * @param {CreateCalendarBlockPayload} createCalendarBlockPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendarBlockCalendarEventsBlocksPost(createCalendarBlockPayload: CreateCalendarBlockPayload, options?: any): AxiosPromise<CalendarEventResponse> {
            return localVarFp.createCalendarBlockCalendarEventsBlocksPost(createCalendarBlockPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Calendar Event
         * @param {string} calendarEventId 
         * @param {boolean} withSubsequentEventsInRecurrenceSeries If true, all events after this event in the recurrence series will also be deleted. When false, only this event is deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEventCalendarEventsCalendarEventIdDelete(calendarEventId: string, withSubsequentEventsInRecurrenceSeries: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCalendarEventCalendarEventsCalendarEventIdDelete(calendarEventId, withSubsequentEventsInRecurrenceSeries, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointment Add To Calendar Details
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(appointmentId: string, options?: any): AxiosPromise<AppointmentAddToCalendarDetailsResponse> {
            return localVarFp.getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentAppointmentsAppointmentIdGet(appointmentId: string, options?: any): AxiosPromise<AppointmentResponse> {
            return localVarFp.getAppointmentAppointmentsAppointmentIdGet(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointments
         * @param {string | null} [providerId] 
         * @param {string | null} [patientId] 
         * @param {string | null} [before] 
         * @param {string | null} [after] 
         * @param {string | null} [startBefore] 
         * @param {string | null} [startAfter] 
         * @param {string | null} [endBefore] 
         * @param {string | null} [endAfter] 
         * @param {AllowedSortParam | null} [sort] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsAppointmentsGet(providerId?: string | null, patientId?: string | null, before?: string | null, after?: string | null, startBefore?: string | null, startAfter?: string | null, endBefore?: string | null, endAfter?: string | null, sort?: AllowedSortParam | null, limit?: number, offset?: number, options?: any): AxiosPromise<Array<AppointmentResponse>> {
            return localVarFp.getAppointmentsAppointmentsGet(providerId, patientId, before, after, startBefore, startAfter, endBefore, endAfter, sort, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Calendar Event
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventCalendarEventsCalendarEventIdGet(calendarEventId: string, options?: any): AxiosPromise<CalendarEventResponse> {
            return localVarFp.getCalendarEventCalendarEventsCalendarEventIdGet(calendarEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Calendar Events
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventsCalendarEventsGet(startTime: string, endTime: string, options?: any): AxiosPromise<CalendarEventsResponse> {
            return localVarFp.getCalendarEventsCalendarEventsGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Availability Schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAvailabilityScheduleAvailabilityScheduleGet(options?: any): AxiosPromise<ProviderAvailabilityScheduleResponse> {
            return localVarFp.getProviderAvailabilityScheduleAvailabilityScheduleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
         * @summary Get Provider Available Slots
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {number} appointmentDurationInMinutes 
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderAvailableSlotsAvailabilitySlotsGet(startTime: string, endTime: string, appointmentDurationInMinutes: number, providerId: string, options?: any): AxiosPromise<AvailabilitySlotsResponse> {
            return localVarFp.getProviderAvailableSlotsAvailabilitySlotsGet(startTime, endTime, appointmentDurationInMinutes, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Book Appointment
         * @param {InternalBookAppointmentPayload} internalBookAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalBookAppointmentInternalAppointmentsPost(internalBookAppointmentPayload: InternalBookAppointmentPayload, options?: any): AxiosPromise<BookAppointmentResponse> {
            return localVarFp.internalBookAppointmentInternalAppointmentsPost(internalBookAppointmentPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Get Appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetAppointmentInternalAppointmentsAppointmentIdGet(appointmentId: string, options?: any): AxiosPromise<InternalAppointmentResponse> {
            return localVarFp.internalGetAppointmentInternalAppointmentsAppointmentIdGet(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
         * @summary Internal Get Provider Available Slots
         * @param {string} providerId 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {number} appointmentDurationInMinutes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetProviderAvailableSlotsInternalAvailabilitySlotsGet(providerId: string, startTime: string, endTime: string, appointmentDurationInMinutes: number, options?: any): AxiosPromise<AvailabilitySlotsResponse> {
            return localVarFp.internalGetProviderAvailableSlotsInternalAvailabilitySlotsGet(providerId, startTime, endTime, appointmentDurationInMinutes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Provider Availability Schedule
         * @param {UpdateProviderAvailabilitySchedulePayload} updateProviderAvailabilitySchedulePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProviderAvailabilityScheduleAvailabilitySchedulePost(updateProviderAvailabilitySchedulePayload: UpdateProviderAvailabilitySchedulePayload, options?: any): AxiosPromise<ProviderAvailabilityScheduleResponse> {
            return localVarFp.setProviderAvailabilityScheduleAvailabilitySchedulePost(updateProviderAvailabilitySchedulePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Appointment
         * @param {string} appointmentId 
         * @param {UpdateAppointmentPayload} updateAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointmentAppointmentsAppointmentIdPatch(appointmentId: string, updateAppointmentPayload: UpdateAppointmentPayload, options?: any): AxiosPromise<AppointmentResponse> {
            return localVarFp.updateAppointmentAppointmentsAppointmentIdPatch(appointmentId, updateAppointmentPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Calendar Event
         * @param {string} calendarEventId 
         * @param {UpdateCalendarEventPayload} updateCalendarEventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEventCalendarEventsCalendarEventIdPut(calendarEventId: string, updateCalendarEventPayload: UpdateCalendarEventPayload, options?: any): AxiosPromise<CalendarEventResponse> {
            return localVarFp.updateCalendarEventCalendarEventsCalendarEventIdPut(calendarEventId, updateCalendarEventPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppointmentApi - object-oriented interface
 * @export
 * @class AppointmentApi
 * @extends {BaseAPI}
 */
export class AppointmentApi extends BaseAPI {
    /**
     * 
     * @summary Book Appointment
     * @param {BookAppointmentPayload} bookAppointmentPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public bookAppointmentAppointmentsPost(bookAppointmentPayload: BookAppointmentPayload, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).bookAppointmentAppointmentsPost(bookAppointmentPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel Appointment
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public cancelAppointmentAppointmentAppointmentIdCancelPost(appointmentId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).cancelAppointmentAppointmentAppointmentIdCancelPost(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Calendar Block
     * @param {CreateCalendarBlockPayload} createCalendarBlockPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public createCalendarBlockCalendarEventsBlocksPost(createCalendarBlockPayload: CreateCalendarBlockPayload, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).createCalendarBlockCalendarEventsBlocksPost(createCalendarBlockPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Calendar Event
     * @param {string} calendarEventId 
     * @param {boolean} withSubsequentEventsInRecurrenceSeries If true, all events after this event in the recurrence series will also be deleted. When false, only this event is deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public deleteCalendarEventCalendarEventsCalendarEventIdDelete(calendarEventId: string, withSubsequentEventsInRecurrenceSeries: boolean, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).deleteCalendarEventCalendarEventsCalendarEventIdDelete(calendarEventId, withSubsequentEventsInRecurrenceSeries, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointment Add To Calendar Details
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(appointmentId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointment
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentAppointmentsAppointmentIdGet(appointmentId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentAppointmentsAppointmentIdGet(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointments
     * @param {string | null} [providerId] 
     * @param {string | null} [patientId] 
     * @param {string | null} [before] 
     * @param {string | null} [after] 
     * @param {string | null} [startBefore] 
     * @param {string | null} [startAfter] 
     * @param {string | null} [endBefore] 
     * @param {string | null} [endAfter] 
     * @param {AllowedSortParam | null} [sort] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentsAppointmentsGet(providerId?: string | null, patientId?: string | null, before?: string | null, after?: string | null, startBefore?: string | null, startAfter?: string | null, endBefore?: string | null, endAfter?: string | null, sort?: AllowedSortParam | null, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentsAppointmentsGet(providerId, patientId, before, after, startBefore, startAfter, endBefore, endAfter, sort, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Calendar Event
     * @param {string} calendarEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getCalendarEventCalendarEventsCalendarEventIdGet(calendarEventId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getCalendarEventCalendarEventsCalendarEventIdGet(calendarEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Calendar Events
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getCalendarEventsCalendarEventsGet(startTime: string, endTime: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getCalendarEventsCalendarEventsGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Availability Schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getProviderAvailabilityScheduleAvailabilityScheduleGet(options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getProviderAvailabilityScheduleAvailabilityScheduleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
     * @summary Get Provider Available Slots
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {number} appointmentDurationInMinutes 
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getProviderAvailableSlotsAvailabilitySlotsGet(startTime: string, endTime: string, appointmentDurationInMinutes: number, providerId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getProviderAvailableSlotsAvailabilitySlotsGet(startTime, endTime, appointmentDurationInMinutes, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Internal Book Appointment
     * @param {InternalBookAppointmentPayload} internalBookAppointmentPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public internalBookAppointmentInternalAppointmentsPost(internalBookAppointmentPayload: InternalBookAppointmentPayload, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).internalBookAppointmentInternalAppointmentsPost(internalBookAppointmentPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Internal Get Appointment
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public internalGetAppointmentInternalAppointmentsAppointmentIdGet(appointmentId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).internalGetAppointmentInternalAppointmentsAppointmentIdGet(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of available slots for the given provider between the given start and end times. The slots returned are the start times of the available slots in 15 minute increments.
     * @summary Internal Get Provider Available Slots
     * @param {string} providerId 
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {number} appointmentDurationInMinutes 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public internalGetProviderAvailableSlotsInternalAvailabilitySlotsGet(providerId: string, startTime: string, endTime: string, appointmentDurationInMinutes: number, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).internalGetProviderAvailableSlotsInternalAvailabilitySlotsGet(providerId, startTime, endTime, appointmentDurationInMinutes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Provider Availability Schedule
     * @param {UpdateProviderAvailabilitySchedulePayload} updateProviderAvailabilitySchedulePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public setProviderAvailabilityScheduleAvailabilitySchedulePost(updateProviderAvailabilitySchedulePayload: UpdateProviderAvailabilitySchedulePayload, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).setProviderAvailabilityScheduleAvailabilitySchedulePost(updateProviderAvailabilitySchedulePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Appointment
     * @param {string} appointmentId 
     * @param {UpdateAppointmentPayload} updateAppointmentPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public updateAppointmentAppointmentsAppointmentIdPatch(appointmentId: string, updateAppointmentPayload: UpdateAppointmentPayload, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).updateAppointmentAppointmentsAppointmentIdPatch(appointmentId, updateAppointmentPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Calendar Event
     * @param {string} calendarEventId 
     * @param {UpdateCalendarEventPayload} updateCalendarEventPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public updateCalendarEventCalendarEventsCalendarEventIdPut(calendarEventId: string, updateCalendarEventPayload: UpdateCalendarEventPayload, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).updateCalendarEventCalendarEventsCalendarEventIdPut(calendarEventId, updateCalendarEventPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChatApi - axios parameter creator
 * @export
 */
export const ChatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Suggest Chat Response
         * @param {SuggestChatResponsePayload} suggestChatResponsePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestChatResponseChatSuggestionPost: async (suggestChatResponsePayload: SuggestChatResponsePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'suggestChatResponsePayload' is not null or undefined
            assertParamExists('suggestChatResponseChatSuggestionPost', 'suggestChatResponsePayload', suggestChatResponsePayload)
            const localVarPath = `/chat/suggestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suggestChatResponsePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatApi - functional programming interface
 * @export
 */
export const ChatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Suggest Chat Response
         * @param {SuggestChatResponsePayload} suggestChatResponsePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggestChatResponseChatSuggestionPost(suggestChatResponsePayload: SuggestChatResponsePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestChatResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suggestChatResponseChatSuggestionPost(suggestChatResponsePayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChatApi.suggestChatResponseChatSuggestionPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ChatApi - factory interface
 * @export
 */
export const ChatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatApiFp(configuration)
    return {
        /**
         * 
         * @summary Suggest Chat Response
         * @param {SuggestChatResponsePayload} suggestChatResponsePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestChatResponseChatSuggestionPost(suggestChatResponsePayload: SuggestChatResponsePayload, options?: any): AxiosPromise<SuggestChatResponseResponse> {
            return localVarFp.suggestChatResponseChatSuggestionPost(suggestChatResponsePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatApi - object-oriented interface
 * @export
 * @class ChatApi
 * @extends {BaseAPI}
 */
export class ChatApi extends BaseAPI {
    /**
     * 
     * @summary Suggest Chat Response
     * @param {SuggestChatResponsePayload} suggestChatResponsePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public suggestChatResponseChatSuggestionPost(suggestChatResponsePayload: SuggestChatResponsePayload, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).suggestChatResponseChatSuggestionPost(suggestChatResponsePayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClaimsApi - axios parameter creator
 * @export
 */
export const ClaimsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Claim
         * @param {CreateClaimPayload} createClaimPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClaimClaimsPost: async (createClaimPayload: CreateClaimPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClaimPayload' is not null or undefined
            assertParamExists('createClaimClaimsPost', 'createClaimPayload', createClaimPayload)
            const localVarPath = `/claims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClaimPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClaimsApi - functional programming interface
 * @export
 */
export const ClaimsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClaimsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Claim
         * @param {CreateClaimPayload} createClaimPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClaimClaimsPost(createClaimPayload: CreateClaimPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClaimResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClaimClaimsPost(createClaimPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClaimsApi.createClaimClaimsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ClaimsApi - factory interface
 * @export
 */
export const ClaimsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClaimsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Claim
         * @param {CreateClaimPayload} createClaimPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClaimClaimsPost(createClaimPayload: CreateClaimPayload, options?: any): AxiosPromise<CreateClaimResponse> {
            return localVarFp.createClaimClaimsPost(createClaimPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClaimsApi - object-oriented interface
 * @export
 * @class ClaimsApi
 * @extends {BaseAPI}
 */
export class ClaimsApi extends BaseAPI {
    /**
     * 
     * @summary Create Claim
     * @param {CreateClaimPayload} createClaimPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsApi
     */
    public createClaimClaimsPost(createClaimPayload: CreateClaimPayload, options?: AxiosRequestConfig) {
        return ClaimsApiFp(this.configuration).createClaimClaimsPost(createClaimPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of all the event and command types that are currently being handled by the bus.
         * @summary Admin Get Bus Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBusTypesAdminBusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/bus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to resubmit Events and/or Commands to EventBridge for payloads that didn\'t make it to EventBridge due to PutEvents errors in Datadog.
         * @summary Admin Resubmit Event Or Command To Bus
         * @param {BusResubmissionPayload} busResubmissionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResubmitEventOrCommandToBusAdminBusPost: async (busResubmissionPayload: BusResubmissionPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'busResubmissionPayload' is not null or undefined
            assertParamExists('adminResubmitEventOrCommandToBusAdminBusPost', 'busResubmissionPayload', busResubmissionPayload)
            const localVarPath = `/admin/bus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(busResubmissionPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Background Jobs Handler
         * @param {BackgroundJobPayload} backgroundJobPayload 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundJobsHandlerBackgroundJobsPost: async (backgroundJobPayload: BackgroundJobPayload, xApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'backgroundJobPayload' is not null or undefined
            assertParamExists('backgroundJobsHandlerBackgroundJobsPost', 'backgroundJobPayload', backgroundJobPayload)
            const localVarPath = `/background-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backgroundJobPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Commands
         * @param {object} body 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commandsCommandsPost: async (body: object, xApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('commandsCommandsPost', 'body', body)
            const localVarPath = `/commands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Events
         * @param {object} body 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsEventsPost: async (body: object, xApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('eventsEventsPost', 'body', body)
            const localVarPath = `/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckHealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Npi Database
         * @param {string} query 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNpiDatabaseNpiGet: async (query: string, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchNpiDatabaseNpiGet', 'query', query)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('searchNpiDatabaseNpiGet', 'limit', limit)
            const localVarPath = `/npi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of all the event and command types that are currently being handled by the bus.
         * @summary Admin Get Bus Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetBusTypesAdminBusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandsAndEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetBusTypesAdminBusGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.adminGetBusTypesAdminBusGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Used to resubmit Events and/or Commands to EventBridge for payloads that didn\'t make it to EventBridge due to PutEvents errors in Datadog.
         * @summary Admin Resubmit Event Or Command To Bus
         * @param {BusResubmissionPayload} busResubmissionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminResubmitEventOrCommandToBusAdminBusPost(busResubmissionPayload: BusResubmissionPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusResubmissionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminResubmitEventOrCommandToBusAdminBusPost(busResubmissionPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.adminResubmitEventOrCommandToBusAdminBusPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Background Jobs Handler
         * @param {BackgroundJobPayload} backgroundJobPayload 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backgroundJobsHandlerBackgroundJobsPost(backgroundJobPayload: BackgroundJobPayload, xApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backgroundJobsHandlerBackgroundJobsPost(backgroundJobPayload, xApiKey, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.backgroundJobsHandlerBackgroundJobsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Commands
         * @param {object} body 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commandsCommandsPost(body: object, xApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commandsCommandsPost(body, xApiKey, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.commandsCommandsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Events
         * @param {object} body 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventsEventsPost(body: object, xApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventsEventsPost(body, xApiKey, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.eventsEventsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthcheckHealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthcheckHealthGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.healthcheckHealthGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Search Npi Database
         * @param {string} query 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchNpiDatabaseNpiGet(query: string, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NPIProviderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchNpiDatabaseNpiGet(query, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.searchNpiDatabaseNpiGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Returns a list of all the event and command types that are currently being handled by the bus.
         * @summary Admin Get Bus Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBusTypesAdminBusGet(options?: any): AxiosPromise<CommandsAndEventsResponse> {
            return localVarFp.adminGetBusTypesAdminBusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Used to resubmit Events and/or Commands to EventBridge for payloads that didn\'t make it to EventBridge due to PutEvents errors in Datadog.
         * @summary Admin Resubmit Event Or Command To Bus
         * @param {BusResubmissionPayload} busResubmissionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResubmitEventOrCommandToBusAdminBusPost(busResubmissionPayload: BusResubmissionPayload, options?: any): AxiosPromise<BusResubmissionResponse> {
            return localVarFp.adminResubmitEventOrCommandToBusAdminBusPost(busResubmissionPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Background Jobs Handler
         * @param {BackgroundJobPayload} backgroundJobPayload 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundJobsHandlerBackgroundJobsPost(backgroundJobPayload: BackgroundJobPayload, xApiKey?: string, options?: any): AxiosPromise<object> {
            return localVarFp.backgroundJobsHandlerBackgroundJobsPost(backgroundJobPayload, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Commands
         * @param {object} body 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commandsCommandsPost(body: object, xApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.commandsCommandsPost(body, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Events
         * @param {object} body 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsEventsPost(body: object, xApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.eventsEventsPost(body, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckHealthGet(options?: any): AxiosPromise<void> {
            return localVarFp.healthcheckHealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Npi Database
         * @param {string} query 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNpiDatabaseNpiGet(query: string, limit: number, options?: any): AxiosPromise<Array<NPIProviderResponse>> {
            return localVarFp.searchNpiDatabaseNpiGet(query, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Returns a list of all the event and command types that are currently being handled by the bus.
     * @summary Admin Get Bus Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminGetBusTypesAdminBusGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminGetBusTypesAdminBusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to resubmit Events and/or Commands to EventBridge for payloads that didn\'t make it to EventBridge due to PutEvents errors in Datadog.
     * @summary Admin Resubmit Event Or Command To Bus
     * @param {BusResubmissionPayload} busResubmissionPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminResubmitEventOrCommandToBusAdminBusPost(busResubmissionPayload: BusResubmissionPayload, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminResubmitEventOrCommandToBusAdminBusPost(busResubmissionPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Background Jobs Handler
     * @param {BackgroundJobPayload} backgroundJobPayload 
     * @param {string} [xApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public backgroundJobsHandlerBackgroundJobsPost(backgroundJobPayload: BackgroundJobPayload, xApiKey?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).backgroundJobsHandlerBackgroundJobsPost(backgroundJobPayload, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Commands
     * @param {object} body 
     * @param {string} [xApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public commandsCommandsPost(body: object, xApiKey?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).commandsCommandsPost(body, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Events
     * @param {object} body 
     * @param {string} [xApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public eventsEventsPost(body: object, xApiKey?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).eventsEventsPost(body, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Healthcheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthcheckHealthGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthcheckHealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Npi Database
     * @param {string} query 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchNpiDatabaseNpiGet(query: string, limit: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).searchNpiDatabaseNpiGet(query, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DonationsApi - axios parameter creator
 * @export
 */
export const DonationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Donation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDonationDonationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/donation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Donation
         * @param {UpdateRecurringDonationPayload} updateRecurringDonationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDonationDonationPut: async (updateRecurringDonationPayload: UpdateRecurringDonationPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateRecurringDonationPayload' is not null or undefined
            assertParamExists('updateDonationDonationPut', 'updateRecurringDonationPayload', updateRecurringDonationPayload)
            const localVarPath = `/donation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRecurringDonationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DonationsApi - functional programming interface
 * @export
 */
export const DonationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DonationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Donation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDonationDonationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringDonationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDonationDonationGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DonationsApi.getDonationDonationGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update Donation
         * @param {UpdateRecurringDonationPayload} updateRecurringDonationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDonationDonationPut(updateRecurringDonationPayload: UpdateRecurringDonationPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurringDonationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDonationDonationPut(updateRecurringDonationPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DonationsApi.updateDonationDonationPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DonationsApi - factory interface
 * @export
 */
export const DonationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DonationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Donation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDonationDonationGet(options?: any): AxiosPromise<RecurringDonationResponse> {
            return localVarFp.getDonationDonationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Donation
         * @param {UpdateRecurringDonationPayload} updateRecurringDonationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDonationDonationPut(updateRecurringDonationPayload: UpdateRecurringDonationPayload, options?: any): AxiosPromise<RecurringDonationResponse> {
            return localVarFp.updateDonationDonationPut(updateRecurringDonationPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DonationsApi - object-oriented interface
 * @export
 * @class DonationsApi
 * @extends {BaseAPI}
 */
export class DonationsApi extends BaseAPI {
    /**
     * 
     * @summary Get Donation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationsApi
     */
    public getDonationDonationGet(options?: AxiosRequestConfig) {
        return DonationsApiFp(this.configuration).getDonationDonationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Donation
     * @param {UpdateRecurringDonationPayload} updateRecurringDonationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationsApi
     */
    public updateDonationDonationPut(updateRecurringDonationPayload: UpdateRecurringDonationPayload, options?: AxiosRequestConfig) {
        return DonationsApiFp(this.configuration).updateDonationDonationPut(updateRecurringDonationPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MealPlanningApi - axios parameter creator
 * @export
 */
export const MealPlanningApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate Meal Plan
         * @param {GenerateMealPlanPayload} generateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMealPlanMealPlanPost: async (generateMealPlanPayload: GenerateMealPlanPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateMealPlanPayload' is not null or undefined
            assertParamExists('generateMealPlanMealPlanPost', 'generateMealPlanPayload', generateMealPlanPayload)
            const localVarPath = `/meal-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateMealPlanPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Meal Plan
         * @param {string} mealPlanId The ID of the meal plan to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealPlanMealPlanMealPlanIdGet: async (mealPlanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealPlanId' is not null or undefined
            assertParamExists('getMealPlanMealPlanMealPlanIdGet', 'mealPlanId', mealPlanId)
            const localVarPath = `/meal-plan/{meal_plan_id}`
                .replace(`{${"meal_plan_id"}}`, encodeURIComponent(String(mealPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Meal Plan
         * @param {string} mealPlanId The ID of the meal plan to send
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMealPlanMealPlanMealPlanIdSendPost: async (mealPlanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealPlanId' is not null or undefined
            assertParamExists('sendMealPlanMealPlanMealPlanIdSendPost', 'mealPlanId', mealPlanId)
            const localVarPath = `/meal-plan/{meal_plan_id}/send`
                .replace(`{${"meal_plan_id"}}`, encodeURIComponent(String(mealPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Meal
         * @param {string} mealPlanId The ID of the meal plan to update
         * @param {string} mealId The ID of the meal to update
         * @param {UpdateMealPayload} updateMealPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMealMealPlanMealPlanIdMealsMealIdPut: async (mealPlanId: string, mealId: string, updateMealPayload: UpdateMealPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealPlanId' is not null or undefined
            assertParamExists('updateMealMealPlanMealPlanIdMealsMealIdPut', 'mealPlanId', mealPlanId)
            // verify required parameter 'mealId' is not null or undefined
            assertParamExists('updateMealMealPlanMealPlanIdMealsMealIdPut', 'mealId', mealId)
            // verify required parameter 'updateMealPayload' is not null or undefined
            assertParamExists('updateMealMealPlanMealPlanIdMealsMealIdPut', 'updateMealPayload', updateMealPayload)
            const localVarPath = `/meal-plan/{meal_plan_id}/meals/{meal_id}`
                .replace(`{${"meal_plan_id"}}`, encodeURIComponent(String(mealPlanId)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(mealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMealPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MealPlanningApi - functional programming interface
 * @export
 */
export const MealPlanningApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MealPlanningApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate Meal Plan
         * @param {GenerateMealPlanPayload} generateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateMealPlanMealPlanPost(generateMealPlanPayload: GenerateMealPlanPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateMealPlanMealPlanPost(generateMealPlanPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MealPlanningApi.generateMealPlanMealPlanPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Meal Plan
         * @param {string} mealPlanId The ID of the meal plan to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealPlanMealPlanMealPlanIdGet(mealPlanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealPlanMealPlanMealPlanIdGet(mealPlanId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MealPlanningApi.getMealPlanMealPlanMealPlanIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send Meal Plan
         * @param {string} mealPlanId The ID of the meal plan to send
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MealPlanningApi.sendMealPlanMealPlanMealPlanIdSendPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update Meal
         * @param {string} mealPlanId The ID of the meal plan to update
         * @param {string} mealId The ID of the meal to update
         * @param {UpdateMealPayload} updateMealPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMealMealPlanMealPlanIdMealsMealIdPut(mealPlanId: string, mealId: string, updateMealPayload: UpdateMealPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMealMealPlanMealPlanIdMealsMealIdPut(mealPlanId, mealId, updateMealPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MealPlanningApi.updateMealMealPlanMealPlanIdMealsMealIdPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MealPlanningApi - factory interface
 * @export
 */
export const MealPlanningApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MealPlanningApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate Meal Plan
         * @param {GenerateMealPlanPayload} generateMealPlanPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMealPlanMealPlanPost(generateMealPlanPayload: GenerateMealPlanPayload, options?: any): AxiosPromise<MealPlanResponse> {
            return localVarFp.generateMealPlanMealPlanPost(generateMealPlanPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Meal Plan
         * @param {string} mealPlanId The ID of the meal plan to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealPlanMealPlanMealPlanIdGet(mealPlanId: string, options?: any): AxiosPromise<MealPlanResponse> {
            return localVarFp.getMealPlanMealPlanMealPlanIdGet(mealPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Meal Plan
         * @param {string} mealPlanId The ID of the meal plan to send
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId: string, options?: any): AxiosPromise<MealPlanResponse> {
            return localVarFp.sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Meal
         * @param {string} mealPlanId The ID of the meal plan to update
         * @param {string} mealId The ID of the meal to update
         * @param {UpdateMealPayload} updateMealPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMealMealPlanMealPlanIdMealsMealIdPut(mealPlanId: string, mealId: string, updateMealPayload: UpdateMealPayload, options?: any): AxiosPromise<MealPlanResponse> {
            return localVarFp.updateMealMealPlanMealPlanIdMealsMealIdPut(mealPlanId, mealId, updateMealPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MealPlanningApi - object-oriented interface
 * @export
 * @class MealPlanningApi
 * @extends {BaseAPI}
 */
export class MealPlanningApi extends BaseAPI {
    /**
     * 
     * @summary Generate Meal Plan
     * @param {GenerateMealPlanPayload} generateMealPlanPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPlanningApi
     */
    public generateMealPlanMealPlanPost(generateMealPlanPayload: GenerateMealPlanPayload, options?: AxiosRequestConfig) {
        return MealPlanningApiFp(this.configuration).generateMealPlanMealPlanPost(generateMealPlanPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Meal Plan
     * @param {string} mealPlanId The ID of the meal plan to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPlanningApi
     */
    public getMealPlanMealPlanMealPlanIdGet(mealPlanId: string, options?: AxiosRequestConfig) {
        return MealPlanningApiFp(this.configuration).getMealPlanMealPlanMealPlanIdGet(mealPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Meal Plan
     * @param {string} mealPlanId The ID of the meal plan to send
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPlanningApi
     */
    public sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId: string, options?: AxiosRequestConfig) {
        return MealPlanningApiFp(this.configuration).sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Meal
     * @param {string} mealPlanId The ID of the meal plan to update
     * @param {string} mealId The ID of the meal to update
     * @param {UpdateMealPayload} updateMealPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealPlanningApi
     */
    public updateMealMealPlanMealPlanIdMealsMealIdPut(mealPlanId: string, mealId: string, updateMealPayload: UpdateMealPayload, options?: AxiosRequestConfig) {
        return MealPlanningApiFp(this.configuration).updateMealMealPlanMealPlanIdMealsMealIdPut(mealPlanId, mealId, updateMealPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Twilio Inbound Sms Webhook
         * @param {string} from 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost: async (from: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost', 'from', from)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost', 'body', body)
            const localVarPath = `/webhooks/twilio/inbound_sms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (from !== undefined) { 
                localVarFormParams.set('from_', from as any);
            }
    
            if (body !== undefined) { 
                localVarFormParams.set('body', body as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Twilio Inbound Sms Webhook
         * @param {string} from 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost(from: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost(from, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotificationApi.twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Twilio Inbound Sms Webhook
         * @param {string} from 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost(from: string, body: string, options?: any): AxiosPromise<void> {
            return localVarFp.twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost(from, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @summary Twilio Inbound Sms Webhook
     * @param {string} from 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost(from: string, body: string, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).twilioInboundSmsWebhookWebhooksTwilioInboundSmsPost(from, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NutritionResearchApi - axios parameter creator
 * @export
 */
export const NutritionResearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Nutrition Research Thread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNutritionResearchThreadNutritionResearchThreadsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nutrition-research/threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Nutrition Research Thread Messages
         * @param {string} threadId 
         * @param {number} [pageSize] The number of messages to return
         * @param {number} [offset] The offset or cursor used in pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet: async (threadId: string, pageSize?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet', 'threadId', threadId)
            const localVarPath = `/nutrition-research/threads/{thread_id}/messages`
                .replace(`{${"thread_id"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Nutrition Research Thread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutritionResearchThreadNutritionResearchThreadsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nutrition-research/threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Nutrition Research Feedback
         * @param {NutritionResearchFeedbackPayload} nutritionResearchFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nutritionResearchFeedbackNutritionResearchFeedbackPost: async (nutritionResearchFeedbackPayload: NutritionResearchFeedbackPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nutritionResearchFeedbackPayload' is not null or undefined
            assertParamExists('nutritionResearchFeedbackNutritionResearchFeedbackPost', 'nutritionResearchFeedbackPayload', nutritionResearchFeedbackPayload)
            const localVarPath = `/nutrition-research/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nutritionResearchFeedbackPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run Nutrition Research Thread
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost: async (threadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost', 'threadId', threadId)
            const localVarPath = `/nutrition-research/threads/run/{thread_id}`
                .replace(`{${"thread_id"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Nutrition Research Message
         * @param {NutritionResearchSendMessagePayload} nutritionResearchSendMessagePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNutritionResearchMessageNutritionResearchMessagePost: async (nutritionResearchSendMessagePayload: NutritionResearchSendMessagePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nutritionResearchSendMessagePayload' is not null or undefined
            assertParamExists('sendNutritionResearchMessageNutritionResearchMessagePost', 'nutritionResearchSendMessagePayload', nutritionResearchSendMessagePayload)
            const localVarPath = `/nutrition-research/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nutritionResearchSendMessagePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NutritionResearchApi - functional programming interface
 * @export
 */
export const NutritionResearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NutritionResearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Nutrition Research Thread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNutritionResearchThreadNutritionResearchThreadsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNutritionResearchThreadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNutritionResearchThreadNutritionResearchThreadsPost(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NutritionResearchApi.createNutritionResearchThreadNutritionResearchThreadsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Nutrition Research Thread Messages
         * @param {string} threadId 
         * @param {number} [pageSize] The number of messages to return
         * @param {number} [offset] The offset or cursor used in pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(threadId: string, pageSize?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNutritionResearchThreadMessagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(threadId, pageSize, offset, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NutritionResearchApi.getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Nutrition Research Thread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNutritionResearchThreadNutritionResearchThreadsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNutritionResearchThreadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNutritionResearchThreadNutritionResearchThreadsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NutritionResearchApi.getNutritionResearchThreadNutritionResearchThreadsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Nutrition Research Feedback
         * @param {NutritionResearchFeedbackPayload} nutritionResearchFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nutritionResearchFeedbackNutritionResearchFeedbackPost(nutritionResearchFeedbackPayload: NutritionResearchFeedbackPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nutritionResearchFeedbackNutritionResearchFeedbackPost(nutritionResearchFeedbackPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NutritionResearchApi.nutritionResearchFeedbackNutritionResearchFeedbackPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Run Nutrition Research Thread
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(threadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutritionResearchRunThreadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(threadId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NutritionResearchApi.runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send Nutrition Research Message
         * @param {NutritionResearchSendMessagePayload} nutritionResearchSendMessagePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNutritionResearchMessageNutritionResearchMessagePost(nutritionResearchSendMessagePayload: NutritionResearchSendMessagePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNutritionResearchMessageNutritionResearchMessagePost(nutritionResearchSendMessagePayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NutritionResearchApi.sendNutritionResearchMessageNutritionResearchMessagePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * NutritionResearchApi - factory interface
 * @export
 */
export const NutritionResearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NutritionResearchApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Nutrition Research Thread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNutritionResearchThreadNutritionResearchThreadsPost(options?: any): AxiosPromise<CreateNutritionResearchThreadResponse> {
            return localVarFp.createNutritionResearchThreadNutritionResearchThreadsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Nutrition Research Thread Messages
         * @param {string} threadId 
         * @param {number} [pageSize] The number of messages to return
         * @param {number} [offset] The offset or cursor used in pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(threadId: string, pageSize?: number, offset?: number, options?: any): AxiosPromise<GetNutritionResearchThreadMessagesResponse> {
            return localVarFp.getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(threadId, pageSize, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Nutrition Research Thread
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutritionResearchThreadNutritionResearchThreadsGet(options?: any): AxiosPromise<GetNutritionResearchThreadResponse> {
            return localVarFp.getNutritionResearchThreadNutritionResearchThreadsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Nutrition Research Feedback
         * @param {NutritionResearchFeedbackPayload} nutritionResearchFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nutritionResearchFeedbackNutritionResearchFeedbackPost(nutritionResearchFeedbackPayload: NutritionResearchFeedbackPayload, options?: any): AxiosPromise<void> {
            return localVarFp.nutritionResearchFeedbackNutritionResearchFeedbackPost(nutritionResearchFeedbackPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run Nutrition Research Thread
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(threadId: string, options?: any): AxiosPromise<NutritionResearchRunThreadResponse> {
            return localVarFp.runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Nutrition Research Message
         * @param {NutritionResearchSendMessagePayload} nutritionResearchSendMessagePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNutritionResearchMessageNutritionResearchMessagePost(nutritionResearchSendMessagePayload: NutritionResearchSendMessagePayload, options?: any): AxiosPromise<void> {
            return localVarFp.sendNutritionResearchMessageNutritionResearchMessagePost(nutritionResearchSendMessagePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NutritionResearchApi - object-oriented interface
 * @export
 * @class NutritionResearchApi
 * @extends {BaseAPI}
 */
export class NutritionResearchApi extends BaseAPI {
    /**
     * 
     * @summary Create Nutrition Research Thread
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutritionResearchApi
     */
    public createNutritionResearchThreadNutritionResearchThreadsPost(options?: AxiosRequestConfig) {
        return NutritionResearchApiFp(this.configuration).createNutritionResearchThreadNutritionResearchThreadsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Nutrition Research Thread Messages
     * @param {string} threadId 
     * @param {number} [pageSize] The number of messages to return
     * @param {number} [offset] The offset or cursor used in pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutritionResearchApi
     */
    public getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(threadId: string, pageSize?: number, offset?: number, options?: AxiosRequestConfig) {
        return NutritionResearchApiFp(this.configuration).getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(threadId, pageSize, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Nutrition Research Thread
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutritionResearchApi
     */
    public getNutritionResearchThreadNutritionResearchThreadsGet(options?: AxiosRequestConfig) {
        return NutritionResearchApiFp(this.configuration).getNutritionResearchThreadNutritionResearchThreadsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Nutrition Research Feedback
     * @param {NutritionResearchFeedbackPayload} nutritionResearchFeedbackPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutritionResearchApi
     */
    public nutritionResearchFeedbackNutritionResearchFeedbackPost(nutritionResearchFeedbackPayload: NutritionResearchFeedbackPayload, options?: AxiosRequestConfig) {
        return NutritionResearchApiFp(this.configuration).nutritionResearchFeedbackNutritionResearchFeedbackPost(nutritionResearchFeedbackPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run Nutrition Research Thread
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutritionResearchApi
     */
    public runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(threadId: string, options?: AxiosRequestConfig) {
        return NutritionResearchApiFp(this.configuration).runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Nutrition Research Message
     * @param {NutritionResearchSendMessagePayload} nutritionResearchSendMessagePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutritionResearchApi
     */
    public sendNutritionResearchMessageNutritionResearchMessagePost(nutritionResearchSendMessagePayload: NutritionResearchSendMessagePayload, options?: AxiosRequestConfig) {
        return NutritionResearchApiFp(this.configuration).sendNutritionResearchMessageNutritionResearchMessagePost(nutritionResearchSendMessagePayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PartnerBookingApi - axios parameter creator
 * @export
 */
export const PartnerBookingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Complete Partner Booking
         * @param {string} bookingCode 
         * @param {CompletePartnerBookingPayload} completePartnerBookingPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePartnerBookingPartnersBookingBookingCodePost: async (bookingCode: string, completePartnerBookingPayload: CompletePartnerBookingPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingCode' is not null or undefined
            assertParamExists('completePartnerBookingPartnersBookingBookingCodePost', 'bookingCode', bookingCode)
            // verify required parameter 'completePartnerBookingPayload' is not null or undefined
            assertParamExists('completePartnerBookingPartnersBookingBookingCodePost', 'completePartnerBookingPayload', completePartnerBookingPayload)
            const localVarPath = `/partners/booking/{booking_code}`
                .replace(`{${"booking_code"}}`, encodeURIComponent(String(bookingCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completePartnerBookingPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Partner Booking Info
         * @param {string} bookingCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerBookingInfoPartnersBookingBookingCodeGet: async (bookingCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingCode' is not null or undefined
            assertParamExists('getPartnerBookingInfoPartnersBookingBookingCodeGet', 'bookingCode', bookingCode)
            const localVarPath = `/partners/booking/{booking_code}`
                .replace(`{${"booking_code"}}`, encodeURIComponent(String(bookingCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerBookingApi - functional programming interface
 * @export
 */
export const PartnerBookingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerBookingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Complete Partner Booking
         * @param {string} bookingCode 
         * @param {CompletePartnerBookingPayload} completePartnerBookingPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePartnerBookingPartnersBookingBookingCodePost(bookingCode: string, completePartnerBookingPayload: CompletePartnerBookingPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompletePartnerBookingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePartnerBookingPartnersBookingBookingCodePost(bookingCode, completePartnerBookingPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PartnerBookingApi.completePartnerBookingPartnersBookingBookingCodePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Partner Booking Info
         * @param {string} bookingCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerBookingInfoPartnersBookingBookingCodeGet(bookingCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerBookingInfoPartnersBookingBookingCodeGet(bookingCode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PartnerBookingApi.getPartnerBookingInfoPartnersBookingBookingCodeGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PartnerBookingApi - factory interface
 * @export
 */
export const PartnerBookingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerBookingApiFp(configuration)
    return {
        /**
         * 
         * @summary Complete Partner Booking
         * @param {string} bookingCode 
         * @param {CompletePartnerBookingPayload} completePartnerBookingPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePartnerBookingPartnersBookingBookingCodePost(bookingCode: string, completePartnerBookingPayload: CompletePartnerBookingPayload, options?: any): AxiosPromise<CompletePartnerBookingResponse> {
            return localVarFp.completePartnerBookingPartnersBookingBookingCodePost(bookingCode, completePartnerBookingPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Partner Booking Info
         * @param {string} bookingCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerBookingInfoPartnersBookingBookingCodeGet(bookingCode: string, options?: any): AxiosPromise<BookingInfoResponse> {
            return localVarFp.getPartnerBookingInfoPartnersBookingBookingCodeGet(bookingCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerBookingApi - object-oriented interface
 * @export
 * @class PartnerBookingApi
 * @extends {BaseAPI}
 */
export class PartnerBookingApi extends BaseAPI {
    /**
     * 
     * @summary Complete Partner Booking
     * @param {string} bookingCode 
     * @param {CompletePartnerBookingPayload} completePartnerBookingPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerBookingApi
     */
    public completePartnerBookingPartnersBookingBookingCodePost(bookingCode: string, completePartnerBookingPayload: CompletePartnerBookingPayload, options?: AxiosRequestConfig) {
        return PartnerBookingApiFp(this.configuration).completePartnerBookingPartnersBookingBookingCodePost(bookingCode, completePartnerBookingPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Partner Booking Info
     * @param {string} bookingCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerBookingApi
     */
    public getPartnerBookingInfoPartnersBookingBookingCodeGet(bookingCode: string, options?: AxiosRequestConfig) {
        return PartnerBookingApiFp(this.configuration).getPartnerBookingInfoPartnersBookingBookingCodeGet(bookingCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PatientApi - axios parameter creator
 * @export
 */
export const PatientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Patient Lab Feedback
         * @param {string} labId 
         * @param {PatientId} patientId 
         * @param {AddPatientLabFeedbackPayload} addPatientLabFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost: async (labId: string, patientId: PatientId, addPatientLabFeedbackPayload: AddPatientLabFeedbackPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost', 'labId', labId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost', 'patientId', patientId)
            // verify required parameter 'addPatientLabFeedbackPayload' is not null or undefined
            assertParamExists('addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost', 'addPatientLabFeedbackPayload', addPatientLabFeedbackPayload)
            const localVarPath = `/patient/{patient_id}/labs/{lab_id}/feedback`
                .replace(`{${"lab_id"}}`, encodeURIComponent(String(labId)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPatientLabFeedbackPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Journal Entry
         * @param {CreateJournalEntryPayload} createJournalEntryPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJournalEntryPatientJournalEntriesPost: async (createJournalEntryPayload: CreateJournalEntryPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createJournalEntryPayload' is not null or undefined
            assertParamExists('createJournalEntryPatientJournalEntriesPost', 'createJournalEntryPayload', createJournalEntryPayload)
            const localVarPath = `/patient/journal/entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createJournalEntryPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Journal Entry
         * @param {string} journalEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete: async (journalEntryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'journalEntryId' is not null or undefined
            assertParamExists('deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete', 'journalEntryId', journalEntryId)
            const localVarPath = `/patient/journal/entries/{journal_entry_id}`
                .replace(`{${"journal_entry_id"}}`, encodeURIComponent(String(journalEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External Facing Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalFacingDetailsPatientExternalFacingDetailsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patient/external_facing_details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Journal Entry
         * @param {string} journalEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalEntryPatientJournalEntriesJournalEntryIdGet: async (journalEntryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'journalEntryId' is not null or undefined
            assertParamExists('getJournalEntryPatientJournalEntriesJournalEntryIdGet', 'journalEntryId', journalEntryId)
            const localVarPath = `/patient/journal/entries/{journal_entry_id}`
                .replace(`{${"journal_entry_id"}}`, encodeURIComponent(String(journalEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Journal Streak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalStreakPatientJournalStreakGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patient/journal/streak`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient By Email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientByEmailInternalPatientsEmailEmailGet: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getPatientByEmailInternalPatientsEmailEmailGet', 'email', email)
            const localVarPath = `/internal/patients/email/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Confirmation Status By Onboarding Code
         * @param {string} onboardingCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet: async (onboardingCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'onboardingCode' is not null or undefined
            assertParamExists('getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet', 'onboardingCode', onboardingCode)
            const localVarPath = `/patient/confirm/code/{onboarding_code}`
                .replace(`{${"onboarding_code"}}`, encodeURIComponent(String(onboardingCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Confirmation Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientConfirmationStatusPatientConfirmGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patient/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Details
         * @param {PatientId} patientId 
         * @param {Array<PatientDetailsInclusions>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDetailsPatientsPatientIdGet: async (patientId: PatientId, include?: Array<PatientDetailsInclusions>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientDetailsPatientsPatientIdGet', 'patientId', patientId)
            const localVarPath = `/patients/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Intake Form Answers
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientIntakeFormAnswersPatientsPatientIdIntakeGet: async (patientId: PatientId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientIntakeFormAnswersPatientsPatientIdIntakeGet', 'patientId', patientId)
            const localVarPath = `/patients/{patient_id}/intake`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve paginated journal entries for the specified patient.  The entries are returned in descending order based on their creation date, with the most recent entries appearing first.  Args:     patient_id (PatientIdAllowingMe): The ID of the patient or \'me\' for the current user.     cursor (str | None): Pagination cursor for fetching the next set of results.     limit (int): Number of entries to return per page (default: 20, max: 100).  Returns:     CursorPaginatedJournalEntriesResponse: A paginated list of journal entries     and the next pagination cursor.
         * @summary Get Patient Journal Entries
         * @param {PatientId} patientId 
         * @param {string | null} [cursor] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientJournalEntriesPatientPatientIdJournalEntriesGet: async (patientId: PatientId, cursor?: string | null, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientJournalEntriesPatientPatientIdJournalEntriesGet', 'patientId', patientId)
            const localVarPath = `/patient/{patient_id}/journal/entries`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Lab
         * @param {string} labId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientLabPatientPatientIdLabsLabIdGet: async (labId: string, patientId: PatientId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'labId' is not null or undefined
            assertParamExists('getPatientLabPatientPatientIdLabsLabIdGet', 'labId', labId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientLabPatientPatientIdLabsLabIdGet', 'patientId', patientId)
            const localVarPath = `/patient/{patient_id}/labs/{lab_id}`
                .replace(`{${"lab_id"}}`, encodeURIComponent(String(labId)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Labs
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientLabsPatientPatientIdLabsGet: async (patientId: PatientId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientLabsPatientPatientIdLabsGet', 'patientId', patientId)
            const localVarPath = `/patient/{patient_id}/labs`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patients For Provider
         * @param {Array<PatientDetailsInclusions>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsForProviderPatientsGet: async (include?: Array<PatientDetailsInclusions>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Release Form
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleaseFormPatientsPatientIdReleaseFormPdfGet: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getReleaseFormPatientsPatientIdReleaseFormPdfGet', 'patientId', patientId)
            const localVarPath = `/patients/{patient_id}/release_form.pdf`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Release Form Template Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleaseFormTemplateUrlPatientsFilesReleaseFormTemplateUrlGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/files/release_form_template_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Import patient and dependents from Healthie, creating models in the database.  Dependents include: - appointments - chat history  and more.
         * @summary Import Patient And Dependents
         * @param {HealthieImportPayload} healthieImportPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPatientAndDependentsAdminImportPatientPost: async (healthieImportPayload: HealthieImportPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthieImportPayload' is not null or undefined
            assertParamExists('importPatientAndDependentsAdminImportPatientPost', 'healthieImportPayload', healthieImportPayload)
            const localVarPath = `/admin/import-patient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthieImportPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Add Patient Utm Parameters
         * @param {string} patientId 
         * @param {PatientUTMParametersPayload} patientUTMParametersPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalAddPatientUtmParametersInternalPatientsPatientIdUtmParametersPost: async (patientId: string, patientUTMParametersPayload: PatientUTMParametersPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('internalAddPatientUtmParametersInternalPatientsPatientIdUtmParametersPost', 'patientId', patientId)
            // verify required parameter 'patientUTMParametersPayload' is not null or undefined
            assertParamExists('internalAddPatientUtmParametersInternalPatientsPatientIdUtmParametersPost', 'patientUTMParametersPayload', patientUTMParametersPayload)
            const localVarPath = `/internal/patients/{patient_id}/utm-parameters`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientUTMParametersPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Register Patient
         * @param {InternalRegisterPatientPayload} internalRegisterPatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalRegisterPatientInternalPatientsPost: async (internalRegisterPatientPayload: InternalRegisterPatientPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalRegisterPatientPayload' is not null or undefined
            assertParamExists('internalRegisterPatientInternalPatientsPost', 'internalRegisterPatientPayload', internalRegisterPatientPayload)
            const localVarPath = `/internal/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalRegisterPatientPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Update Patient
         * @param {string} patientId 
         * @param {UpdatePatientPayload} updatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdatePatientInternalPatientsPatientIdPatch: async (patientId: string, updatePatientPayload: UpdatePatientPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('internalUpdatePatientInternalPatientsPatientIdPatch', 'patientId', patientId)
            // verify required parameter 'updatePatientPayload' is not null or undefined
            assertParamExists('internalUpdatePatientInternalPatientsPatientIdPatch', 'updatePatientPayload', updatePatientPayload)
            const localVarPath = `/internal/patients/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Update Patient Provider
         * @param {string} patientId 
         * @param {UpdatePatientProviderPayload} updatePatientProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdatePatientProviderInternalPatientsPatientIdProviderPatch: async (patientId: string, updatePatientProviderPayload: UpdatePatientProviderPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('internalUpdatePatientProviderInternalPatientsPatientIdProviderPatch', 'patientId', patientId)
            // verify required parameter 'updatePatientProviderPayload' is not null or undefined
            assertParamExists('internalUpdatePatientProviderInternalPatientsPatientIdProviderPatch', 'updatePatientProviderPayload', updatePatientProviderPayload)
            const localVarPath = `/internal/patients/{patient_id}/provider`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientProviderPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patient Confirmation By Code
         * @param {PatientConfirmationByCodePayload} patientConfirmationByCodePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientConfirmationByCodePatientConfirmCodePost: async (patientConfirmationByCodePayload: PatientConfirmationByCodePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientConfirmationByCodePayload' is not null or undefined
            assertParamExists('patientConfirmationByCodePatientConfirmCodePost', 'patientConfirmationByCodePayload', patientConfirmationByCodePayload)
            const localVarPath = `/patient/confirm/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientConfirmationByCodePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patient Confirmation
         * @param {PatientConfirmationPayload} patientConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientConfirmationPatientConfirmPost: async (patientConfirmationPayload: PatientConfirmationPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientConfirmationPayload' is not null or undefined
            assertParamExists('patientConfirmationPatientConfirmPost', 'patientConfirmationPayload', patientConfirmationPayload)
            const localVarPath = `/patient/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientConfirmationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register Patient
         * @param {RegisterPatientPayload} registerPatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPatientAdminPatientsPost: async (registerPatientPayload: RegisterPatientPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerPatientPayload' is not null or undefined
            assertParamExists('registerPatientAdminPatientsPost', 'registerPatientPayload', registerPatientPayload)
            const localVarPath = `/admin/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerPatientPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Patient Intake Form
         * @param {PatientId} patientId 
         * @param {UpdateIntakePayload} updateIntakePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPatientIntakeFormPatientsPatientIdIntakePost: async (patientId: PatientId, updateIntakePayload: UpdateIntakePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('setPatientIntakeFormPatientsPatientIdIntakePost', 'patientId', patientId)
            // verify required parameter 'updateIntakePayload' is not null or undefined
            assertParamExists('setPatientIntakeFormPatientsPatientIdIntakePost', 'updateIntakePayload', updateIntakePayload)
            const localVarPath = `/patients/{patient_id}/intake`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIntakePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit Booking Email
         * @param {PatientBookingSubmitEmailPayload} patientBookingSubmitEmailPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitBookingEmailInternalPatientsBookingSubmitEmailPost: async (patientBookingSubmitEmailPayload: PatientBookingSubmitEmailPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientBookingSubmitEmailPayload' is not null or undefined
            assertParamExists('submitBookingEmailInternalPatientsBookingSubmitEmailPost', 'patientBookingSubmitEmailPayload', patientBookingSubmitEmailPayload)
            const localVarPath = `/internal/patients/booking-submit-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientBookingSubmitEmailPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Journal Entry
         * @param {string} journalEntryId 
         * @param {UpdateJournalEntryPayload} updateJournalEntryPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJournalEntryPatientJournalEntriesJournalEntryIdPatch: async (journalEntryId: string, updateJournalEntryPayload: UpdateJournalEntryPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'journalEntryId' is not null or undefined
            assertParamExists('updateJournalEntryPatientJournalEntriesJournalEntryIdPatch', 'journalEntryId', journalEntryId)
            // verify required parameter 'updateJournalEntryPayload' is not null or undefined
            assertParamExists('updateJournalEntryPatientJournalEntriesJournalEntryIdPatch', 'updateJournalEntryPayload', updateJournalEntryPayload)
            const localVarPath = `/patient/journal/entries/{journal_entry_id}`
                .replace(`{${"journal_entry_id"}}`, encodeURIComponent(String(journalEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateJournalEntryPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Patient
         * @param {PatientId} patientId 
         * @param {UpdatePatientPayload} updatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientPatientsPatientIdPatch: async (patientId: PatientId, updatePatientPayload: UpdatePatientPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updatePatientPatientsPatientIdPatch', 'patientId', patientId)
            // verify required parameter 'updatePatientPayload' is not null or undefined
            assertParamExists('updatePatientPatientsPatientIdPatch', 'updatePatientPayload', updatePatientPayload)
            const localVarPath = `/patients/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Patient Provider
         * @param {PatientId} patientId 
         * @param {UpdatePatientProviderPayload} updatePatientProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientProviderPatientsPatientIdProviderPatch: async (patientId: PatientId, updatePatientProviderPayload: UpdatePatientProviderPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updatePatientProviderPatientsPatientIdProviderPatch', 'patientId', patientId)
            // verify required parameter 'updatePatientProviderPayload' is not null or undefined
            assertParamExists('updatePatientProviderPatientsPatientIdProviderPatch', 'updatePatientProviderPayload', updatePatientProviderPayload)
            const localVarPath = `/patients/{patient_id}/provider`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientProviderPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Release Of Information
         * @param {PatientId} patientId 
         * @param {ReleaseOfInformationPayload} releaseOfInformationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost: async (patientId: PatientId, releaseOfInformationPayload: ReleaseOfInformationPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost', 'patientId', patientId)
            // verify required parameter 'releaseOfInformationPayload' is not null or undefined
            assertParamExists('updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost', 'releaseOfInformationPayload', releaseOfInformationPayload)
            const localVarPath = `/patients/{patient_id}/release_of_information`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(releaseOfInformationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Patient Lab
         * @param {PatientId} patientId 
         * @param {UploadPatientLabPayload} uploadPatientLabPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPatientLabPatientPatientIdLabsPost: async (patientId: PatientId, uploadPatientLabPayload: UploadPatientLabPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('uploadPatientLabPatientPatientIdLabsPost', 'patientId', patientId)
            // verify required parameter 'uploadPatientLabPayload' is not null or undefined
            assertParamExists('uploadPatientLabPatientPatientIdLabsPost', 'uploadPatientLabPayload', uploadPatientLabPayload)
            const localVarPath = `/patient/{patient_id}/labs`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadPatientLabPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientApi - functional programming interface
 * @export
 */
export const PatientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Patient Lab Feedback
         * @param {string} labId 
         * @param {PatientId} patientId 
         * @param {AddPatientLabFeedbackPayload} addPatientLabFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost(labId: string, patientId: PatientId, addPatientLabFeedbackPayload: AddPatientLabFeedbackPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost(labId, patientId, addPatientLabFeedbackPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create Journal Entry
         * @param {CreateJournalEntryPayload} createJournalEntryPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createJournalEntryPatientJournalEntriesPost(createJournalEntryPayload: CreateJournalEntryPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalEntryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createJournalEntryPatientJournalEntriesPost(createJournalEntryPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.createJournalEntryPatientJournalEntriesPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Journal Entry
         * @param {string} journalEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(journalEntryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(journalEntryId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary External Facing Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalFacingDetailsPatientExternalFacingDetailsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientExternalFacingDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalFacingDetailsPatientExternalFacingDetailsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.externalFacingDetailsPatientExternalFacingDetailsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Journal Entry
         * @param {string} journalEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalEntryPatientJournalEntriesJournalEntryIdGet(journalEntryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalEntryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalEntryPatientJournalEntriesJournalEntryIdGet(journalEntryId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.getJournalEntryPatientJournalEntriesJournalEntryIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Journal Streak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalStreakPatientJournalStreakGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalStreakResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalStreakPatientJournalStreakGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.getJournalStreakPatientJournalStreakGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient By Email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientByEmailInternalPatientsEmailEmailGet(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientByEmailInternalPatientsEmailEmailGet(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.getPatientByEmailInternalPatientsEmailEmailGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Confirmation Status By Onboarding Code
         * @param {string} onboardingCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(onboardingCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientConfirmationPreflightResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(onboardingCode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Confirmation Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientConfirmationStatusPatientConfirmGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientConfirmationPreflightResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientConfirmationStatusPatientConfirmGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.getPatientConfirmationStatusPatientConfirmGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Details
         * @param {PatientId} patientId 
         * @param {Array<PatientDetailsInclusions>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientDetailsPatientsPatientIdGet(patientId: PatientId, include?: Array<PatientDetailsInclusions>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientDetailsPatientsPatientIdGet(patientId, include, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.getPatientDetailsPatientsPatientIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Intake Form Answers
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(patientId: PatientId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(patientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.getPatientIntakeFormAnswersPatientsPatientIdIntakeGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieve paginated journal entries for the specified patient.  The entries are returned in descending order based on their creation date, with the most recent entries appearing first.  Args:     patient_id (PatientIdAllowingMe): The ID of the patient or \'me\' for the current user.     cursor (str | None): Pagination cursor for fetching the next set of results.     limit (int): Number of entries to return per page (default: 20, max: 100).  Returns:     CursorPaginatedJournalEntriesResponse: A paginated list of journal entries     and the next pagination cursor.
         * @summary Get Patient Journal Entries
         * @param {PatientId} patientId 
         * @param {string | null} [cursor] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientJournalEntriesPatientPatientIdJournalEntriesGet(patientId: PatientId, cursor?: string | null, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CursorPaginatedJournalEntriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientJournalEntriesPatientPatientIdJournalEntriesGet(patientId, cursor, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.getPatientJournalEntriesPatientPatientIdJournalEntriesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Lab
         * @param {string} labId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientLabPatientPatientIdLabsLabIdGet(labId: string, patientId: PatientId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientLabPatientPatientIdLabsLabIdGet(labId, patientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.getPatientLabPatientPatientIdLabsLabIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Labs
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientLabsPatientPatientIdLabsGet(patientId: PatientId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientLabsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientLabsPatientPatientIdLabsGet(patientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.getPatientLabsPatientPatientIdLabsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patients For Provider
         * @param {Array<PatientDetailsInclusions>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientsForProviderPatientsGet(include?: Array<PatientDetailsInclusions>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDetailsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientsForProviderPatientsGet(include, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.getPatientsForProviderPatientsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Release Form
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReleaseFormPatientsPatientIdReleaseFormPdfGet(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReleaseFormPatientsPatientIdReleaseFormPdfGet(patientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.getReleaseFormPatientsPatientIdReleaseFormPdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Release Form Template Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReleaseFormTemplateUrlPatientsFilesReleaseFormTemplateUrlGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientReleaseFormTemplateUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReleaseFormTemplateUrlPatientsFilesReleaseFormTemplateUrlGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.getReleaseFormTemplateUrlPatientsFilesReleaseFormTemplateUrlGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Import patient and dependents from Healthie, creating models in the database.  Dependents include: - appointments - chat history  and more.
         * @summary Import Patient And Dependents
         * @param {HealthieImportPayload} healthieImportPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importPatientAndDependentsAdminImportPatientPost(healthieImportPayload: HealthieImportPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportPatientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importPatientAndDependentsAdminImportPatientPost(healthieImportPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.importPatientAndDependentsAdminImportPatientPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Internal Add Patient Utm Parameters
         * @param {string} patientId 
         * @param {PatientUTMParametersPayload} patientUTMParametersPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalAddPatientUtmParametersInternalPatientsPatientIdUtmParametersPost(patientId: string, patientUTMParametersPayload: PatientUTMParametersPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalAddPatientUtmParametersInternalPatientsPatientIdUtmParametersPost(patientId, patientUTMParametersPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.internalAddPatientUtmParametersInternalPatientsPatientIdUtmParametersPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Internal Register Patient
         * @param {InternalRegisterPatientPayload} internalRegisterPatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalRegisterPatientInternalPatientsPost(internalRegisterPatientPayload: InternalRegisterPatientPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalRegisterPatientInternalPatientsPost(internalRegisterPatientPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.internalRegisterPatientInternalPatientsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Internal Update Patient
         * @param {string} patientId 
         * @param {UpdatePatientPayload} updatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdatePatientInternalPatientsPatientIdPatch(patientId: string, updatePatientPayload: UpdatePatientPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdatePatientInternalPatientsPatientIdPatch(patientId, updatePatientPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.internalUpdatePatientInternalPatientsPatientIdPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Internal Update Patient Provider
         * @param {string} patientId 
         * @param {UpdatePatientProviderPayload} updatePatientProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdatePatientProviderInternalPatientsPatientIdProviderPatch(patientId: string, updatePatientProviderPayload: UpdatePatientProviderPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdatePatientProviderInternalPatientsPatientIdProviderPatch(patientId, updatePatientProviderPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.internalUpdatePatientProviderInternalPatientsPatientIdProviderPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Patient Confirmation By Code
         * @param {PatientConfirmationByCodePayload} patientConfirmationByCodePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientConfirmationByCodePatientConfirmCodePost(patientConfirmationByCodePayload: PatientConfirmationByCodePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientConfirmationByCodePatientConfirmCodePost(patientConfirmationByCodePayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.patientConfirmationByCodePatientConfirmCodePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Patient Confirmation
         * @param {PatientConfirmationPayload} patientConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientConfirmationPatientConfirmPost(patientConfirmationPayload: PatientConfirmationPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientConfirmationPatientConfirmPost(patientConfirmationPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.patientConfirmationPatientConfirmPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Register Patient
         * @param {RegisterPatientPayload} registerPatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerPatientAdminPatientsPost(registerPatientPayload: RegisterPatientPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerPatientAdminPatientsPost(registerPatientPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.registerPatientAdminPatientsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Set Patient Intake Form
         * @param {PatientId} patientId 
         * @param {UpdateIntakePayload} updateIntakePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPatientIntakeFormPatientsPatientIdIntakePost(patientId: PatientId, updateIntakePayload: UpdateIntakePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPatientIntakeFormPatientsPatientIdIntakePost(patientId, updateIntakePayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.setPatientIntakeFormPatientsPatientIdIntakePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Submit Booking Email
         * @param {PatientBookingSubmitEmailPayload} patientBookingSubmitEmailPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitBookingEmailInternalPatientsBookingSubmitEmailPost(patientBookingSubmitEmailPayload: PatientBookingSubmitEmailPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientBookingSubmitEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitBookingEmailInternalPatientsBookingSubmitEmailPost(patientBookingSubmitEmailPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.submitBookingEmailInternalPatientsBookingSubmitEmailPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update Journal Entry
         * @param {string} journalEntryId 
         * @param {UpdateJournalEntryPayload} updateJournalEntryPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(journalEntryId: string, updateJournalEntryPayload: UpdateJournalEntryPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalEntryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(journalEntryId, updateJournalEntryPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.updateJournalEntryPatientJournalEntriesJournalEntryIdPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update Patient
         * @param {PatientId} patientId 
         * @param {UpdatePatientPayload} updatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatientPatientsPatientIdPatch(patientId: PatientId, updatePatientPayload: UpdatePatientPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatientPatientsPatientIdPatch(patientId, updatePatientPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.updatePatientPatientsPatientIdPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update Patient Provider
         * @param {PatientId} patientId 
         * @param {UpdatePatientProviderPayload} updatePatientProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatientProviderPatientsPatientIdProviderPatch(patientId: PatientId, updatePatientProviderPayload: UpdatePatientProviderPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatientProviderPatientsPatientIdProviderPatch(patientId, updatePatientProviderPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.updatePatientProviderPatientsPatientIdProviderPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update Release Of Information
         * @param {PatientId} patientId 
         * @param {ReleaseOfInformationPayload} releaseOfInformationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(patientId: PatientId, releaseOfInformationPayload: ReleaseOfInformationPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(patientId, releaseOfInformationPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload Patient Lab
         * @param {PatientId} patientId 
         * @param {UploadPatientLabPayload} uploadPatientLabPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPatientLabPatientPatientIdLabsPost(patientId: PatientId, uploadPatientLabPayload: UploadPatientLabPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientLabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPatientLabPatientPatientIdLabsPost(patientId, uploadPatientLabPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientApi.uploadPatientLabPatientPatientIdLabsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PatientApi - factory interface
 * @export
 */
export const PatientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Patient Lab Feedback
         * @param {string} labId 
         * @param {PatientId} patientId 
         * @param {AddPatientLabFeedbackPayload} addPatientLabFeedbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost(labId: string, patientId: PatientId, addPatientLabFeedbackPayload: AddPatientLabFeedbackPayload, options?: any): AxiosPromise<void> {
            return localVarFp.addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost(labId, patientId, addPatientLabFeedbackPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Journal Entry
         * @param {CreateJournalEntryPayload} createJournalEntryPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJournalEntryPatientJournalEntriesPost(createJournalEntryPayload: CreateJournalEntryPayload, options?: any): AxiosPromise<JournalEntryResponse> {
            return localVarFp.createJournalEntryPatientJournalEntriesPost(createJournalEntryPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Journal Entry
         * @param {string} journalEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(journalEntryId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(journalEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External Facing Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalFacingDetailsPatientExternalFacingDetailsGet(options?: any): AxiosPromise<PatientExternalFacingDetailsResponse> {
            return localVarFp.externalFacingDetailsPatientExternalFacingDetailsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Journal Entry
         * @param {string} journalEntryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalEntryPatientJournalEntriesJournalEntryIdGet(journalEntryId: string, options?: any): AxiosPromise<JournalEntryResponse> {
            return localVarFp.getJournalEntryPatientJournalEntriesJournalEntryIdGet(journalEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Journal Streak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalStreakPatientJournalStreakGet(options?: any): AxiosPromise<JournalStreakResponse> {
            return localVarFp.getJournalStreakPatientJournalStreakGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient By Email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientByEmailInternalPatientsEmailEmailGet(email: string, options?: any): AxiosPromise<PatientDetailsResponse> {
            return localVarFp.getPatientByEmailInternalPatientsEmailEmailGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Confirmation Status By Onboarding Code
         * @param {string} onboardingCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(onboardingCode: string, options?: any): AxiosPromise<PatientConfirmationPreflightResponse> {
            return localVarFp.getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(onboardingCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Confirmation Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientConfirmationStatusPatientConfirmGet(options?: any): AxiosPromise<PatientConfirmationPreflightResponse> {
            return localVarFp.getPatientConfirmationStatusPatientConfirmGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Details
         * @param {PatientId} patientId 
         * @param {Array<PatientDetailsInclusions>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDetailsPatientsPatientIdGet(patientId: PatientId, include?: Array<PatientDetailsInclusions>, options?: any): AxiosPromise<PatientDetailsResponse> {
            return localVarFp.getPatientDetailsPatientsPatientIdGet(patientId, include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Intake Form Answers
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(patientId: PatientId, options?: any): AxiosPromise<IntakeFormResponse> {
            return localVarFp.getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve paginated journal entries for the specified patient.  The entries are returned in descending order based on their creation date, with the most recent entries appearing first.  Args:     patient_id (PatientIdAllowingMe): The ID of the patient or \'me\' for the current user.     cursor (str | None): Pagination cursor for fetching the next set of results.     limit (int): Number of entries to return per page (default: 20, max: 100).  Returns:     CursorPaginatedJournalEntriesResponse: A paginated list of journal entries     and the next pagination cursor.
         * @summary Get Patient Journal Entries
         * @param {PatientId} patientId 
         * @param {string | null} [cursor] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientJournalEntriesPatientPatientIdJournalEntriesGet(patientId: PatientId, cursor?: string | null, limit?: number, options?: any): AxiosPromise<CursorPaginatedJournalEntriesResponse> {
            return localVarFp.getPatientJournalEntriesPatientPatientIdJournalEntriesGet(patientId, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Lab
         * @param {string} labId 
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientLabPatientPatientIdLabsLabIdGet(labId: string, patientId: PatientId, options?: any): AxiosPromise<PatientLabResponse> {
            return localVarFp.getPatientLabPatientPatientIdLabsLabIdGet(labId, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Labs
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientLabsPatientPatientIdLabsGet(patientId: PatientId, options?: any): AxiosPromise<PatientLabsResponse> {
            return localVarFp.getPatientLabsPatientPatientIdLabsGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patients For Provider
         * @param {Array<PatientDetailsInclusions>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsForProviderPatientsGet(include?: Array<PatientDetailsInclusions>, options?: any): AxiosPromise<PatientDetailsListResponse> {
            return localVarFp.getPatientsForProviderPatientsGet(include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Release Form
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleaseFormPatientsPatientIdReleaseFormPdfGet(patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getReleaseFormPatientsPatientIdReleaseFormPdfGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Release Form Template Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleaseFormTemplateUrlPatientsFilesReleaseFormTemplateUrlGet(options?: any): AxiosPromise<PatientReleaseFormTemplateUrlResponse> {
            return localVarFp.getReleaseFormTemplateUrlPatientsFilesReleaseFormTemplateUrlGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Import patient and dependents from Healthie, creating models in the database.  Dependents include: - appointments - chat history  and more.
         * @summary Import Patient And Dependents
         * @param {HealthieImportPayload} healthieImportPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPatientAndDependentsAdminImportPatientPost(healthieImportPayload: HealthieImportPayload, options?: any): AxiosPromise<ImportPatientResponse> {
            return localVarFp.importPatientAndDependentsAdminImportPatientPost(healthieImportPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Add Patient Utm Parameters
         * @param {string} patientId 
         * @param {PatientUTMParametersPayload} patientUTMParametersPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalAddPatientUtmParametersInternalPatientsPatientIdUtmParametersPost(patientId: string, patientUTMParametersPayload: PatientUTMParametersPayload, options?: any): AxiosPromise<void> {
            return localVarFp.internalAddPatientUtmParametersInternalPatientsPatientIdUtmParametersPost(patientId, patientUTMParametersPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Register Patient
         * @param {InternalRegisterPatientPayload} internalRegisterPatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalRegisterPatientInternalPatientsPost(internalRegisterPatientPayload: InternalRegisterPatientPayload, options?: any): AxiosPromise<PatientDetailsResponse> {
            return localVarFp.internalRegisterPatientInternalPatientsPost(internalRegisterPatientPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Update Patient
         * @param {string} patientId 
         * @param {UpdatePatientPayload} updatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdatePatientInternalPatientsPatientIdPatch(patientId: string, updatePatientPayload: UpdatePatientPayload, options?: any): AxiosPromise<PatientDetailsResponse> {
            return localVarFp.internalUpdatePatientInternalPatientsPatientIdPatch(patientId, updatePatientPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Update Patient Provider
         * @param {string} patientId 
         * @param {UpdatePatientProviderPayload} updatePatientProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdatePatientProviderInternalPatientsPatientIdProviderPatch(patientId: string, updatePatientProviderPayload: UpdatePatientProviderPayload, options?: any): AxiosPromise<void> {
            return localVarFp.internalUpdatePatientProviderInternalPatientsPatientIdProviderPatch(patientId, updatePatientProviderPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patient Confirmation By Code
         * @param {PatientConfirmationByCodePayload} patientConfirmationByCodePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientConfirmationByCodePatientConfirmCodePost(patientConfirmationByCodePayload: PatientConfirmationByCodePayload, options?: any): AxiosPromise<void> {
            return localVarFp.patientConfirmationByCodePatientConfirmCodePost(patientConfirmationByCodePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patient Confirmation
         * @param {PatientConfirmationPayload} patientConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientConfirmationPatientConfirmPost(patientConfirmationPayload: PatientConfirmationPayload, options?: any): AxiosPromise<void> {
            return localVarFp.patientConfirmationPatientConfirmPost(patientConfirmationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register Patient
         * @param {RegisterPatientPayload} registerPatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPatientAdminPatientsPost(registerPatientPayload: RegisterPatientPayload, options?: any): AxiosPromise<PatientDetailsResponse> {
            return localVarFp.registerPatientAdminPatientsPost(registerPatientPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Patient Intake Form
         * @param {PatientId} patientId 
         * @param {UpdateIntakePayload} updateIntakePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPatientIntakeFormPatientsPatientIdIntakePost(patientId: PatientId, updateIntakePayload: UpdateIntakePayload, options?: any): AxiosPromise<IntakeFormResponse> {
            return localVarFp.setPatientIntakeFormPatientsPatientIdIntakePost(patientId, updateIntakePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit Booking Email
         * @param {PatientBookingSubmitEmailPayload} patientBookingSubmitEmailPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitBookingEmailInternalPatientsBookingSubmitEmailPost(patientBookingSubmitEmailPayload: PatientBookingSubmitEmailPayload, options?: any): AxiosPromise<PatientBookingSubmitEmailResponse> {
            return localVarFp.submitBookingEmailInternalPatientsBookingSubmitEmailPost(patientBookingSubmitEmailPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Journal Entry
         * @param {string} journalEntryId 
         * @param {UpdateJournalEntryPayload} updateJournalEntryPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(journalEntryId: string, updateJournalEntryPayload: UpdateJournalEntryPayload, options?: any): AxiosPromise<JournalEntryResponse> {
            return localVarFp.updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(journalEntryId, updateJournalEntryPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Patient
         * @param {PatientId} patientId 
         * @param {UpdatePatientPayload} updatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientPatientsPatientIdPatch(patientId: PatientId, updatePatientPayload: UpdatePatientPayload, options?: any): AxiosPromise<PatientDetailsResponse> {
            return localVarFp.updatePatientPatientsPatientIdPatch(patientId, updatePatientPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Patient Provider
         * @param {PatientId} patientId 
         * @param {UpdatePatientProviderPayload} updatePatientProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientProviderPatientsPatientIdProviderPatch(patientId: PatientId, updatePatientProviderPayload: UpdatePatientProviderPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updatePatientProviderPatientsPatientIdProviderPatch(patientId, updatePatientProviderPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Release Of Information
         * @param {PatientId} patientId 
         * @param {ReleaseOfInformationPayload} releaseOfInformationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(patientId: PatientId, releaseOfInformationPayload: ReleaseOfInformationPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(patientId, releaseOfInformationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Patient Lab
         * @param {PatientId} patientId 
         * @param {UploadPatientLabPayload} uploadPatientLabPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPatientLabPatientPatientIdLabsPost(patientId: PatientId, uploadPatientLabPayload: UploadPatientLabPayload, options?: any): AxiosPromise<PatientLabResponse> {
            return localVarFp.uploadPatientLabPatientPatientIdLabsPost(patientId, uploadPatientLabPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientApi - object-oriented interface
 * @export
 * @class PatientApi
 * @extends {BaseAPI}
 */
export class PatientApi extends BaseAPI {
    /**
     * 
     * @summary Add Patient Lab Feedback
     * @param {string} labId 
     * @param {PatientId} patientId 
     * @param {AddPatientLabFeedbackPayload} addPatientLabFeedbackPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost(labId: string, patientId: PatientId, addPatientLabFeedbackPayload: AddPatientLabFeedbackPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost(labId, patientId, addPatientLabFeedbackPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Journal Entry
     * @param {CreateJournalEntryPayload} createJournalEntryPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public createJournalEntryPatientJournalEntriesPost(createJournalEntryPayload: CreateJournalEntryPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).createJournalEntryPatientJournalEntriesPost(createJournalEntryPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Journal Entry
     * @param {string} journalEntryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(journalEntryId: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(journalEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary External Facing Details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public externalFacingDetailsPatientExternalFacingDetailsGet(options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).externalFacingDetailsPatientExternalFacingDetailsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Journal Entry
     * @param {string} journalEntryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getJournalEntryPatientJournalEntriesJournalEntryIdGet(journalEntryId: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getJournalEntryPatientJournalEntriesJournalEntryIdGet(journalEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Journal Streak
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getJournalStreakPatientJournalStreakGet(options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getJournalStreakPatientJournalStreakGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient By Email
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientByEmailInternalPatientsEmailEmailGet(email: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientByEmailInternalPatientsEmailEmailGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Confirmation Status By Onboarding Code
     * @param {string} onboardingCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(onboardingCode: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(onboardingCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Confirmation Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientConfirmationStatusPatientConfirmGet(options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientConfirmationStatusPatientConfirmGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Details
     * @param {PatientId} patientId 
     * @param {Array<PatientDetailsInclusions>} [include] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientDetailsPatientsPatientIdGet(patientId: PatientId, include?: Array<PatientDetailsInclusions>, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientDetailsPatientsPatientIdGet(patientId, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Intake Form Answers
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(patientId: PatientId, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve paginated journal entries for the specified patient.  The entries are returned in descending order based on their creation date, with the most recent entries appearing first.  Args:     patient_id (PatientIdAllowingMe): The ID of the patient or \'me\' for the current user.     cursor (str | None): Pagination cursor for fetching the next set of results.     limit (int): Number of entries to return per page (default: 20, max: 100).  Returns:     CursorPaginatedJournalEntriesResponse: A paginated list of journal entries     and the next pagination cursor.
     * @summary Get Patient Journal Entries
     * @param {PatientId} patientId 
     * @param {string | null} [cursor] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientJournalEntriesPatientPatientIdJournalEntriesGet(patientId: PatientId, cursor?: string | null, limit?: number, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientJournalEntriesPatientPatientIdJournalEntriesGet(patientId, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Lab
     * @param {string} labId 
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientLabPatientPatientIdLabsLabIdGet(labId: string, patientId: PatientId, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientLabPatientPatientIdLabsLabIdGet(labId, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Labs
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientLabsPatientPatientIdLabsGet(patientId: PatientId, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientLabsPatientPatientIdLabsGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patients For Provider
     * @param {Array<PatientDetailsInclusions>} [include] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientsForProviderPatientsGet(include?: Array<PatientDetailsInclusions>, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientsForProviderPatientsGet(include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Release Form
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getReleaseFormPatientsPatientIdReleaseFormPdfGet(patientId: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getReleaseFormPatientsPatientIdReleaseFormPdfGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Release Form Template Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getReleaseFormTemplateUrlPatientsFilesReleaseFormTemplateUrlGet(options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getReleaseFormTemplateUrlPatientsFilesReleaseFormTemplateUrlGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Import patient and dependents from Healthie, creating models in the database.  Dependents include: - appointments - chat history  and more.
     * @summary Import Patient And Dependents
     * @param {HealthieImportPayload} healthieImportPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public importPatientAndDependentsAdminImportPatientPost(healthieImportPayload: HealthieImportPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).importPatientAndDependentsAdminImportPatientPost(healthieImportPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Internal Add Patient Utm Parameters
     * @param {string} patientId 
     * @param {PatientUTMParametersPayload} patientUTMParametersPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public internalAddPatientUtmParametersInternalPatientsPatientIdUtmParametersPost(patientId: string, patientUTMParametersPayload: PatientUTMParametersPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).internalAddPatientUtmParametersInternalPatientsPatientIdUtmParametersPost(patientId, patientUTMParametersPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Internal Register Patient
     * @param {InternalRegisterPatientPayload} internalRegisterPatientPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public internalRegisterPatientInternalPatientsPost(internalRegisterPatientPayload: InternalRegisterPatientPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).internalRegisterPatientInternalPatientsPost(internalRegisterPatientPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Internal Update Patient
     * @param {string} patientId 
     * @param {UpdatePatientPayload} updatePatientPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public internalUpdatePatientInternalPatientsPatientIdPatch(patientId: string, updatePatientPayload: UpdatePatientPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).internalUpdatePatientInternalPatientsPatientIdPatch(patientId, updatePatientPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Internal Update Patient Provider
     * @param {string} patientId 
     * @param {UpdatePatientProviderPayload} updatePatientProviderPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public internalUpdatePatientProviderInternalPatientsPatientIdProviderPatch(patientId: string, updatePatientProviderPayload: UpdatePatientProviderPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).internalUpdatePatientProviderInternalPatientsPatientIdProviderPatch(patientId, updatePatientProviderPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patient Confirmation By Code
     * @param {PatientConfirmationByCodePayload} patientConfirmationByCodePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientConfirmationByCodePatientConfirmCodePost(patientConfirmationByCodePayload: PatientConfirmationByCodePayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).patientConfirmationByCodePatientConfirmCodePost(patientConfirmationByCodePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patient Confirmation
     * @param {PatientConfirmationPayload} patientConfirmationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientConfirmationPatientConfirmPost(patientConfirmationPayload: PatientConfirmationPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).patientConfirmationPatientConfirmPost(patientConfirmationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register Patient
     * @param {RegisterPatientPayload} registerPatientPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public registerPatientAdminPatientsPost(registerPatientPayload: RegisterPatientPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).registerPatientAdminPatientsPost(registerPatientPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Patient Intake Form
     * @param {PatientId} patientId 
     * @param {UpdateIntakePayload} updateIntakePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public setPatientIntakeFormPatientsPatientIdIntakePost(patientId: PatientId, updateIntakePayload: UpdateIntakePayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).setPatientIntakeFormPatientsPatientIdIntakePost(patientId, updateIntakePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit Booking Email
     * @param {PatientBookingSubmitEmailPayload} patientBookingSubmitEmailPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public submitBookingEmailInternalPatientsBookingSubmitEmailPost(patientBookingSubmitEmailPayload: PatientBookingSubmitEmailPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).submitBookingEmailInternalPatientsBookingSubmitEmailPost(patientBookingSubmitEmailPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Journal Entry
     * @param {string} journalEntryId 
     * @param {UpdateJournalEntryPayload} updateJournalEntryPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(journalEntryId: string, updateJournalEntryPayload: UpdateJournalEntryPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(journalEntryId, updateJournalEntryPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Patient
     * @param {PatientId} patientId 
     * @param {UpdatePatientPayload} updatePatientPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public updatePatientPatientsPatientIdPatch(patientId: PatientId, updatePatientPayload: UpdatePatientPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).updatePatientPatientsPatientIdPatch(patientId, updatePatientPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Patient Provider
     * @param {PatientId} patientId 
     * @param {UpdatePatientProviderPayload} updatePatientProviderPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public updatePatientProviderPatientsPatientIdProviderPatch(patientId: PatientId, updatePatientProviderPayload: UpdatePatientProviderPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).updatePatientProviderPatientsPatientIdProviderPatch(patientId, updatePatientProviderPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Release Of Information
     * @param {PatientId} patientId 
     * @param {ReleaseOfInformationPayload} releaseOfInformationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(patientId: PatientId, releaseOfInformationPayload: ReleaseOfInformationPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(patientId, releaseOfInformationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Patient Lab
     * @param {PatientId} patientId 
     * @param {UploadPatientLabPayload} uploadPatientLabPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public uploadPatientLabPatientPatientIdLabsPost(patientId: PatientId, uploadPatientLabPayload: UploadPatientLabPayload, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).uploadPatientLabPatientPatientIdLabsPost(patientId, uploadPatientLabPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PatientBillingApi - axios parameter creator
 * @export
 */
export const PatientBillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Patient Payment Instrument
         * @param {PatientId} patientId 
         * @param {CreatePaymentInstrumentPayload} createPaymentInstrumentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatientPaymentInstrumentPatientsPatientIdPaymentPost: async (patientId: PatientId, createPaymentInstrumentPayload: CreatePaymentInstrumentPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('createPatientPaymentInstrumentPatientsPatientIdPaymentPost', 'patientId', patientId)
            // verify required parameter 'createPaymentInstrumentPayload' is not null or undefined
            assertParamExists('createPatientPaymentInstrumentPatientsPatientIdPaymentPost', 'createPaymentInstrumentPayload', createPaymentInstrumentPayload)
            const localVarPath = `/patients/{patient_id}/payment`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentInstrumentPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Patient Payment Instrument
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientPaymentInstrumentPatientsPatientIdPaymentGet: async (patientId: PatientId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientPaymentInstrumentPatientsPatientIdPaymentGet', 'patientId', patientId)
            const localVarPath = `/patients/{patient_id}/payment`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Internal Create Patient Payment Instrument
         * @param {string} patientId 
         * @param {CreatePaymentInstrumentPayload} createPaymentInstrumentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreatePatientPaymentInstrumentInternalPatientsPatientIdPaymentPost: async (patientId: string, createPaymentInstrumentPayload: CreatePaymentInstrumentPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('internalCreatePatientPaymentInstrumentInternalPatientsPatientIdPaymentPost', 'patientId', patientId)
            // verify required parameter 'createPaymentInstrumentPayload' is not null or undefined
            assertParamExists('internalCreatePatientPaymentInstrumentInternalPatientsPatientIdPaymentPost', 'createPaymentInstrumentPayload', createPaymentInstrumentPayload)
            const localVarPath = `/internal/patients/{patient_id}/payment`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentInstrumentPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Partner Booking Verify Payment Method
         * @param {PartnerBookingVerifyPaymentMethodPayload} partnerBookingVerifyPaymentMethodPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost: async (partnerBookingVerifyPaymentMethodPayload: PartnerBookingVerifyPaymentMethodPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerBookingVerifyPaymentMethodPayload' is not null or undefined
            assertParamExists('partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost', 'partnerBookingVerifyPaymentMethodPayload', partnerBookingVerifyPaymentMethodPayload)
            const localVarPath = `/partners/verify-payment-method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerBookingVerifyPaymentMethodPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify Payment Method
         * @param {VerifyPaymentMethodPayload} verifyPaymentMethodPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPaymentMethodBookingVerifyPaymentMethodPost: async (verifyPaymentMethodPayload: VerifyPaymentMethodPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyPaymentMethodPayload' is not null or undefined
            assertParamExists('verifyPaymentMethodBookingVerifyPaymentMethodPost', 'verifyPaymentMethodPayload', verifyPaymentMethodPayload)
            const localVarPath = `/booking/verify-payment-method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyPaymentMethodPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientBillingApi - functional programming interface
 * @export
 */
export const PatientBillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientBillingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Patient Payment Instrument
         * @param {PatientId} patientId 
         * @param {CreatePaymentInstrumentPayload} createPaymentInstrumentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPatientPaymentInstrumentPatientsPatientIdPaymentPost(patientId: PatientId, createPaymentInstrumentPayload: CreatePaymentInstrumentPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInstrumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPatientPaymentInstrumentPatientsPatientIdPaymentPost(patientId, createPaymentInstrumentPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientBillingApi.createPatientPaymentInstrumentPatientsPatientIdPaymentPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Patient Payment Instrument
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientPaymentInstrumentPatientsPatientIdPaymentGet(patientId: PatientId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInstrumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientPaymentInstrumentPatientsPatientIdPaymentGet(patientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientBillingApi.getPatientPaymentInstrumentPatientsPatientIdPaymentGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Internal Create Patient Payment Instrument
         * @param {string} patientId 
         * @param {CreatePaymentInstrumentPayload} createPaymentInstrumentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreatePatientPaymentInstrumentInternalPatientsPatientIdPaymentPost(patientId: string, createPaymentInstrumentPayload: CreatePaymentInstrumentPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInstrumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreatePatientPaymentInstrumentInternalPatientsPatientIdPaymentPost(patientId, createPaymentInstrumentPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientBillingApi.internalCreatePatientPaymentInstrumentInternalPatientsPatientIdPaymentPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Partner Booking Verify Payment Method
         * @param {PartnerBookingVerifyPaymentMethodPayload} partnerBookingVerifyPaymentMethodPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost(partnerBookingVerifyPaymentMethodPayload: PartnerBookingVerifyPaymentMethodPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyPaymentMethodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost(partnerBookingVerifyPaymentMethodPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientBillingApi.partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Verify Payment Method
         * @param {VerifyPaymentMethodPayload} verifyPaymentMethodPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyPaymentMethodBookingVerifyPaymentMethodPost(verifyPaymentMethodPayload: VerifyPaymentMethodPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyPaymentMethodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyPaymentMethodBookingVerifyPaymentMethodPost(verifyPaymentMethodPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientBillingApi.verifyPaymentMethodBookingVerifyPaymentMethodPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PatientBillingApi - factory interface
 * @export
 */
export const PatientBillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientBillingApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Patient Payment Instrument
         * @param {PatientId} patientId 
         * @param {CreatePaymentInstrumentPayload} createPaymentInstrumentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatientPaymentInstrumentPatientsPatientIdPaymentPost(patientId: PatientId, createPaymentInstrumentPayload: CreatePaymentInstrumentPayload, options?: any): AxiosPromise<PaymentInstrumentResponse> {
            return localVarFp.createPatientPaymentInstrumentPatientsPatientIdPaymentPost(patientId, createPaymentInstrumentPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Patient Payment Instrument
         * @param {PatientId} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientPaymentInstrumentPatientsPatientIdPaymentGet(patientId: PatientId, options?: any): AxiosPromise<PaymentInstrumentResponse> {
            return localVarFp.getPatientPaymentInstrumentPatientsPatientIdPaymentGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Internal Create Patient Payment Instrument
         * @param {string} patientId 
         * @param {CreatePaymentInstrumentPayload} createPaymentInstrumentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreatePatientPaymentInstrumentInternalPatientsPatientIdPaymentPost(patientId: string, createPaymentInstrumentPayload: CreatePaymentInstrumentPayload, options?: any): AxiosPromise<PaymentInstrumentResponse> {
            return localVarFp.internalCreatePatientPaymentInstrumentInternalPatientsPatientIdPaymentPost(patientId, createPaymentInstrumentPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Partner Booking Verify Payment Method
         * @param {PartnerBookingVerifyPaymentMethodPayload} partnerBookingVerifyPaymentMethodPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost(partnerBookingVerifyPaymentMethodPayload: PartnerBookingVerifyPaymentMethodPayload, options?: any): AxiosPromise<VerifyPaymentMethodResponse> {
            return localVarFp.partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost(partnerBookingVerifyPaymentMethodPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify Payment Method
         * @param {VerifyPaymentMethodPayload} verifyPaymentMethodPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPaymentMethodBookingVerifyPaymentMethodPost(verifyPaymentMethodPayload: VerifyPaymentMethodPayload, options?: any): AxiosPromise<VerifyPaymentMethodResponse> {
            return localVarFp.verifyPaymentMethodBookingVerifyPaymentMethodPost(verifyPaymentMethodPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientBillingApi - object-oriented interface
 * @export
 * @class PatientBillingApi
 * @extends {BaseAPI}
 */
export class PatientBillingApi extends BaseAPI {
    /**
     * 
     * @summary Create Patient Payment Instrument
     * @param {PatientId} patientId 
     * @param {CreatePaymentInstrumentPayload} createPaymentInstrumentPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientBillingApi
     */
    public createPatientPaymentInstrumentPatientsPatientIdPaymentPost(patientId: PatientId, createPaymentInstrumentPayload: CreatePaymentInstrumentPayload, options?: AxiosRequestConfig) {
        return PatientBillingApiFp(this.configuration).createPatientPaymentInstrumentPatientsPatientIdPaymentPost(patientId, createPaymentInstrumentPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Patient Payment Instrument
     * @param {PatientId} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientBillingApi
     */
    public getPatientPaymentInstrumentPatientsPatientIdPaymentGet(patientId: PatientId, options?: AxiosRequestConfig) {
        return PatientBillingApiFp(this.configuration).getPatientPaymentInstrumentPatientsPatientIdPaymentGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Internal Create Patient Payment Instrument
     * @param {string} patientId 
     * @param {CreatePaymentInstrumentPayload} createPaymentInstrumentPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientBillingApi
     */
    public internalCreatePatientPaymentInstrumentInternalPatientsPatientIdPaymentPost(patientId: string, createPaymentInstrumentPayload: CreatePaymentInstrumentPayload, options?: AxiosRequestConfig) {
        return PatientBillingApiFp(this.configuration).internalCreatePatientPaymentInstrumentInternalPatientsPatientIdPaymentPost(patientId, createPaymentInstrumentPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Partner Booking Verify Payment Method
     * @param {PartnerBookingVerifyPaymentMethodPayload} partnerBookingVerifyPaymentMethodPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientBillingApi
     */
    public partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost(partnerBookingVerifyPaymentMethodPayload: PartnerBookingVerifyPaymentMethodPayload, options?: AxiosRequestConfig) {
        return PatientBillingApiFp(this.configuration).partnerBookingVerifyPaymentMethodPartnersVerifyPaymentMethodPost(partnerBookingVerifyPaymentMethodPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify Payment Method
     * @param {VerifyPaymentMethodPayload} verifyPaymentMethodPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientBillingApi
     */
    public verifyPaymentMethodBookingVerifyPaymentMethodPost(verifyPaymentMethodPayload: VerifyPaymentMethodPayload, options?: AxiosRequestConfig) {
        return PatientBillingApiFp(this.configuration).verifyPaymentMethodBookingVerifyPaymentMethodPost(verifyPaymentMethodPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PatientRecapApi - axios parameter creator
 * @export
 */
export const PatientRecapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Patient Recap
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientRecapPatientRecapPatientIdGet: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientRecapPatientRecapPatientIdGet', 'patientId', patientId)
            const localVarPath = `/patient-recap/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientRecapApi - functional programming interface
 * @export
 */
export const PatientRecapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientRecapApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Patient Recap
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientRecapPatientRecapPatientIdGet(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientRecapResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientRecapPatientRecapPatientIdGet(patientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PatientRecapApi.getPatientRecapPatientRecapPatientIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PatientRecapApi - factory interface
 * @export
 */
export const PatientRecapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientRecapApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Patient Recap
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientRecapPatientRecapPatientIdGet(patientId: string, options?: any): AxiosPromise<PatientRecapResponse> {
            return localVarFp.getPatientRecapPatientRecapPatientIdGet(patientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientRecapApi - object-oriented interface
 * @export
 * @class PatientRecapApi
 * @extends {BaseAPI}
 */
export class PatientRecapApi extends BaseAPI {
    /**
     * 
     * @summary Get Patient Recap
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientRecapApi
     */
    public getPatientRecapPatientRecapPatientIdGet(patientId: string, options?: AxiosRequestConfig) {
        return PatientRecapApiFp(this.configuration).getPatientRecapPatientRecapPatientIdGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PayoutApi - axios parameter creator
 * @export
 */
export const PayoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Payouts For Provider
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayoutsForProviderPayoutsGet: async (providerId: ProviderId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getPayoutsForProviderPayoutsGet', 'providerId', providerId)
            const localVarPath = `/payouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (providerId !== undefined) {
                for (const [key, value] of Object.entries(providerId)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PayoutApi - functional programming interface
 * @export
 */
export const PayoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PayoutApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Payouts For Provider
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayoutsForProviderPayoutsGet(providerId: ProviderId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayoutsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayoutsForProviderPayoutsGet(providerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PayoutApi.getPayoutsForProviderPayoutsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PayoutApi - factory interface
 * @export
 */
export const PayoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PayoutApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Payouts For Provider
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayoutsForProviderPayoutsGet(providerId: ProviderId, options?: any): AxiosPromise<PayoutsListResponse> {
            return localVarFp.getPayoutsForProviderPayoutsGet(providerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PayoutApi - object-oriented interface
 * @export
 * @class PayoutApi
 * @extends {BaseAPI}
 */
export class PayoutApi extends BaseAPI {
    /**
     * 
     * @summary Get Payouts For Provider
     * @param {ProviderId} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApi
     */
    public getPayoutsForProviderPayoutsGet(providerId: ProviderId, options?: AxiosRequestConfig) {
        return PayoutApiFp(this.configuration).getPayoutsForProviderPayoutsGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProviderApi - axios parameter creator
 * @export
 */
export const ProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Admin Trigger Provider Healthie Import Validation
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTriggerProviderHealthieImportValidationAdminProvidersHealthieProviderIdValidatePost: async (healthieProviderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthieProviderId' is not null or undefined
            assertParamExists('adminTriggerProviderHealthieImportValidationAdminProvidersHealthieProviderIdValidatePost', 'healthieProviderId', healthieProviderId)
            const localVarPath = `/admin/providers/{healthie_provider_id}/validate`
                .replace(`{${"healthie_provider_id"}}`, encodeURIComponent(String(healthieProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary All Healthie Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allHealthieProvidersAdminHealthieProvidersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/healthie-providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary All Onboardable Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allOnboardableProvidersAdminOnboardableProvidersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/onboardable-providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm Provider Migration Admin
         * @param {ProviderBatchActionPayload} providerBatchActionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmProviderMigrationAdminAdminProvidersConfirmImportPost: async (providerBatchActionPayload: ProviderBatchActionPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerBatchActionPayload' is not null or undefined
            assertParamExists('confirmProviderMigrationAdminAdminProvidersConfirmImportPost', 'providerBatchActionPayload', providerBatchActionPayload)
            const localVarPath = `/admin/providers/confirm-import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerBatchActionPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate Provider Healthie Admin
         * @param {ProviderBatchActionPayload} providerBatchActionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateProviderHealthieAdminAdminProvidersDeactivateHealthiePost: async (providerBatchActionPayload: ProviderBatchActionPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerBatchActionPayload' is not null or undefined
            assertParamExists('deactivateProviderHealthieAdminAdminProvidersDeactivateHealthiePost', 'providerBatchActionPayload', providerBatchActionPayload)
            const localVarPath = `/admin/providers/deactivate-healthie`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerBatchActionPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Google Calendar Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGoogleCalendarOauthProvidersMeGoogleDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers/me/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Google Calendar Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleCalendarOauthProvidersMeGoogleGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers/me/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Healthie Provider
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthieProviderAdminHealthieProvidersHealthieProviderIdGet: async (healthieProviderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthieProviderId' is not null or undefined
            assertParamExists('getHealthieProviderAdminHealthieProvidersHealthieProviderIdGet', 'healthieProviderId', healthieProviderId)
            const localVarPath = `/admin/healthie-providers/{healthie_provider_id}`
                .replace(`{${"healthie_provider_id"}}`, encodeURIComponent(String(healthieProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider By Healthie Id
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderByHealthieIdInternalHealthieProvidersHealthieProviderIdGet: async (healthieProviderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthieProviderId' is not null or undefined
            assertParamExists('getProviderByHealthieIdInternalHealthieProvidersHealthieProviderIdGet', 'healthieProviderId', healthieProviderId)
            const localVarPath = `/internal/healthie-providers/{healthie_provider_id}`
                .replace(`{${"healthie_provider_id"}}`, encodeURIComponent(String(healthieProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Confirmation Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderConfirmationStatusProviderConfirmGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/provider/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Details Internal
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderDetailsInternalInternalProvidersProviderIdGet: async (providerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderDetailsInternalInternalProvidersProviderIdGet', 'providerId', providerId)
            const localVarPath = `/internal/providers/{provider_id}`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Details
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderDetailsProvidersProviderIdGet: async (providerId: ProviderId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderDetailsProvidersProviderIdGet', 'providerId', providerId)
            const localVarPath = `/providers/{provider_id}`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Healthie Active Status
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderHealthieActiveStatusAdminProvidersProviderIdHealthieDeactivatedStatusGet: async (providerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderHealthieActiveStatusAdminProvidersProviderIdHealthieDeactivatedStatusGet', 'providerId', providerId)
            const localVarPath = `/admin/providers/{provider_id}/healthie-deactivated-status`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Identity
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderIdentityProvidersProviderIdIdentityGet: async (providerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderIdentityProvidersProviderIdIdentityGet', 'providerId', providerId)
            const localVarPath = `/providers/{provider_id}/identity`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider Practice Metrics
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet: async (providerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet', 'providerId', providerId)
            const localVarPath = `/providers/{provider_id}/practice-metrics`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Providers Admin
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersAdminAdminProvidersGet: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Providers For Patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersForPatientProvidersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Import provider and dependents from Healthie, creating models in the database.  Dependents include: - patients - appointments - availability schedules  and more.
         * @summary Import Providers And Dependents
         * @param {ImportProvidersPayload} importProvidersPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProvidersAndDependentsAdminProvidersImportPost: async (importProvidersPayload: ImportProvidersPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importProvidersPayload' is not null or undefined
            assertParamExists('importProvidersAndDependentsAdminProvidersImportPost', 'importProvidersPayload', importProvidersPayload)
            const localVarPath = `/admin/providers/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importProvidersPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Offboard Provider
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offboardProviderAdminProvidersProviderIdOffboardPost: async (providerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('offboardProviderAdminProvidersProviderIdOffboardPost', 'providerId', providerId)
            const localVarPath = `/admin/providers/{provider_id}/offboard`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provider Confirmation
         * @param {ProviderConfirmationPayload} providerConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerConfirmationProviderConfirmPost: async (providerConfirmationPayload: ProviderConfirmationPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerConfirmationPayload' is not null or undefined
            assertParamExists('providerConfirmationProviderConfirmPost', 'providerConfirmationPayload', providerConfirmationPayload)
            const localVarPath = `/provider/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerConfirmationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register Provider
         * @param {RegisterProviderPayload} registerProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerProviderProvidersPost: async (registerProviderPayload: RegisterProviderPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerProviderPayload' is not null or undefined
            assertParamExists('registerProviderProvidersPost', 'registerProviderPayload', registerProviderPayload)
            const localVarPath = `/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerProviderPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Provider Password
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProviderPasswordProvidersProviderIdPasswordResetPost: async (providerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('resetProviderPasswordProvidersProviderIdPasswordResetPost', 'providerId', providerId)
            const localVarPath = `/providers/{provider_id}/password/reset`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Google Calendar Oauth
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGoogleCalendarOauthProvidersMeGooglePost: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('setGoogleCalendarOauthProvidersMeGooglePost', 'code', code)
            const localVarPath = `/providers/me/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Target Migration Date
         * @param {SetTargetMigrationDatePayload} setTargetMigrationDatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTargetMigrationDateAdminProvidersTargetMigrationDatePut: async (setTargetMigrationDatePayload: SetTargetMigrationDatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setTargetMigrationDatePayload' is not null or undefined
            assertParamExists('setTargetMigrationDateAdminProvidersTargetMigrationDatePut', 'setTargetMigrationDatePayload', setTargetMigrationDatePayload)
            const localVarPath = `/admin/providers/target-migration-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setTargetMigrationDatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Provider
         * @param {ProviderId} providerId 
         * @param {UpdateProviderPayload} updateProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProviderProvidersProviderIdPatch: async (providerId: ProviderId, updateProviderPayload: UpdateProviderPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('updateProviderProvidersProviderIdPatch', 'providerId', providerId)
            // verify required parameter 'updateProviderPayload' is not null or undefined
            assertParamExists('updateProviderProvidersProviderIdPatch', 'updateProviderPayload', updateProviderPayload)
            const localVarPath = `/providers/{provider_id}`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderApi - functional programming interface
 * @export
 */
export const ProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Admin Trigger Provider Healthie Import Validation
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTriggerProviderHealthieImportValidationAdminProvidersHealthieProviderIdValidatePost(healthieProviderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTriggerProviderHealthieImportValidationAdminProvidersHealthieProviderIdValidatePost(healthieProviderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.adminTriggerProviderHealthieImportValidationAdminProvidersHealthieProviderIdValidatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary All Healthie Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allHealthieProvidersAdminHealthieProvidersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HealthieProviderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allHealthieProvidersAdminHealthieProvidersGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.allHealthieProvidersAdminHealthieProvidersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary All Onboardable Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allOnboardableProvidersAdminOnboardableProvidersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OnboardableProviderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allOnboardableProvidersAdminOnboardableProvidersGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.allOnboardableProvidersAdminOnboardableProvidersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Confirm Provider Migration Admin
         * @param {ProviderBatchActionPayload} providerBatchActionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmProviderMigrationAdminAdminProvidersConfirmImportPost(providerBatchActionPayload: ProviderBatchActionPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderBatchActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmProviderMigrationAdminAdminProvidersConfirmImportPost(providerBatchActionPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.confirmProviderMigrationAdminAdminProvidersConfirmImportPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deactivate Provider Healthie Admin
         * @param {ProviderBatchActionPayload} providerBatchActionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateProviderHealthieAdminAdminProvidersDeactivateHealthiePost(providerBatchActionPayload: ProviderBatchActionPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderBatchActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateProviderHealthieAdminAdminProvidersDeactivateHealthiePost(providerBatchActionPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.deactivateProviderHealthieAdminAdminProvidersDeactivateHealthiePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Google Calendar Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGoogleCalendarOauthProvidersMeGoogleDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGoogleCalendarOauthProvidersMeGoogleDelete(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.deleteGoogleCalendarOauthProvidersMeGoogleDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Google Calendar Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGoogleCalendarOauthProvidersMeGoogleGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGoogleCalendarOauthProvidersMeGoogleGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.getGoogleCalendarOauthProvidersMeGoogleGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Healthie Provider
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthieProviderAdminHealthieProvidersHealthieProviderIdGet(healthieProviderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthieProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthieProviderAdminHealthieProvidersHealthieProviderIdGet(healthieProviderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.getHealthieProviderAdminHealthieProvidersHealthieProviderIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider By Healthie Id
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderByHealthieIdInternalHealthieProvidersHealthieProviderIdGet(healthieProviderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderByHealthieIdInternalHealthieProvidersHealthieProviderIdGet(healthieProviderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.getProviderByHealthieIdInternalHealthieProvidersHealthieProviderIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Confirmation Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderConfirmationStatusProviderConfirmGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderConfirmationPreflightResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderConfirmationStatusProviderConfirmGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.getProviderConfirmationStatusProviderConfirmGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Details Internal
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderDetailsInternalInternalProvidersProviderIdGet(providerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderDetailsInternalInternalProvidersProviderIdGet(providerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.getProviderDetailsInternalInternalProvidersProviderIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Details
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderDetailsProvidersProviderIdGet(providerId: ProviderId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderDetailsProvidersProviderIdGet(providerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.getProviderDetailsProvidersProviderIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Healthie Active Status
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderHealthieActiveStatusAdminProvidersProviderIdHealthieDeactivatedStatusGet(providerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderHealthieActiveStatusAdminProvidersProviderIdHealthieDeactivatedStatusGet(providerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.getProviderHealthieActiveStatusAdminProvidersProviderIdHealthieDeactivatedStatusGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Identity
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderIdentityProvidersProviderIdIdentityGet(providerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderIdentityDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderIdentityProvidersProviderIdIdentityGet(providerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.getProviderIdentityProvidersProviderIdIdentityGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider Practice Metrics
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(providerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(providerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Providers Admin
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvidersAdminAdminProvidersGet(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderDetailsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvidersAdminAdminProvidersGet(limit, offset, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.getProvidersAdminAdminProvidersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Providers For Patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvidersForPatientProvidersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderForPatientDetailsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvidersForPatientProvidersGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.getProvidersForPatientProvidersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Import provider and dependents from Healthie, creating models in the database.  Dependents include: - patients - appointments - availability schedules  and more.
         * @summary Import Providers And Dependents
         * @param {ImportProvidersPayload} importProvidersPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importProvidersAndDependentsAdminProvidersImportPost(importProvidersPayload: ImportProvidersPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportProvidersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importProvidersAndDependentsAdminProvidersImportPost(importProvidersPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.importProvidersAndDependentsAdminProvidersImportPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Offboard Provider
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offboardProviderAdminProvidersProviderIdOffboardPost(providerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OffboardStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offboardProviderAdminProvidersProviderIdOffboardPost(providerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.offboardProviderAdminProvidersProviderIdOffboardPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Provider Confirmation
         * @param {ProviderConfirmationPayload} providerConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerConfirmationProviderConfirmPost(providerConfirmationPayload: ProviderConfirmationPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerConfirmationProviderConfirmPost(providerConfirmationPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.providerConfirmationProviderConfirmPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Register Provider
         * @param {RegisterProviderPayload} registerProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerProviderProvidersPost(registerProviderPayload: RegisterProviderPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerProviderProvidersPost(registerProviderPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.registerProviderProvidersPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Reset Provider Password
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetProviderPasswordProvidersProviderIdPasswordResetPost(providerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetProviderPasswordProvidersProviderIdPasswordResetPost(providerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.resetProviderPasswordProvidersProviderIdPasswordResetPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Set Google Calendar Oauth
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setGoogleCalendarOauthProvidersMeGooglePost(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setGoogleCalendarOauthProvidersMeGooglePost(code, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.setGoogleCalendarOauthProvidersMeGooglePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Set Target Migration Date
         * @param {SetTargetMigrationDatePayload} setTargetMigrationDatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTargetMigrationDateAdminProvidersTargetMigrationDatePut(setTargetMigrationDatePayload: SetTargetMigrationDatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderBatchActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTargetMigrationDateAdminProvidersTargetMigrationDatePut(setTargetMigrationDatePayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.setTargetMigrationDateAdminProvidersTargetMigrationDatePut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update Provider
         * @param {ProviderId} providerId 
         * @param {UpdateProviderPayload} updateProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProviderProvidersProviderIdPatch(providerId: ProviderId, updateProviderPayload: UpdateProviderPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProviderProvidersProviderIdPatch(providerId, updateProviderPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.updateProviderProvidersProviderIdPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProviderApi - factory interface
 * @export
 */
export const ProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderApiFp(configuration)
    return {
        /**
         * 
         * @summary Admin Trigger Provider Healthie Import Validation
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTriggerProviderHealthieImportValidationAdminProvidersHealthieProviderIdValidatePost(healthieProviderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminTriggerProviderHealthieImportValidationAdminProvidersHealthieProviderIdValidatePost(healthieProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary All Healthie Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allHealthieProvidersAdminHealthieProvidersGet(options?: any): AxiosPromise<Array<HealthieProviderResponse>> {
            return localVarFp.allHealthieProvidersAdminHealthieProvidersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary All Onboardable Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allOnboardableProvidersAdminOnboardableProvidersGet(options?: any): AxiosPromise<Array<OnboardableProviderResponse>> {
            return localVarFp.allOnboardableProvidersAdminOnboardableProvidersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm Provider Migration Admin
         * @param {ProviderBatchActionPayload} providerBatchActionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmProviderMigrationAdminAdminProvidersConfirmImportPost(providerBatchActionPayload: ProviderBatchActionPayload, options?: any): AxiosPromise<ProviderBatchActionResponse> {
            return localVarFp.confirmProviderMigrationAdminAdminProvidersConfirmImportPost(providerBatchActionPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate Provider Healthie Admin
         * @param {ProviderBatchActionPayload} providerBatchActionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateProviderHealthieAdminAdminProvidersDeactivateHealthiePost(providerBatchActionPayload: ProviderBatchActionPayload, options?: any): AxiosPromise<ProviderBatchActionResponse> {
            return localVarFp.deactivateProviderHealthieAdminAdminProvidersDeactivateHealthiePost(providerBatchActionPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Google Calendar Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGoogleCalendarOauthProvidersMeGoogleDelete(options?: any): AxiosPromise<OAuthResponse> {
            return localVarFp.deleteGoogleCalendarOauthProvidersMeGoogleDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Google Calendar Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleCalendarOauthProvidersMeGoogleGet(options?: any): AxiosPromise<OAuthResponse> {
            return localVarFp.getGoogleCalendarOauthProvidersMeGoogleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Healthie Provider
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthieProviderAdminHealthieProvidersHealthieProviderIdGet(healthieProviderId: string, options?: any): AxiosPromise<HealthieProviderResponse> {
            return localVarFp.getHealthieProviderAdminHealthieProvidersHealthieProviderIdGet(healthieProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider By Healthie Id
         * @param {string} healthieProviderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderByHealthieIdInternalHealthieProvidersHealthieProviderIdGet(healthieProviderId: string, options?: any): AxiosPromise<ProviderDetailsResponse> {
            return localVarFp.getProviderByHealthieIdInternalHealthieProvidersHealthieProviderIdGet(healthieProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Confirmation Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderConfirmationStatusProviderConfirmGet(options?: any): AxiosPromise<ProviderConfirmationPreflightResponse> {
            return localVarFp.getProviderConfirmationStatusProviderConfirmGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Details Internal
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderDetailsInternalInternalProvidersProviderIdGet(providerId: string, options?: any): AxiosPromise<ProviderDetailsResponse> {
            return localVarFp.getProviderDetailsInternalInternalProvidersProviderIdGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Details
         * @param {ProviderId} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderDetailsProvidersProviderIdGet(providerId: ProviderId, options?: any): AxiosPromise<ProviderDetailsResponse> {
            return localVarFp.getProviderDetailsProvidersProviderIdGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Healthie Active Status
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderHealthieActiveStatusAdminProvidersProviderIdHealthieDeactivatedStatusGet(providerId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.getProviderHealthieActiveStatusAdminProvidersProviderIdHealthieDeactivatedStatusGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Identity
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderIdentityProvidersProviderIdIdentityGet(providerId: string, options?: any): AxiosPromise<ProviderIdentityDetailsResponse> {
            return localVarFp.getProviderIdentityProvidersProviderIdIdentityGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider Practice Metrics
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(providerId: string, options?: any): AxiosPromise<PracticeMetricsResponse> {
            return localVarFp.getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Providers Admin
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersAdminAdminProvidersGet(limit?: number, offset?: number, options?: any): AxiosPromise<Array<ProviderDetailsResponse>> {
            return localVarFp.getProvidersAdminAdminProvidersGet(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Providers For Patient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersForPatientProvidersGet(options?: any): AxiosPromise<Array<ProviderForPatientDetailsResponse>> {
            return localVarFp.getProvidersForPatientProvidersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Import provider and dependents from Healthie, creating models in the database.  Dependents include: - patients - appointments - availability schedules  and more.
         * @summary Import Providers And Dependents
         * @param {ImportProvidersPayload} importProvidersPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProvidersAndDependentsAdminProvidersImportPost(importProvidersPayload: ImportProvidersPayload, options?: any): AxiosPromise<ImportProvidersResponse> {
            return localVarFp.importProvidersAndDependentsAdminProvidersImportPost(importProvidersPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Offboard Provider
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offboardProviderAdminProvidersProviderIdOffboardPost(providerId: string, options?: any): AxiosPromise<OffboardStatusResponse> {
            return localVarFp.offboardProviderAdminProvidersProviderIdOffboardPost(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provider Confirmation
         * @param {ProviderConfirmationPayload} providerConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerConfirmationProviderConfirmPost(providerConfirmationPayload: ProviderConfirmationPayload, options?: any): AxiosPromise<void> {
            return localVarFp.providerConfirmationProviderConfirmPost(providerConfirmationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register Provider
         * @param {RegisterProviderPayload} registerProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerProviderProvidersPost(registerProviderPayload: RegisterProviderPayload, options?: any): AxiosPromise<ProviderDetailsResponse> {
            return localVarFp.registerProviderProvidersPost(registerProviderPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Provider Password
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProviderPasswordProvidersProviderIdPasswordResetPost(providerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resetProviderPasswordProvidersProviderIdPasswordResetPost(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Google Calendar Oauth
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGoogleCalendarOauthProvidersMeGooglePost(code: string, options?: any): AxiosPromise<void> {
            return localVarFp.setGoogleCalendarOauthProvidersMeGooglePost(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Target Migration Date
         * @param {SetTargetMigrationDatePayload} setTargetMigrationDatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTargetMigrationDateAdminProvidersTargetMigrationDatePut(setTargetMigrationDatePayload: SetTargetMigrationDatePayload, options?: any): AxiosPromise<ProviderBatchActionResponse> {
            return localVarFp.setTargetMigrationDateAdminProvidersTargetMigrationDatePut(setTargetMigrationDatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Provider
         * @param {ProviderId} providerId 
         * @param {UpdateProviderPayload} updateProviderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProviderProvidersProviderIdPatch(providerId: ProviderId, updateProviderPayload: UpdateProviderPayload, options?: any): AxiosPromise<ProviderDetailsResponse> {
            return localVarFp.updateProviderProvidersProviderIdPatch(providerId, updateProviderPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderApi - object-oriented interface
 * @export
 * @class ProviderApi
 * @extends {BaseAPI}
 */
export class ProviderApi extends BaseAPI {
    /**
     * 
     * @summary Admin Trigger Provider Healthie Import Validation
     * @param {string} healthieProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public adminTriggerProviderHealthieImportValidationAdminProvidersHealthieProviderIdValidatePost(healthieProviderId: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).adminTriggerProviderHealthieImportValidationAdminProvidersHealthieProviderIdValidatePost(healthieProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary All Healthie Providers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public allHealthieProvidersAdminHealthieProvidersGet(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).allHealthieProvidersAdminHealthieProvidersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary All Onboardable Providers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public allOnboardableProvidersAdminOnboardableProvidersGet(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).allOnboardableProvidersAdminOnboardableProvidersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm Provider Migration Admin
     * @param {ProviderBatchActionPayload} providerBatchActionPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public confirmProviderMigrationAdminAdminProvidersConfirmImportPost(providerBatchActionPayload: ProviderBatchActionPayload, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).confirmProviderMigrationAdminAdminProvidersConfirmImportPost(providerBatchActionPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate Provider Healthie Admin
     * @param {ProviderBatchActionPayload} providerBatchActionPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public deactivateProviderHealthieAdminAdminProvidersDeactivateHealthiePost(providerBatchActionPayload: ProviderBatchActionPayload, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).deactivateProviderHealthieAdminAdminProvidersDeactivateHealthiePost(providerBatchActionPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Google Calendar Oauth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public deleteGoogleCalendarOauthProvidersMeGoogleDelete(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).deleteGoogleCalendarOauthProvidersMeGoogleDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Google Calendar Oauth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getGoogleCalendarOauthProvidersMeGoogleGet(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getGoogleCalendarOauthProvidersMeGoogleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Healthie Provider
     * @param {string} healthieProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getHealthieProviderAdminHealthieProvidersHealthieProviderIdGet(healthieProviderId: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getHealthieProviderAdminHealthieProvidersHealthieProviderIdGet(healthieProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider By Healthie Id
     * @param {string} healthieProviderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderByHealthieIdInternalHealthieProvidersHealthieProviderIdGet(healthieProviderId: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderByHealthieIdInternalHealthieProvidersHealthieProviderIdGet(healthieProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Confirmation Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderConfirmationStatusProviderConfirmGet(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderConfirmationStatusProviderConfirmGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Details Internal
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderDetailsInternalInternalProvidersProviderIdGet(providerId: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderDetailsInternalInternalProvidersProviderIdGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Details
     * @param {ProviderId} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderDetailsProvidersProviderIdGet(providerId: ProviderId, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderDetailsProvidersProviderIdGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Healthie Active Status
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderHealthieActiveStatusAdminProvidersProviderIdHealthieDeactivatedStatusGet(providerId: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderHealthieActiveStatusAdminProvidersProviderIdHealthieDeactivatedStatusGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Identity
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderIdentityProvidersProviderIdIdentityGet(providerId: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderIdentityProvidersProviderIdIdentityGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider Practice Metrics
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(providerId: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Providers Admin
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProvidersAdminAdminProvidersGet(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProvidersAdminAdminProvidersGet(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Providers For Patient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public getProvidersForPatientProvidersGet(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).getProvidersForPatientProvidersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Import provider and dependents from Healthie, creating models in the database.  Dependents include: - patients - appointments - availability schedules  and more.
     * @summary Import Providers And Dependents
     * @param {ImportProvidersPayload} importProvidersPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public importProvidersAndDependentsAdminProvidersImportPost(importProvidersPayload: ImportProvidersPayload, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).importProvidersAndDependentsAdminProvidersImportPost(importProvidersPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Offboard Provider
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public offboardProviderAdminProvidersProviderIdOffboardPost(providerId: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).offboardProviderAdminProvidersProviderIdOffboardPost(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Provider Confirmation
     * @param {ProviderConfirmationPayload} providerConfirmationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public providerConfirmationProviderConfirmPost(providerConfirmationPayload: ProviderConfirmationPayload, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).providerConfirmationProviderConfirmPost(providerConfirmationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register Provider
     * @param {RegisterProviderPayload} registerProviderPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public registerProviderProvidersPost(registerProviderPayload: RegisterProviderPayload, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).registerProviderProvidersPost(registerProviderPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Provider Password
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public resetProviderPasswordProvidersProviderIdPasswordResetPost(providerId: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).resetProviderPasswordProvidersProviderIdPasswordResetPost(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Google Calendar Oauth
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public setGoogleCalendarOauthProvidersMeGooglePost(code: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).setGoogleCalendarOauthProvidersMeGooglePost(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Target Migration Date
     * @param {SetTargetMigrationDatePayload} setTargetMigrationDatePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public setTargetMigrationDateAdminProvidersTargetMigrationDatePut(setTargetMigrationDatePayload: SetTargetMigrationDatePayload, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).setTargetMigrationDateAdminProvidersTargetMigrationDatePut(setTargetMigrationDatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Provider
     * @param {ProviderId} providerId 
     * @param {UpdateProviderPayload} updateProviderPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public updateProviderProvidersProviderIdPatch(providerId: ProviderId, updateProviderPayload: UpdateProviderPayload, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).updateProviderProvidersProviderIdPatch(providerId, updateProviderPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VideoApi - axios parameter creator
 * @export
 */
export const VideoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Appointment Video Call
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet: async (appointmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet', 'appointmentId', appointmentId)
            const localVarPath = `/appointments/{appointment_id}/video-call`
                .replace(`{${"appointment_id"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VideoApi - functional programming interface
 * @export
 */
export const VideoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VideoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Appointment Video Call
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(appointmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentVideoCallResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(appointmentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VideoApi.getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VideoApi - factory interface
 * @export
 */
export const VideoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VideoApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Appointment Video Call
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(appointmentId: string, options?: any): AxiosPromise<AppointmentVideoCallResponse> {
            return localVarFp.getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(appointmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VideoApi - object-oriented interface
 * @export
 * @class VideoApi
 * @extends {BaseAPI}
 */
export class VideoApi extends BaseAPI {
    /**
     * 
     * @summary Get Appointment Video Call
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(appointmentId: string, options?: AxiosRequestConfig) {
        return VideoApiFp(this.configuration).getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebflowApi - axios parameter creator
 * @export
 */
export const WebflowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Webflow Oauth Callback
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webflowOauthCallbackWebflowOauthCallbackGet: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('webflowOauthCallbackWebflowOauthCallbackGet', 'code', code)
            const localVarPath = `/webflow/oauth-callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebflowApi - functional programming interface
 * @export
 */
export const WebflowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebflowApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Webflow Oauth Callback
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webflowOauthCallbackWebflowOauthCallbackGet(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webflowOauthCallbackWebflowOauthCallbackGet(code, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebflowApi.webflowOauthCallbackWebflowOauthCallbackGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * WebflowApi - factory interface
 * @export
 */
export const WebflowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebflowApiFp(configuration)
    return {
        /**
         * 
         * @summary Webflow Oauth Callback
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webflowOauthCallbackWebflowOauthCallbackGet(code: string, options?: any): AxiosPromise<string> {
            return localVarFp.webflowOauthCallbackWebflowOauthCallbackGet(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebflowApi - object-oriented interface
 * @export
 * @class WebflowApi
 * @extends {BaseAPI}
 */
export class WebflowApi extends BaseAPI {
    /**
     * 
     * @summary Webflow Oauth Callback
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebflowApi
     */
    public webflowOauthCallbackWebflowOauthCallbackGet(code: string, options?: AxiosRequestConfig) {
        return WebflowApiFp(this.configuration).webflowOauthCallbackWebflowOauthCallbackGet(code, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ZocdocIntegrationApi - axios parameter creator
 * @export
 */
export const ZocdocIntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel Appointment
         * @param {ZocdocAppointmentIdPayload} zocdocAppointmentIdPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointmentPartnersZocdocAppointmentsCancelPost: async (zocdocAppointmentIdPayload: ZocdocAppointmentIdPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zocdocAppointmentIdPayload' is not null or undefined
            assertParamExists('cancelAppointmentPartnersZocdocAppointmentsCancelPost', 'zocdocAppointmentIdPayload', zocdocAppointmentIdPayload)
            const localVarPath = `/partners/zocdoc/appointments/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zocdocAppointmentIdPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Appointment
         * @param {ZocdocCreateAppointmentPayload} zocdocCreateAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointmentPartnersZocdocAppointmentsPost: async (zocdocCreateAppointmentPayload: ZocdocCreateAppointmentPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zocdocCreateAppointmentPayload' is not null or undefined
            assertParamExists('createAppointmentPartnersZocdocAppointmentsPost', 'zocdocCreateAppointmentPayload', zocdocCreateAppointmentPayload)
            const localVarPath = `/partners/zocdoc/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zocdocCreateAppointmentPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Patient
         * @param {ZocdocCreatePatientPayload} zocdocCreatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatientPartnersZocdocPatientsPost: async (zocdocCreatePatientPayload: ZocdocCreatePatientPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zocdocCreatePatientPayload' is not null or undefined
            assertParamExists('createPatientPartnersZocdocPatientsPost', 'zocdocCreatePatientPayload', zocdocCreatePatientPayload)
            const localVarPath = `/partners/zocdoc/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zocdocCreatePatientPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointment Cancellation Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/appointment_cancellation_reasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointment Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentStatusesPartnersZocdocAppointmentStatusesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/appointment_statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Appointments By Ids
         * @param {Array<ZocdocAppointmentIdPayload>} zocdocAppointmentIdPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost: async (zocdocAppointmentIdPayload: Array<ZocdocAppointmentIdPayload>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zocdocAppointmentIdPayload' is not null or undefined
            assertParamExists('getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost', 'zocdocAppointmentIdPayload', zocdocAppointmentIdPayload)
            const localVarPath = `/partners/zocdoc/appointments/by_ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zocdocAppointmentIdPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Available Slot Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/available_slot_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Available Slots By Provider
         * @param {string} providerId 
         * @param {StartDate} startDate 
         * @param {EndDate} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet: async (providerId: string, startDate: StartDate, endDate: EndDate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet', 'providerId', providerId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet', 'endDate', endDate)
            const localVarPath = `/partners/zocdoc/available_slots/by_provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (providerId !== undefined) {
                localVarQueryParameter['provider_id'] = providerId;
            }

            if (startDate !== undefined) {
                for (const [key, value] of Object.entries(startDate)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (endDate !== undefined) {
                for (const [key, value] of Object.entries(endDate)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Locations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsPartnersZocdocLocationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Provider
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderPartnersZocdocProvidersProviderIdGet: async (providerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getProviderPartnersZocdocProvidersProviderIdGet', 'providerId', providerId)
            const localVarPath = `/partners/zocdoc/providers/{provider_id}`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersPartnersZocdocProvidersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Schedulable Resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulableResourcesPartnersZocdocSchedulableResourcesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/schedulable_resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionPartnersZocdocVersionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Visit Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitReasonsPartnersZocdocVisitReasonsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/zocdoc/visit_reasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Patients
         * @param {ZocdocPatientSearchPayload} zocdocPatientSearchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPatientsPartnersZocdocPatientsSearchPost: async (zocdocPatientSearchPayload: ZocdocPatientSearchPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zocdocPatientSearchPayload' is not null or undefined
            assertParamExists('searchPatientsPartnersZocdocPatientsSearchPost', 'zocdocPatientSearchPayload', zocdocPatientSearchPayload)
            const localVarPath = `/partners/zocdoc/patients/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zocdocPatientSearchPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZocdocIntegrationApi - functional programming interface
 * @export
 */
export const ZocdocIntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZocdocIntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel Appointment
         * @param {ZocdocAppointmentIdPayload} zocdocAppointmentIdPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAppointmentPartnersZocdocAppointmentsCancelPost(zocdocAppointmentIdPayload: ZocdocAppointmentIdPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZocdocAppointmentCancelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAppointmentPartnersZocdocAppointmentsCancelPost(zocdocAppointmentIdPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.cancelAppointmentPartnersZocdocAppointmentsCancelPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create Appointment
         * @param {ZocdocCreateAppointmentPayload} zocdocCreateAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppointmentPartnersZocdocAppointmentsPost(zocdocCreateAppointmentPayload: ZocdocCreateAppointmentPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZocdocCreateAppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppointmentPartnersZocdocAppointmentsPost(zocdocCreateAppointmentPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.createAppointmentPartnersZocdocAppointmentsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create Patient
         * @param {ZocdocCreatePatientPayload} zocdocCreatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPatientPartnersZocdocPatientsPost(zocdocCreatePatientPayload: ZocdocCreatePatientPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZocdocCreatePatientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPatientPartnersZocdocPatientsPost(zocdocCreatePatientPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.createPatientPartnersZocdocPatientsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Appointment Cancellation Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Appointment Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentStatusesPartnersZocdocAppointmentStatusesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZocdocAppointmentStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentStatusesPartnersZocdocAppointmentStatusesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.getAppointmentStatusesPartnersZocdocAppointmentStatusesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Appointments By Ids
         * @param {Array<ZocdocAppointmentIdPayload>} zocdocAppointmentIdPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost(zocdocAppointmentIdPayload: Array<ZocdocAppointmentIdPayload>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZocdocAppointment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost(zocdocAppointmentIdPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Available Slot Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Available Slots By Provider
         * @param {string} providerId 
         * @param {StartDate} startDate 
         * @param {EndDate} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet(providerId: string, startDate: StartDate, endDate: EndDate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZocdocAvailabilitySlot>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet(providerId, startDate, endDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Locations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationsPartnersZocdocLocationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationsPartnersZocdocLocationsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.getLocationsPartnersZocdocLocationsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Provider
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderPartnersZocdocProvidersProviderIdGet(providerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZocdocProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderPartnersZocdocProvidersProviderIdGet(providerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.getProviderPartnersZocdocProvidersProviderIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvidersPartnersZocdocProvidersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZocdocProvider>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvidersPartnersZocdocProvidersGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.getProvidersPartnersZocdocProvidersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Schedulable Resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchedulableResourcesPartnersZocdocSchedulableResourcesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchedulableResourcesPartnersZocdocSchedulableResourcesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.getSchedulableResourcesPartnersZocdocSchedulableResourcesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersionPartnersZocdocVersionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersionPartnersZocdocVersionGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.getVersionPartnersZocdocVersionGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Visit Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitReasonsPartnersZocdocVisitReasonsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZocdocVisitReason>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitReasonsPartnersZocdocVisitReasonsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.getVisitReasonsPartnersZocdocVisitReasonsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Search Patients
         * @param {ZocdocPatientSearchPayload} zocdocPatientSearchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPatientsPartnersZocdocPatientsSearchPost(zocdocPatientSearchPayload: ZocdocPatientSearchPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZocdocPatient>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPatientsPartnersZocdocPatientsSearchPost(zocdocPatientSearchPayload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ZocdocIntegrationApi.searchPatientsPartnersZocdocPatientsSearchPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ZocdocIntegrationApi - factory interface
 * @export
 */
export const ZocdocIntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZocdocIntegrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel Appointment
         * @param {ZocdocAppointmentIdPayload} zocdocAppointmentIdPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointmentPartnersZocdocAppointmentsCancelPost(zocdocAppointmentIdPayload: ZocdocAppointmentIdPayload, options?: any): AxiosPromise<ZocdocAppointmentCancelResponse> {
            return localVarFp.cancelAppointmentPartnersZocdocAppointmentsCancelPost(zocdocAppointmentIdPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Appointment
         * @param {ZocdocCreateAppointmentPayload} zocdocCreateAppointmentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointmentPartnersZocdocAppointmentsPost(zocdocCreateAppointmentPayload: ZocdocCreateAppointmentPayload, options?: any): AxiosPromise<ZocdocCreateAppointmentResponse> {
            return localVarFp.createAppointmentPartnersZocdocAppointmentsPost(zocdocCreateAppointmentPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Patient
         * @param {ZocdocCreatePatientPayload} zocdocCreatePatientPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatientPartnersZocdocPatientsPost(zocdocCreatePatientPayload: ZocdocCreatePatientPayload, options?: any): AxiosPromise<ZocdocCreatePatientResponse> {
            return localVarFp.createPatientPartnersZocdocPatientsPost(zocdocCreatePatientPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointment Cancellation Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointment Statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentStatusesPartnersZocdocAppointmentStatusesGet(options?: any): AxiosPromise<Array<ZocdocAppointmentStatus>> {
            return localVarFp.getAppointmentStatusesPartnersZocdocAppointmentStatusesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Appointments By Ids
         * @param {Array<ZocdocAppointmentIdPayload>} zocdocAppointmentIdPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost(zocdocAppointmentIdPayload: Array<ZocdocAppointmentIdPayload>, options?: any): AxiosPromise<Array<ZocdocAppointment>> {
            return localVarFp.getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost(zocdocAppointmentIdPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Available Slot Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Available Slots By Provider
         * @param {string} providerId 
         * @param {StartDate} startDate 
         * @param {EndDate} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet(providerId: string, startDate: StartDate, endDate: EndDate, options?: any): AxiosPromise<Array<ZocdocAvailabilitySlot>> {
            return localVarFp.getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet(providerId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Locations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsPartnersZocdocLocationsGet(options?: any): AxiosPromise<void> {
            return localVarFp.getLocationsPartnersZocdocLocationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Provider
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderPartnersZocdocProvidersProviderIdGet(providerId: string, options?: any): AxiosPromise<ZocdocProvider> {
            return localVarFp.getProviderPartnersZocdocProvidersProviderIdGet(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersPartnersZocdocProvidersGet(options?: any): AxiosPromise<Array<ZocdocProvider>> {
            return localVarFp.getProvidersPartnersZocdocProvidersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Schedulable Resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulableResourcesPartnersZocdocSchedulableResourcesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getSchedulableResourcesPartnersZocdocSchedulableResourcesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionPartnersZocdocVersionGet(options?: any): AxiosPromise<void> {
            return localVarFp.getVersionPartnersZocdocVersionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Visit Reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitReasonsPartnersZocdocVisitReasonsGet(options?: any): AxiosPromise<Array<ZocdocVisitReason>> {
            return localVarFp.getVisitReasonsPartnersZocdocVisitReasonsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Patients
         * @param {ZocdocPatientSearchPayload} zocdocPatientSearchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPatientsPartnersZocdocPatientsSearchPost(zocdocPatientSearchPayload: ZocdocPatientSearchPayload, options?: any): AxiosPromise<Array<ZocdocPatient>> {
            return localVarFp.searchPatientsPartnersZocdocPatientsSearchPost(zocdocPatientSearchPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZocdocIntegrationApi - object-oriented interface
 * @export
 * @class ZocdocIntegrationApi
 * @extends {BaseAPI}
 */
export class ZocdocIntegrationApi extends BaseAPI {
    /**
     * 
     * @summary Cancel Appointment
     * @param {ZocdocAppointmentIdPayload} zocdocAppointmentIdPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public cancelAppointmentPartnersZocdocAppointmentsCancelPost(zocdocAppointmentIdPayload: ZocdocAppointmentIdPayload, options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).cancelAppointmentPartnersZocdocAppointmentsCancelPost(zocdocAppointmentIdPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Appointment
     * @param {ZocdocCreateAppointmentPayload} zocdocCreateAppointmentPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public createAppointmentPartnersZocdocAppointmentsPost(zocdocCreateAppointmentPayload: ZocdocCreateAppointmentPayload, options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).createAppointmentPartnersZocdocAppointmentsPost(zocdocCreateAppointmentPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Patient
     * @param {ZocdocCreatePatientPayload} zocdocCreatePatientPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public createPatientPartnersZocdocPatientsPost(zocdocCreatePatientPayload: ZocdocCreatePatientPayload, options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).createPatientPartnersZocdocPatientsPost(zocdocCreatePatientPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointment Cancellation Reasons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet(options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getAppointmentCancellationReasonsPartnersZocdocAppointmentCancellationReasonsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointment Statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getAppointmentStatusesPartnersZocdocAppointmentStatusesGet(options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getAppointmentStatusesPartnersZocdocAppointmentStatusesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Appointments By Ids
     * @param {Array<ZocdocAppointmentIdPayload>} zocdocAppointmentIdPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost(zocdocAppointmentIdPayload: Array<ZocdocAppointmentIdPayload>, options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getAppointmentsByIdsPartnersZocdocAppointmentsByIdsPost(zocdocAppointmentIdPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Available Slot Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet(options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getAvailableSlotTypesPartnersZocdocAvailableSlotTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Available Slots By Provider
     * @param {string} providerId 
     * @param {StartDate} startDate 
     * @param {EndDate} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet(providerId: string, startDate: StartDate, endDate: EndDate, options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getAvailableSlotsByProviderPartnersZocdocAvailableSlotsByProviderGet(providerId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Locations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getLocationsPartnersZocdocLocationsGet(options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getLocationsPartnersZocdocLocationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Provider
     * @param {string} providerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getProviderPartnersZocdocProvidersProviderIdGet(providerId: string, options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getProviderPartnersZocdocProvidersProviderIdGet(providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Providers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getProvidersPartnersZocdocProvidersGet(options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getProvidersPartnersZocdocProvidersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Schedulable Resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getSchedulableResourcesPartnersZocdocSchedulableResourcesGet(options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getSchedulableResourcesPartnersZocdocSchedulableResourcesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getVersionPartnersZocdocVersionGet(options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getVersionPartnersZocdocVersionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Visit Reasons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public getVisitReasonsPartnersZocdocVisitReasonsGet(options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).getVisitReasonsPartnersZocdocVisitReasonsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Patients
     * @param {ZocdocPatientSearchPayload} zocdocPatientSearchPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZocdocIntegrationApi
     */
    public searchPatientsPartnersZocdocPatientsSearchPost(zocdocPatientSearchPayload: ZocdocPatientSearchPayload, options?: AxiosRequestConfig) {
        return ZocdocIntegrationApiFp(this.configuration).searchPatientsPartnersZocdocPatientsSearchPost(zocdocPatientSearchPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



