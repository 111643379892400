// A wrapper around the generated API to provide a more convenient interface.
// The general pattern is to map the generated API endpoints and types to a more convenient type
// that is easier to work with in the rest of the application.
//
// For example, the generated API returns a string for the date of birth of a
// patient. This wrapper converts that string to a Date object so that the
// rest of the application doesn't have to deal with parsing the string.
//
// This API then exports the types and functions that the rest of the
// application should use.

import { zonedTimeToUtc } from "date-fns-tz";
import {
  format,
  isValid,
  parse,
  addDays,
  startOfToday,
  getDay,
  set,
} from "date-fns";

// Please keep alphasorted
import {
  APIAppointmentStatus,
  APIAppointmentType,
  APIRecurrenceType,
  AppointmentAddToCalendarDetailsResponse,
  AppointmentApi,
  AppointmentResponse as _AppointmentResponse,
  BookAppointmentPayload as _BookAppointmentPayload,
  BookAppointmentResponse as _BookAppointmentResponse,
  CalendarEventResponse as _CalendarEventResponse,
  CalendarEventsResponse,
  CardSource,
  ChatApi,
  Configuration,
  CreateCalendarBlockPayload as _CreateCalendarBlockPayload,
  CreateJournalEntryPayload,
  DefaultApi,
  ErrorResponse,
  ExternalProviderResponseList,
  Gender,
  HeightFieldOutput,
  HeightUnits,
  InsuranceCompany,
  IntakeFormResponse,
  JournalEntryResponse,
  JournalStreakResponse,
  MealPlanCondition,
  MealPlanResponse as MealPlan,
  MealResponse as Meal,
  MealPlanningApi,
  NutritionResearchApi,
  NutritionResearchRunThreadResponse,
  PatientApi,
  PatientBillingApi,
  PatientConfirmationByCodePayload,
  PatientConfirmationPayload,
  PatientDetailsInclusions,
  PatientDetailsListResponse,
  PatientDetailsResponse,
  PatientDiscoverySource,
  PatientLabResponse,
  PatientLabsResponse,
  PatientRecapApi,
  PatientRecapResponse as PatientRecap,
  PayoutApi,
  ProviderApi,
  AIFeedbackApi,
  ProviderAvailabilityWindowResponse as _ProviderAvailabilityWindowResponse,
  ProviderDetailsResponse,
  ProviderForPatientDetailsResponse,
  RecipeResponse as Recipe,
  ReleaseOfInformationPayload,
  Sex,
  UpcomingAppointmentSummary as _UpcomingAppointmentSummary,
  UpdateAppointmentPayload as _UpdateAppointmentPayload,
  UpdateCalendarEventPayload as _UpdateCalendarEventPayload,
  UpdateIntakePayload,
  UpdateJournalEntryPayload,
  UpdatePatientPayload,
  UpdateProviderAvailabilitySchedulePayload as _UpdateProviderAvailabilitySchedulePayload,
  UpdateProviderAvailabilityScheduleWindowPayload as _UpdateProviderAvailabilityScheduleWindowPayload,
  UpdateProviderPayload,
  UploadPatientLabPayload,
  VideoApi,
  WeightFieldOutput,
  WeightUnits,
  UpdateMealPayload,
  CreateNutritionResearchThreadResponse,
  GetNutritionResearchThreadResponse,
  NutritionResearchMessageDetails,
  GetNutritionResearchThreadMessagesResponse,
  NutritionResearchFeedbackRating,
} from "./generated";
import { fetchAuthSession } from "aws-amplify/auth";
import axios, { AxiosError } from "axios";

// Please keep alphasorted
export {
  APIAppointmentStatus,
  APIAppointmentType,
  APIRecurrenceType,
  Gender,
  InsuranceCompany,
  Sex,
};

export type { MealPlan, Meal, Recipe };

// Please keep alphasorted
export type {
  ErrorResponse,
  HealthieProviderResponse as HealthieProvider,
  HealthieProviderValidationResponse,
  JournalEntryResponse as JournalEntry,
  JournalStreakResponse as JournalStreak,
  MealPlanCondition,
  OnboardableProviderResponse as OnboardableProvider,
  PatientAddress,
  PatientRecapResponse as PatientRecap,
  PaymentInstrumentResponse,
  ProviderBatchActionResponse,
  ProviderDetailsResponse,
  UpdatePatientPayload,
  UpdateProviderPayload,
} from "./generated";

export { CardBrand, CardSource } from "./generated";

export const URL = process.env.REACT_APP_FAY_API_BASE_PATH;

const axiosInstance = axios.create();

const checkIsErrorResponse = (data: any): data is ErrorResponse => {
  return data?.detail !== undefined;
};

const USER_FRIENDLY_ERROR_STATUSES = [400, 404, 409];

// Add a 400 response interceptor to throw a UserFriendlyError
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error instanceof AxiosError &&
      !!error.response &&
      USER_FRIENDLY_ERROR_STATUSES.includes(error.response.status) &&
      checkIsErrorResponse(error.response.data)
    ) {
      throw new UserFriendlyError({
        response: error.response.data,
      });
    }
    throw error;
  },
);

const configuration = new Configuration({
  basePath: URL,
  accessToken: async () => {
    const session = await fetchAuthSession();
    const { idToken } = session.tokens ?? {};
    const rawToken = idToken?.toString() ?? "";
    const tokenPrefix = {
      provider: "provider",
      client: "patient",
    }[process.env.REACT_APP_FAY_APP ?? ""];

    if (!tokenPrefix) {
      throw new Error("Invalid app configuration");
    }

    return `${tokenPrefix}::::${rawToken}`;
  },
  baseOptions: {
    headers:
      process.env.NODE_ENV === "development"
        ? {
            "ngrok-skip-browser-warning": `TRUE`,
          }
        : {},
  },
});

const appointmentApi = new AppointmentApi(
  configuration,
  undefined,
  axiosInstance,
);
const mealPlanApi = new MealPlanningApi(
  configuration,
  undefined,
  axiosInstance,
);
const chatApi = new ChatApi(configuration, undefined, axiosInstance);
const patientRecapApi = new PatientRecapApi(
  configuration,
  undefined,
  axiosInstance,
);
const patientApi = new PatientApi(configuration, undefined, axiosInstance);
const patientBillingApi = new PatientBillingApi(
  configuration,
  undefined,
  axiosInstance,
);
const payoutApi = new PayoutApi(configuration, undefined, axiosInstance);
const providerApi = new ProviderApi(configuration, undefined, axiosInstance);
const aiFeedbackApi = new AIFeedbackApi(
  configuration,
  undefined,
  axiosInstance,
);
const videoCallApi = new VideoApi(configuration, undefined, axiosInstance);
const defaultApi = new DefaultApi(configuration, undefined, axiosInstance);
const nutritionResearchApi = new NutritionResearchApi(
  configuration,
  undefined,
  axiosInstance,
);

// Modifies the type T with the properties of type R.
// Only modifies the properties that are common to both T and R.
// If R includes a key that does not exist in T, then that key is ignored.
// This prevents hidden backwards compatibility issues when modifying the API.
type Modify<T, R> = {
  [P in keyof T]: P extends keyof R ? R[P] : T[P];
};

export class UserFriendlyError extends Error {
  response: ErrorResponse;

  constructor({ response }: { response: ErrorResponse }) {
    super();
    this.response = response;
  }
}

export const formatHeight = (height: HeightFieldOutput): string => {
  let inchesTotal: number;

  if (height.unit === HeightUnits.Centimeters) {
    // Convert centimeters to inches
    inchesTotal = parseFloat(height.value) / 2.54;
  } else if (height.unit === HeightUnits.Inches) {
    inchesTotal = parseFloat(height.value);
  } else {
    throw new Error(`Unknown height unit: ${height.unit}`);
  }

  // Convert total inches to feet and inches
  const feet = Math.floor(inchesTotal / 12);
  const inches = Math.round(inchesTotal % 12);

  return `${feet}' ${inches}"`;
};

export const formatWeight = (weight: WeightFieldOutput): string => {
  let pounds: number;

  if (weight.unit === WeightUnits.Kilograms) {
    // Convert kilograms to pounds
    pounds = parseFloat(weight.value) * 2.20462;
  } else if (weight.unit === WeightUnits.Pounds) {
    pounds = parseFloat(weight.value);
  } else {
    throw new Error(`Unknown weight unit: ${weight.unit}`);
  }

  return `${pounds.toFixed(1)} lbs`;
};

export type GetProviderPatientsResponse = Modify<
  PatientDetailsListResponse,
  {
    patients: PatientDetails[];
  }
>;

export type UpcomingAppointmentSummary = Modify<
  _UpcomingAppointmentSummary,
  {
    start_time: Date;
  }
>;

export type PatientDetails = Modify<
  PatientDetailsResponse,
  {
    patient_id: string;
    cognito_id: string;
    first_name: string;
    preferred_first_name: string;
    last_name: string;
    email: string;
    date_of_birth?: Date | null;
    joined_at: Date | null;
    avatar_url: string | null;
    next_appointment?: UpcomingAppointmentSummary | null;
    external_providers_list: ExternalProviderResponseList | null;
  }
>;

const _mapUpcomingAppointmentSummary = (
  appointmentSummary?: _UpcomingAppointmentSummary | null,
): UpcomingAppointmentSummary | undefined => {
  if (appointmentSummary) {
    return {
      ...appointmentSummary,
      start_time: convertDateTimeStringToDateTime(
        appointmentSummary.start_time,
      ),
    };
  }
};

const _mapPatientDetailsResponse = (
  patient: PatientDetailsResponse,
): PatientDetails => ({
  ...patient,
  joined_at: patient.joined_at
    ? convertDateTimeStringToDateTime(patient.joined_at)
    : null,
  date_of_birth: patient.date_of_birth
    ? convertDateStringToDateTime(patient.date_of_birth)
    : null,
  next_appointment: _mapUpcomingAppointmentSummary(patient.next_appointment),
});

export const getProviderPatients = async ({
  withOldPatients,
}: {
  withOldPatients?: boolean;
}) => {
  const options = [
    PatientDetailsInclusions.NextAppointment,
    ...(withOldPatients ? [PatientDetailsInclusions.OldPatients] : []),
  ];

  const response = await patientApi.getPatientsForProviderPatientsGet(options);

  const serializedPatients = response.data.patients.map(
    _mapPatientDetailsResponse,
  );

  return {
    ...response.data,
    patients: serializedPatients,
  };
};

export const getPatientDetailsById = async (
  patientId: string,
): Promise<PatientDetails> => {
  const response =
    await patientApi.getPatientDetailsPatientsPatientIdGet(patientId);

  return _mapPatientDetailsResponse(response.data);
};

export const updatePatientById = async (
  patientId: string,
  payload: UpdatePatientPayload,
): Promise<PatientDetails> => {
  const response = await patientApi.updatePatientPatientsPatientIdPatch(
    patientId,
    payload,
  );

  return _mapPatientDetailsResponse(response.data);
};

export const getPatientIntakeFormAnswers = async (
  patientId: string,
): Promise<IntakeFormResponse> => {
  const response =
    await patientApi.getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(
      patientId,
    );

  return response.data;
};

export type CalendarEvents = Modify<
  CalendarEventsResponse,
  {
    events: CalendarEvent[];
  }
>;

export type CalendarEvent = Modify<
  _CalendarEventResponse,
  { start: Date; end: Date }
>;

const convertDateStringToDateTime = (dateString: string): Date => {
  return parse(dateString, "yyyy-MM-dd", new Date());
};
const convertDateTimeStringToDateTime = (dateString: string): Date => {
  return new Date(dateString);
};

const _mapCalendarEventResponse = (
  calendarEvent: _CalendarEventResponse,
): CalendarEvent => ({
  ...calendarEvent,
  start: convertDateTimeStringToDateTime(calendarEvent.start),
  end: convertDateTimeStringToDateTime(calendarEvent.end),
});

export const getCalendarEventsWithinTimeRange = async (
  startTime: Date,
  endTime: Date,
): Promise<CalendarEvents> => {
  const response = await appointmentApi.getCalendarEventsCalendarEventsGet(
    startTime.toISOString(),
    endTime.toISOString(),
  );

  const serializedEvents = response.data.events.map(_mapCalendarEventResponse);

  return {
    ...response.data,
    events: serializedEvents,
  };
};

export const getCalendarEventById = async (
  calendarEventId: string,
): Promise<CalendarEvent> => {
  const response =
    await appointmentApi.getCalendarEventCalendarEventsCalendarEventIdGet(
      calendarEventId,
    );

  return {
    ...response.data,
    start: convertDateTimeStringToDateTime(response.data.start),
    end: convertDateTimeStringToDateTime(response.data.end),
  };
};

export type { AppointmentDetailsResponse } from "./generated";

export type CreateCalendarBlockPayload = Modify<
  _CreateCalendarBlockPayload,
  {
    start: Date;
    end: Date;
  }
>;

const _mapCreateCalendarBlockPayload = (
  payload: CreateCalendarBlockPayload,
): _CreateCalendarBlockPayload => ({
  ...payload,
  start: payload.start.toISOString(),
  end: payload.end.toISOString(),
});

export const createCalendarBlock = async (
  payload: CreateCalendarBlockPayload,
): Promise<CalendarEvent> => {
  const response =
    await appointmentApi.createCalendarBlockCalendarEventsBlocksPost(
      _mapCreateCalendarBlockPayload(payload),
    );

  return _mapCalendarEventResponse(response.data);
};

export type UpdateCalendarEventPayload = Modify<
  _UpdateCalendarEventPayload,
  {
    start: Date;
    end: Date;
  }
>;

export const deleteCalendarEvent = async (
  calendarEventId: string,
  { withSubsequentRecurringEvents }: { withSubsequentRecurringEvents: boolean },
): Promise<void> => {
  await appointmentApi.deleteCalendarEventCalendarEventsCalendarEventIdDelete(
    calendarEventId,
    withSubsequentRecurringEvents,
  );
};

export type BookAppointmentPayload = Modify<
  _BookAppointmentPayload,
  {
    start: Date;
  }
>;

const _mapBookAppointmentPayload = (
  payload: BookAppointmentPayload,
): _BookAppointmentPayload => ({
  ...payload,
  start: payload.start.toISOString(),
});

export type BookAppointmentResponse = Modify<_BookAppointmentResponse, {}>;

const _mapBookAppointmentResponse = (
  response: _BookAppointmentResponse,
): BookAppointmentResponse => ({
  ...response,
});

export const bookAppointment = async (
  payload: BookAppointmentPayload,
): Promise<BookAppointmentResponse> => {
  const response = await appointmentApi.bookAppointmentAppointmentsPost(
    _mapBookAppointmentPayload(payload),
  );
  return _mapBookAppointmentResponse(response.data);
};

export type UpdateAppointmentPayload = Modify<
  _UpdateAppointmentPayload,
  {
    start?: Date;
  }
>;

const _mapUpdateAppointmentPayload = (
  payload: UpdateAppointmentPayload,
): _UpdateAppointmentPayload => ({
  ...payload,
  ...{
    start: payload.start?.toISOString(),
  },
});

export const updateAppointment = async (
  appointmentId: string,
  payload: UpdateAppointmentPayload,
): Promise<BookAppointmentResponse> => {
  const response =
    await appointmentApi.updateAppointmentAppointmentsAppointmentIdPatch(
      appointmentId,
      _mapUpdateAppointmentPayload(payload),
    );

  return _mapBookAppointmentResponse(response.data);
};

export const cancelAppointment = async (
  appointmentId: string,
): Promise<void> => {
  await appointmentApi.cancelAppointmentAppointmentAppointmentIdCancelPost(
    appointmentId,
  );
};

// A time string has a format of HH:MM:SS
export type TimeString = string & { __timeString: never };

export const timeStringFormat = "HH:mm:ss";

export const isTimeString = (timeString: string): timeString is TimeString => {
  const parsedDate = parse(timeString, timeStringFormat, new Date());
  return (
    isValid(parsedDate) && timeString === format(parsedDate, timeStringFormat)
  );
};

export const createDateFromAvailability = (
  timeString: TimeString,
  dayOfWeek: number,
  timezone: string,
): Date => {
  // Standardize to today's date
  let baseDate = startOfToday(); // Ensures all dates are on the same day
  const currentDayOfWeek = getDay(baseDate); // 0 (Sunday) to 6 (Saturday)

  // Adjust baseDate to the desired day_of_week (1=Monday, ...,7=Sunday)
  const desiredDayOffset = (dayOfWeek % 7) - currentDayOfWeek;
  baseDate = addDays(baseDate, desiredDayOffset);

  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const zonedDate = set(baseDate, { hours, minutes, seconds, milliseconds: 0 });

  return zonedTimeToUtc(zonedDate, timezone);
};

export const createTimeStringFromDate = (date: Date): TimeString => {
  const timeString = format(date, timeStringFormat);

  if (!isTimeString(timeString)) {
    throw new Error(
      "Error converting date to time string: invalid time string",
    );
  }
  return timeString;
};

export type ProviderAvailabilityWindow = Modify<
  _ProviderAvailabilityWindowResponse,
  {
    start_time: Date;
    end_time: Date;
    day_of_week: DayOfWeek;
  }
>;

const _mapProviderAvailabilityWindow = (
  response: _ProviderAvailabilityWindowResponse,
): ProviderAvailabilityWindow => {
  const { day_of_week, start_time, end_time, timezone } = response;

  if (!isTimeString(start_time) || !isTimeString(end_time)) {
    throw new Error(
      "Error fetching provider availability: invalid time string",
    );
  }

  return {
    ...response,
    start_time: createDateFromAvailability(start_time, day_of_week, timezone),
    end_time: createDateFromAvailability(end_time, day_of_week, timezone),
  };
};

export const getAvailability = async (): Promise<
  ProviderAvailabilityWindow[]
> => {
  const response =
    await appointmentApi.getProviderAvailabilityScheduleAvailabilityScheduleGet();

  return response.data.windows.map(_mapProviderAvailabilityWindow);
};

// Update Provider Availability Schedule
// Using date-fns:
// const monday = addDays(startOfISOWeek(newDate()), 1);
// format(monday, "EEEE") => "Monday"
// format(monday, "EE") => "Mon"
export type DayOfWeek = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type UpdateProviderAvailabilityScheduleWindowPayload = Modify<
  _UpdateProviderAvailabilityScheduleWindowPayload,
  {
    day_of_week: DayOfWeek;
    start_time: TimeString;
    end_time: TimeString;
  }
>;

export type UpdateProviderAvailabilitySchedulePayload = Modify<
  _UpdateProviderAvailabilitySchedulePayload,
  {
    windows: UpdateProviderAvailabilityScheduleWindowPayload[];
  }
>;

const _mapUpdateProviderAvailabilitySchedulePayload = (
  payload: UpdateProviderAvailabilitySchedulePayload,
): _UpdateProviderAvailabilitySchedulePayload => ({
  ...payload,
  windows: payload.windows.map((availabilityWindow) => ({
    ...availabilityWindow,
    start_time: availabilityWindow.start_time,
    end_time: availabilityWindow.end_time,
    day_of_week: availabilityWindow.day_of_week,
  })),
});

export const updateAvailabilitySchedule = async (
  payload: UpdateProviderAvailabilitySchedulePayload,
): Promise<ProviderAvailabilityWindow[]> => {
  const response =
    await appointmentApi.setProviderAvailabilityScheduleAvailabilitySchedulePost(
      _mapUpdateProviderAvailabilitySchedulePayload(payload),
    );

  return response.data.windows.map(_mapProviderAvailabilityWindow);
};

export const getAvailabilitiesForProviderId = async (data: {
  startTime: Date;
  endTime: Date;
  providerId: string;
  appointmentDurationInMinutes: number;
}): Promise<Date[]> => {
  const response =
    await appointmentApi.getProviderAvailableSlotsAvailabilitySlotsGet(
      data.startTime.toISOString(),
      data.endTime.toISOString(),
      data.appointmentDurationInMinutes,
      data.providerId,
    );
  return response.data.slots.map((dateString) => {
    return new Date(dateString);
  });
};

export const getProviderDetailsById = async (providerId: string) => {
  const response =
    await providerApi.getProviderDetailsProvidersProviderIdGet(providerId);

  return response.data;
};

export const updateSelfProvider = async (
  payload: UpdateProviderPayload,
): Promise<ProviderDetailsResponse> => {
  const response = await providerApi.updateProviderProvidersProviderIdPatch(
    "me",
    payload,
  );

  return response.data;
};

export type AppointmentResponse = Modify<
  _AppointmentResponse,
  {
    start: Date;
    end: Date;
  }
>;

const _mapAppointmentResponse = (
  appointment: _AppointmentResponse,
): AppointmentResponse => ({
  ...appointment,
  start: convertDateTimeStringToDateTime(appointment.start),
  end: convertDateTimeStringToDateTime(appointment.end),
});

const getAppointments = async (
  patientId?: string | null,
  providerId?: string | null,
  beforeStart: Date | null = null,
  afterStart: Date | null = null,
  beforeEnd: Date | null = null,
  afterEnd: Date | null = null,
  sort: "asc" | "desc" | null = null,
  limit: number = 100,
  offset: number = 0,
) => {
  const response = await appointmentApi.getAppointmentsAppointmentsGet(
    providerId,
    patientId,
    null,
    null,
    beforeStart?.toISOString() ?? null,
    afterStart?.toISOString() ?? null,
    beforeEnd?.toISOString() ?? null,
    afterEnd?.toISOString() ?? null,
    sort,
    limit,
    offset,
  );

  return response.data.map((appointment) =>
    _mapAppointmentResponse(appointment),
  );
};

export const getAppointmentDetailsById = async (
  appointmentId: string,
): Promise<AppointmentResponse> => {
  const response =
    await appointmentApi.getAppointmentAppointmentsAppointmentIdGet(
      appointmentId,
    );

  return _mapAppointmentResponse(response.data);
};

interface GetProviderPatientAppointmentsOptions {
  providerId?: string | null;
  patientId?: string | null;
  startBefore?: Date | null;
  startAfter?: Date | null;
  endBefore?: Date | null;
  endAfter?: Date | null;
  sort?: "asc" | "desc" | null;
  limit?: number;
  offset?: number;
}

export const getProviderPatientAppointments = async ({
  providerId,
  patientId,
  startBefore,
  startAfter,
  endBefore,
  endAfter,
  sort,
  limit,
  offset,
}: GetProviderPatientAppointmentsOptions) =>
  getAppointments(
    patientId,
    providerId,
    startBefore,
    startAfter,
    endBefore,
    endAfter,
    sort,
    limit,
    offset,
  );

export const registerProvider = async (
  firstName: string,
  lastName: string,
  email: string,
  npi: string,
  timezone: string,
  isAdmin: boolean = false,
  isTest: boolean = false,
  cognitoId: string | undefined = undefined,
) => {
  const response = await providerApi.registerProviderProvidersPost({
    first_name: firstName,
    last_name: lastName,
    email: email,
    npi: npi,
    timezone: timezone,
    is_admin: isAdmin,
    is_test: isTest,
    cognito_id: cognitoId,
  });

  return response.data;
};

export const getProvidersAdmin = async (
  limit: number = 100,
  offset: number = 0,
) => {
  const response = await providerApi.getProvidersAdminAdminProvidersGet(
    limit,
    offset,
  );

  return response.data;
};

export const getHealthieProvidersAdmin = async () => {
  const response =
    await providerApi.allHealthieProvidersAdminHealthieProvidersGet();

  return response.data;
};

export const getHealthieProviderAdmin = async (providerHealthieId: string) => {
  const response =
    await providerApi.getHealthieProviderAdminHealthieProvidersHealthieProviderIdGet(
      providerHealthieId,
    );

  return response.data;
};

export const importHealthieProvidersAdmin = async (
  healthieProviderIds: string[],
  targetMigrationDate: Date,
) => {
  const formattedTargetDate = format(targetMigrationDate, "yyyy-MM-dd");
  const response =
    await providerApi.importProvidersAndDependentsAdminProvidersImportPost({
      healthie_provider_ids: healthieProviderIds,
      target_migration_date: formattedTargetDate,
    });

  return response.data;
};

export const getOnboardableProvidersAdmin = async () => {
  const response =
    await providerApi.allOnboardableProvidersAdminOnboardableProvidersGet();

  return response.data;
};

export const getProviderIdentityDetails = async (providerId: string) => {
  const response =
    await providerApi.getProviderIdentityProvidersProviderIdIdentityGet(
      providerId,
    );

  return response.data;
};

export const resetProviderPassword = async (providerId: string) => {
  await providerApi.resetProviderPasswordProvidersProviderIdPasswordResetPost(
    providerId,
  );
};

export const confirmImportedProviders = async (providerIds: string[]) => {
  const response =
    await providerApi.confirmProviderMigrationAdminAdminProvidersConfirmImportPost(
      {
        provider_ids: providerIds,
      },
    );

  return response.data;
};

export const registerPatient = async (
  email: string,
  firstName: string,
  lastName: string,
  dateOfBirth: Date,
  timezone: string,
) => {
  const response = await patientApi.registerPatientAdminPatientsPost({
    email,
    first_name: firstName,
    last_name: lastName,
    date_of_birth: format(dateOfBirth, "yyyy-MM-dd"),
    timezone,
    discovery_source: PatientDiscoverySource.Other,
  });

  return response.data;
};

export const getMePaymentInstrument = async () => {
  const response =
    await patientBillingApi.getPatientPaymentInstrumentPatientsPatientIdPaymentGet(
      "me",
      {
        validateStatus: (status) => status === 200 || status === 404,
        "axios-retry": {
          retries: 0,
        },
      },
    );

  // 404 is acceptable here, we just return null.
  if (response.status === 404) {
    return null;
  }

  if (response.status !== 200) {
    throw new Error(
      `Error fetching patient payment instrument: ${response.statusText}`,
    );
  }

  return response.data;
};

export const updatePaymentInstrument = async (
  cardToken: string,
  cardSource: CardSource,
) => {
  const response =
    await patientBillingApi.createPatientPaymentInstrumentPatientsPatientIdPaymentPost(
      "me",
      {
        card_token: cardToken,
        card_source: cardSource,
      },
    );

  return response.data;
};

export const getPatientProviders = async (): Promise<
  ProviderForPatientDetailsResponse[]
> => {
  const response = await providerApi.getProvidersForPatientProvidersGet();

  if (response.status !== 200) {
    throw new Error(`Error fetching patient providers: ${response.statusText}`);
  }

  return response.data;
};

type ProviderConfirmationPayload = {
  new_password: string;
};

export type ConfirmationPayload =
  | PatientConfirmationPayload
  | ProviderConfirmationPayload;

export type ConfirmationStatus = {
  tokenValid: boolean;
  canConfirm: boolean;
  email: string | null;
  cognitoId: string | null;
};

export const getPatientConfirmationPreflight = async (
  jwt: string,
): Promise<ConfirmationStatus> => {
  const response =
    await patientApi.getPatientConfirmationStatusPatientConfirmGet({
      headers: { Authorization: `Bearer ${jwt}` },
      validateStatus: (status) =>
        status === 200 || status === 404 || status === 401,
    });

  if (response.status === 401 || response.status === 404) {
    return {
      tokenValid: false,
      canConfirm: false,
      email: null,
      cognitoId: null,
    };
  }

  return {
    tokenValid: true,
    canConfirm: response.data.can_confirm,
    email: response.data.email,
    cognitoId: response.data.cognito_id,
  };
};

export const getPatientCodeConfirmationPreflight = async (
  code: string,
): Promise<ConfirmationStatus> => {
  const response =
    await patientApi.getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(
      code,
      {
        validateStatus: (status) => status === 200 || status === 404,
      },
    );

  if (response.status === 404) {
    return {
      tokenValid: false,
      canConfirm: false,
      email: null,
      cognitoId: null,
    };
  }

  return {
    tokenValid: true,
    canConfirm: response.data.can_confirm,
    email: response.data.email,
    cognitoId: response.data.cognito_id,
  };
};

export const confirmPatient = async (
  payload: ConfirmationPayload,
  jwt: string,
) => {
  const response = await patientApi.patientConfirmationPatientConfirmPost(
    payload,
    { headers: { Authorization: `Bearer ${jwt}` } },
  );

  return response.data;
};

export const confirmPatientByCode = async (
  payload: PatientConfirmationByCodePayload,
) => {
  const response =
    await patientApi.patientConfirmationByCodePatientConfirmCodePost(payload);

  return response.data;
};

export const getProviderConfirmationPreflight = async (
  jwt: string,
): Promise<ConfirmationStatus> => {
  const response =
    await providerApi.getProviderConfirmationStatusProviderConfirmGet({
      headers: { Authorization: `Bearer ${jwt}` },
      validateStatus: (status) =>
        status === 200 || status === 404 || status === 401,
    });

  if (response.status === 401 || response.status === 404) {
    return {
      tokenValid: false,
      canConfirm: false,
      email: null,
      cognitoId: null,
    };
  }

  return {
    tokenValid: true,
    canConfirm: response.data.can_confirm,
    email: response.data.email,
    cognitoId: response.data.cognito_id,
  };
};

export const confirmProvider = async (
  payload: ConfirmationPayload,
  jwt: string,
) => {
  const response = await providerApi.providerConfirmationProviderConfirmPost(
    payload,
    { headers: { Authorization: `Bearer ${jwt}` } },
  );

  return response.data;
};

export const getGoogleOAuth = async () => {
  const response =
    await providerApi.getGoogleCalendarOauthProvidersMeGoogleGet();

  if (response.status !== 200) {
    throw new Error(`Error getting Google OAuth State: ${response.statusText}`);
  }

  return response.data;
};

export const setGoogleOAuth = async (code: string) => {
  const response =
    await providerApi.setGoogleCalendarOauthProvidersMeGooglePost(code);

  if (response.status !== 200) {
    throw new Error(`Error setting Google OAuth: ${response.statusText}`);
  }

  return response.data;
};

export const destroyGoogleOAuth = async () => {
  const response =
    await providerApi.deleteGoogleCalendarOauthProvidersMeGoogleDelete();

  if (response.status !== 200) {
    throw new Error(`Error deleting Google OAuth: ${response.statusText}`);
  }

  return response.data;
};

export const updatePatientIntakeFormResponses = async (
  patientId: string,
  updateIntakePayload: UpdateIntakePayload,
) => {
  const response =
    await patientApi.setPatientIntakeFormPatientsPatientIdIntakePost(
      patientId,
      updateIntakePayload,
    );

  return response.data;
};

export const searchNpiProvidersDatabase = async (
  name: string,
  limit: number,
) => {
  const response = await defaultApi.searchNpiDatabaseNpiGet(name, limit);
  return response.data;
};

export const getMeetingUrlForAppointmentId = async (appointmentId: string) => {
  const response =
    await videoCallApi.getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(
      appointmentId,
      { validateStatus: (status) => status === 200 || status === 404 },
    );

  if (response.status === 404) {
    return null;
  }

  return response.data;
};

export type AppointmentAddToCalendarDetails = Modify<
  AppointmentAddToCalendarDetailsResponse,
  { start: Date; end: Date }
>;

const _mapAppointmentAddToCalendarDetailsResponse = (
  appointment: AppointmentAddToCalendarDetailsResponse,
): AppointmentAddToCalendarDetails => ({
  ...appointment,
  start: convertDateTimeStringToDateTime(appointment.start),
  end: convertDateTimeStringToDateTime(appointment.end),
});

export const getAppointmentAddToCalendarDetails = async (
  appointmentId: string,
  jwt: string,
): Promise<AppointmentAddToCalendarDetails> => {
  const response =
    await appointmentApi.getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(
      appointmentId,
      { headers: { Authorization: `Bearer ${jwt}` } },
    );

  return _mapAppointmentAddToCalendarDetailsResponse(response.data);
};

export const deactivateProvidersOnHealthie = async (providerIds: string[]) => {
  const response =
    await providerApi.deactivateProviderHealthieAdminAdminProvidersDeactivateHealthiePost(
      { provider_ids: providerIds },
    );

  return response.data;
};

export const getCommandsAndEvents = async () => {
  const response = await defaultApi.adminGetBusTypesAdminBusGet();
  return response.data;
};

export const resubmitCommandOrEventPayload = async (
  commandOrEvent: string,
  payloadData: Record<string, any>,
) => {
  const response =
    await defaultApi.adminResubmitEventOrCommandToBusAdminBusPost(
      {
        command_or_event_name: commandOrEvent,
        payload: payloadData,
      },
      {
        validateStatus: (status) => status === 200 || status === 400,
      },
    );

  return response.data;
};

export type PatientSignedReleaseForm = {
  contents: string;
  filename: string;
};

export const getPatientSignedReleaseForm = async (patientId: string) => {
  const response =
    await patientApi.getReleaseFormPatientsPatientIdReleaseFormPdfGet(
      patientId,
      { responseType: "arraybuffer" },
    );
  // the OpenAPI generator doesn't handle application/pdf response types well
  // so we have to manually cast this to an ArrayBuffer
  return response.data as unknown as ArrayBuffer;
};

export const updateReleaseOfInformation = async (
  patientId: string,
  payload: ReleaseOfInformationPayload,
) => {
  await patientApi.updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(
    patientId,
    payload,
  );
};

export const getReleaseFormTemplateUrl = async () => {
  const response =
    await patientApi.getReleaseFormTemplateUrlPatientsFilesReleaseFormTemplateUrlGet();

  return response.data.release_form_template_url;
};

export const getProviderPracticeMetrics = async (providerId: string) => {
  const response =
    await providerApi.getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(
      providerId,
      {
        validateStatus: (status) => status === 200 || status === 404,
        "axios-retry": {
          retries: 0,
        },
      },
    );

  // 404 is acceptable here, we just return null.
  if (response.status === 404) {
    return null;
  }

  return response.data;
};

export const updateProvidersTargetMigrationDate = async (
  providerIds: string[],
  targetMigrationDate: Date,
) => {
  const response =
    await providerApi.setTargetMigrationDateAdminProvidersTargetMigrationDatePut(
      {
        provider_ids: providerIds,
        target_migration_date: format(targetMigrationDate, "yyyy-MM-dd"),
      },
    );

  return response.data;
};

export const triggerHealthieProviderImportValidation = async (
  healthieProviderId: string,
) => {
  const response =
    await providerApi.adminTriggerProviderHealthieImportValidationAdminProvidersHealthieProviderIdValidatePost(
      healthieProviderId,
    );
  return response.data;
};

export const getMyPayouts = async () => {
  const response = await payoutApi.getPayoutsForProviderPayoutsGet("me");
  return response.data;
};

export const getPatientDashboardDetails = async (token: string) => {
  const response =
    await patientApi.externalFacingDetailsPatientExternalFacingDetailsGet({
      headers: { Authorization: `Bearer ${token}` },
      validateStatus: (status) =>
        status === 200 || status === 401 || status === 404,
    });

  if (response.status === 200) {
    return response.data;
  } else if (response.status === 401) {
    // token is invalid
    throw new Error("The URL provided was invalid");
  } else if (response.status === 404) {
    // patient could not be found
    throw new Error("The patient could not be identified");
  } else {
    throw new Error("An unknown error occurred");
  }
};

export const getJournalEntry = async (
  id: string,
): Promise<JournalEntryResponse> => {
  const { data } =
    await patientApi.getJournalEntryPatientJournalEntriesJournalEntryIdGet(id);
  return data;
};

export const createJournalEntry = async (
  payload: CreateJournalEntryPayload,
): Promise<JournalEntryResponse> => {
  const { data } =
    await patientApi.createJournalEntryPatientJournalEntriesPost(payload);
  return data;
};

export const updateJournalEntry = async ({
  id,
  payload,
}: {
  id: string;
  payload: UpdateJournalEntryPayload;
}): Promise<JournalEntryResponse> => {
  const { data } =
    await patientApi.updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(
      id,
      payload,
    );
  return data;
};

export const deleteJournalEntry = async (id: string): Promise<void> => {
  await patientApi.deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(
    id,
  );
};

export const getJournalStreak = async (): Promise<JournalStreakResponse> => {
  const { data } = await patientApi.getJournalStreakPatientJournalStreakGet();

  return data;
};

export const uploadPatientLabReport = async (
  patientId: string,
  payload: UploadPatientLabPayload,
): Promise<void> => {
  await patientApi.uploadPatientLabPatientPatientIdLabsPost(patientId, payload);
};

export const getPatientLabs = async (
  patientId: string,
): Promise<PatientLabsResponse> => {
  const { data } =
    await patientApi.getPatientLabsPatientPatientIdLabsGet(patientId);

  return data;
};

export const getPatientLab = async (
  patientId: string,
  labId: string,
): Promise<PatientLabResponse> => {
  const { data } = await patientApi.getPatientLabPatientPatientIdLabsLabIdGet(
    labId,
    patientId,
  );

  return data;
};

export const sendNutritionResearchMessage = async (
  threadId: string,
  message: string,
) => {
  await nutritionResearchApi.sendNutritionResearchMessageNutritionResearchMessagePost(
    { thread_id: threadId, message },
  );
};

export const runNutritionResearchThread = async (
  threadId: string,
): Promise<NutritionResearchRunThreadResponse> => {
  const { data } =
    await nutritionResearchApi.runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(
      threadId,
    );
  return data;
};

export const createNutritionResearchThread =
  async (): Promise<CreateNutritionResearchThreadResponse> => {
    const { data } =
      await nutritionResearchApi.createNutritionResearchThreadNutritionResearchThreadsPost();
    return data;
  };

export const getNutritionResearchThread = async (
  providerId: string,
): Promise<GetNutritionResearchThreadResponse | null> => {
  const response =
    await nutritionResearchApi.getNutritionResearchThreadNutritionResearchThreadsGet(
      {
        validateStatus: (status) => status === 200 || status === 404,
        "axios-retry": {
          retries: 0,
        },
      },
    );

  if (response.status === 404) {
    return null;
  }

  return response.data;
};

export const getNutritionResearchThreadMessages = async (
  threadId: string,
  pagination_options: { page_size: number; offset: number },
): Promise<GetNutritionResearchThreadMessagesResponse> => {
  const { data } =
    await nutritionResearchApi.getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(
      threadId,
      pagination_options.page_size,
      pagination_options.offset,
    );
  return data;
};

interface GetOrCreateNutritionResearchThreadResponse {
  threadId: string;
  messages: NutritionResearchMessageDetails[];
}

// we only support 1 thread per provider at the moment
export const getOrCreateNutritionResearchThread = async (
  providerId: string,
  pagination_options: { page_size: number; offset: number },
): Promise<GetOrCreateNutritionResearchThreadResponse> => {
  const getThreadResponse = await getNutritionResearchThread(providerId);
  let threadId = getThreadResponse?.thread_id;
  if (!threadId) {
    const createResponse = await createNutritionResearchThread();
    threadId = createResponse.thread_id;
  }

  const messageReponse = await getNutritionResearchThreadMessages(
    threadId,
    pagination_options,
  );
  return { threadId, messages: messageReponse.messages };
};

export const getPatientRecap = async (
  patientId: string,
): Promise<PatientRecap> => {
  const { data } =
    await patientRecapApi.getPatientRecapPatientRecapPatientIdGet(patientId);
  return data;
};

export const draftProviderChatResponse = async (patient_id: string) => {
  const { data } = await chatApi.suggestChatResponseChatSuggestionPost({
    patient_id,
  });

  return data;
};

export const getMealPlanDetails = async (
  mealPlanId: string,
): Promise<MealPlan> => {
  const response =
    await mealPlanApi.getMealPlanMealPlanMealPlanIdGet(mealPlanId);
  return response.data;
};

export const initializeMealPlan = async (
  patientId: string,
  description: string,
  conditions: MealPlanCondition[],
  calorieTarget: number,
): Promise<MealPlan> => {
  const response = await mealPlanApi.generateMealPlanMealPlanPost({
    patient_id: patientId,
    description,
    conditions,
    calorie_target: calorieTarget,
  });

  return response.data;
};

export const updateMealPlanMeal = async (
  mealPlanId: string,
  mealId: string,
  payload: UpdateMealPayload,
): Promise<MealPlan> => {
  const response = await mealPlanApi.updateMealMealPlanMealPlanIdMealsMealIdPut(
    mealPlanId,
    mealId,
    payload,
  );

  return response.data;
};

export const sendMealPlan = async (mealPlanId: string): Promise<MealPlan> => {
  return (await mealPlanApi.sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId))
    .data;
};

export const provideFeedbackOnResearchAssistantMessage = async ({
  messageId,
  feedbackRating,
}: {
  messageId: string;
  feedbackRating: NutritionResearchFeedbackRating;
}) => {
  await nutritionResearchApi.nutritionResearchFeedbackNutritionResearchFeedbackPost(
    {
      message_id: messageId,
      feedback_rating: feedbackRating,
    },
  );
};

export const provideFeedbackOnChatCompletion = async ({
  chatCompletionId,
  binaryFeedback,
  feedbackText,
}: {
  chatCompletionId: string;
  binaryFeedback?: boolean;
  feedbackText?: string;
}) => {
  await aiFeedbackApi.updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(
    chatCompletionId,
    {
      binary_feedback: binaryFeedback,
      feedback_text: feedbackText,
    },
  );
};

export const offboardProvider = async ({
  providerId,
}: {
  providerId: string;
}) => {
  return await providerApi.offboardProviderAdminProvidersProviderIdOffboardPost(
    providerId,
  );
};
